<template>
    <dialogBox >
        <template v-slot:title>{{ $t('ChangeEnvProblem') }}</template>
        <template v-slot:body>
            <div class="mt-1 text-center">
                <h3 class="mb-3"><b>{{ $t('selectDifferentProblem') }}</b></h3>
                <Multiselect 
                    v-model="form.problemEnvironment"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="this.devProjects"
                    :placeholder="$t('device_printer')+', '+$t('device_rro')+', '+$t('dir_pos')"
                />
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('cancel') }}</button>
                <button class="btn btn-success" @click="change">{{ $t('Edit') }}</button>
            </div>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Tickets } from '@/API'
import { storeS } from '@/store'

let apiServe = new Tickets()

export default{
    props: ['objCard'],
    components: {
        dialogBox,
        Multiselect
    },
    data(){
        return{
            form: {
                problemEnvironment: ''
            },
            devProjects: []
        }
    },
    created(){
        this.form.problemEnvironment = this.objCard.problemEnvironment
   
        this.changeSelectDevProjects();
    },
    methods: {
        changeSelectDevProjects(){
            // Робимо вигляд проєктів під мультіселект

            this.devProjects = [];
            for(var item in this.developmentProjects){
                var project = this.developmentProjects[item]
                this.devProjects.push({
                    "label": project.projectName,
                    "value": project.projectKey
                })
            }
            
        },
        change(){
            apiServe.changeProblemEnvironment(this.objCard.ticketId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('ProblemChangSucc'));
                    this.$emit('close')
                    var data = {
                        status: true,
                        data: result.data
                    }
                    this.eventBus.emit('getCardTicket', data);
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        }
    },
    computed: {
        developmentProjects() {
            // Отримання проектів
            return storeS.developmentProjects
        }
    }
}
</script>