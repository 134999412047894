<template>
    <b-card>
        <div class="d-flex mb-3">
            <h6 class="card-title mb-0 flex-grow-1">{{ $t('appointed') }}</h6>
            <!-- <div class="flex-shrink-0">
                <button type="button" 
                    v-if="this.showButt == true || this.perms[307] == true"
                    :class="`btn btn-soft-${showAddWorks == false ? 'danger' : 'success'} btn-sm`"  
                    @click="showAddWorks == false ? showAddWorks = true : addWorkJob()">
                    <i class="ri-share-line me-1 align-bottom"></i>
                    {{ showAddWorks == false ? $t('Appoint') : $t('save') }}
                </button>
            </div> -->
            <div 
                class="flex-shrink-0" 
                v-if="(
                    (
                        this.perms[307] && this.form.inJobWorkers.find(el => el.workerId === this.user.userid
                    ) ||
                    (
                        this.user.userid === 79 || 
                        this.user.userid === 113 || 
                        this.user.userid === 114 || 
                        this.user.userid === 1 ||
                        this.user.userid === 41 ||
                        this.user.userid === 57 ||
                        this.user.userid === 54 ||
                        this.user.userid === 77 ||
                        this.user.userid === 49 ||
                        this.user.userid === 60 ||
                        this.user.userid === 56 ||
                        this.user.userid === 33
                    )
                ) || this.perms[2000])"
            >
                <button type="button" class="btn btn-soft-danger btn-sm" @click="showModal = true"><i class="ri-share-line me-1 align-bottom"></i>{{ $t('Add') }}</button>
            </div>
        </div>
        <ul class="list-unstyled vstack gap-3 mb-0">
            <li @click="open(items)" class="click" v-for="items in this.form.assignedWorkers">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-1"><b>{{items.workerName}}</b></h6>
                    </div>
                </div>
            </li>
        </ul>
    </b-card>

    <b-card>
        <div class="d-flex mb-3">
            <h6 class="card-title mb-0 flex-grow-1">{{ $t('statusInWork') }}</h6>
            <!-- <div class="flex-shrink-0">
                <button type="button" 
                    v-if="this.showButt == true || this.perms[307] == true"
                    :class="`btn btn-soft-${showAddWorks == false ? 'danger' : 'success'} btn-sm`"  
                    @click="showAddWorks == false ? showAddWorks = true : addWorkJob()">
                    <i class="ri-share-line me-1 align-bottom"></i>
                    {{ showAddWorks == false ? $t('Appoint') : $t('save') }}
                </button>
            </div> -->
        </div>
        <ul class="list-unstyled vstack gap-3 mb-0">
            <li v-for="(item, index) in form.inJobWorkers" :key="index" @click="open(item)" class="click">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index}}</div></div>
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="mb-1"><b>{{item.workerName}}</b></h6>
                    </div>
                </div>
            </li>
        </ul>
    </b-card>
    

    <modal
        v-if="showModal"
        @close="showModal = false"
        @invite="invite"
        @open="open"
        :projectWorkers="this.form.inJobWorkers.concat(this.form.assignedWorkers)"
    />

    <modalWorker 
        v-if="showModalWorker == true"
        @close="showModalWorker = false"
        :objcard="objcard"
    />

</template>

<script>
import modal from '@/components/modal/modal-members'
import modalWorker from '@/views/users/view/index'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { Tickets, Users } from '@/API'
import { storeS } from '@/store'

let apiServe = new Tickets();
let apiUsers = new Users();

export default{
    props: ['obj'],
    components: {
        Multiselect,
        modal,
        modalWorker
    },
    data(){
        return{
            showButt:false,
            showAddWorks: false,
            objcard: '',
            showModal: false,
            showModalWorker: false,
            form: {},
            formWorkers: {
                workerId: [],
            },
            workersList: [{
                label: this.$t('all'), value: 'all'
            }]
        }
    },
    created(){
        this.form = this.obj
        this.formWorkers.workerId.push(this.form.inJobWorkers[0]?.workerId)
        this.form.assignedWorkers.forEach(el => {
            console.log(el)
            this.formWorkers.workerId.push(el.workerId)
        })
    },
    methods:{
        nameWorker(item){
            return nameWorker(item)
        },
        invite(workersList){
            let obj = {workers: []}
            workersList.workers.forEach(el => {
                if(el.workerId != this.user.userid) {
                    obj.workers.push(el.workerId)
                }
            })
            apiServe.addTeammates(this.form.ticketId, obj).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('appointed'));
                    this.eventBus.emit('updateTicket', true);
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
            

        },
        open(id){
            apiUsers.getUser(id.workerId).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModalWorker = true
                }
            })
        }
    },
    computed: {
        user() {
            return storeS.userbase
        },
        workers(){
            return storeS.workers
        },
        perms(){
            return storeS.perms
        },
        checks(){
            return storeS.checks
        }
    }
}
</script>