import axios from "axios";
import { storeS } from "@/store.js"
import Cookies from "js-cookie";
import { eventB } from "@/main"

axios.defaults.baseURL = `https://api.inside.skyservice.pro/`;
var savedTokenAndCompany = null;
var token = "";

try {
    /*Для отладки*/
    if (process.env.NODE_ENV != 'production') {
        // savedTokenAndCompany = '5DibZrSATdhfs22YYY9dzf8d7n84zZnh&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&lang=uk' // Borys
        // savedTokenAndCompany = '5TyH7e3fbG3h7bkSRGB6N6b93K4ks35Y&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f' // Олег К
        savedTokenAndCompany = '5B6Rk2yQhHKsk8sBBHfsHN9ieAnRr2S5&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f' //Max
        // savedTokenAndCompany = 'Reftk5fRA73RKYZDsniH5Aba7D9ssTrf&companyId=79de1430-414b-4e34-bbd0-2a1d353a7f85' //Max testing
        // savedTokenAndCompany = 'aZKB45fzBThzhT8ShSS5bb42tGAAAHKH&companyId=79de1430-414b-4e34-bbd0-2a1d353a7f85'//Бодя
        // savedTokenAndCompany = '9KS3DsFh42tTE5FryTdsYfse9nFD2bS6&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f' // Юля
        // швидке взяття токену для localhost    
            // document.cookie.match(/token=................................/)[0].replace('token=', '')+"&"+document.cookie.match(/companyId=..................................../)[0]
        // швидке взяття токену для localhost

        // savedTokenAndCompany = 'Qrf44AQDtTrHz2yyGHkzGh7FREZaQkdn&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&lang=ru' // Клиим
        // axios.defaults.params = {
        //     token: 'Fr4f6fTT9HfeT6BayrKdb7Eie4N2eht5',
        //     companyId: '792fe762-b138-4c3e-9b5f-a4a310fc4a0f', //Max

        // //     // token: 'Qrf44AQDtTrHz2yyGHkzGh7FREZaQkdn',
        // //     // companyId: '792fe762-b138-4c3e-9b5f-a4a310fc4a0f', // klim-klim
        // token = '4Q3FiYDZyGzKD4ahfi374bF4B87fa46F';
        // token = 'Reftk5fRA73RKYZDsniH5Aba7D9ssTrf' // Max testing
        // token = 'rhDG38GfYd35DZZk5Nb3AZ8EE8sRd6T6'; // Вася
        //     // companyId: '792fe762-b138-4c3e-9b5f-a4a310fc4a0f', // Borys
        // }
        storeS.savedTokenAndCompany = savedTokenAndCompany
        if(token) {
            storeS.token = token;
        } else {
            storeS.token = savedTokenAndCompany.split("&")[0];
        }

    }
} catch (err) {
}

try{
    
    if(process.env.NODE_ENV == 'production') {
        var url = new URL(window.location.href);
        token = []
        if(url.searchParams.get('token') != '' && url.searchParams.get('token') != null && url.searchParams.get('token') != undefined){
            token = url.searchParams.get('token')
        } else if(document.cookie.indexOf('token=') != -1) {
            for(let i = document.cookie.indexOf('token=')+6; i<=document.cookie.indexOf('token=')+5+32; i++) {
                token.push(document.cookie[i])
            }
            token = token.join('')
        }  else {
            alert(i18n.global.t('dontAccess'))
            window.location.href('https://cabinet.skyservice.online/')
        }

        var companyId = url.searchParams.get('companyId');
        savedTokenAndCompany = Cookies.get(`token`, token) + '&companyId=' + Cookies.get("companyId");
        // token = Cookies.get('token', token);

        if(token && companyId){
            // const expirationDate = new Date(); // створити об'єкт дати
            // expirationDate.setMonth(expirationDate.getMonth() + 6); // додати 6 місяців

            Cookies.set(`token`, token)
            Cookies.set(`companyId`, companyId)
            savedTokenAndCompany = token + '&companyId=' + companyId;
            storeS.savedTokenAndCompany = savedTokenAndCompany
            // token = token
        }
        if (token) {
            storeS.token = token;
        }
        
         // Отримати всі куки
         const allCookies = document.cookie;

         // Відобразити значення
         console.log("allCookies >>>> ",allCookies);
    }
}catch(err){
    console.log("Не вдалось зчитати токен або компанію")
}


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    
    storeS.preloaderTable = true;    
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) { 
    
        storeS.preloaderTable = false;
        return response;
    
  }, function (error) {

    function Error(type, mess) {
        this.type = type
        this.mess = mess
    }

    const output = new Error('error', error.toJSON().message)
    
    console.table(output)

    // зберігаємо запит в LocalStorage
    
    if(error.toJSON().config.method == 'post') {

        let obj = {
            method: error.toJSON().config.method,
            api: error.toJSON().config.baseURL + error.toJSON().config.url,
            data: error.toJSON().config.data
        }

        localStorage.setItem('toDoFirst', JSON.stringify(obj))
        // зберігаємо запит в LocalStorage

        storeS.preloaderTable = false
        storeS.preloader = false

        eventB.emit('errorWithReq', {status: true})
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response errors
    return Promise.reject(error);
  });
  
  
export class Start {
    
    getStart() {
        console.log("saved > ", savedTokenAndCompany);
        return axios.get(`?section=adminPanel&action=getStart&token=${savedTokenAndCompany}`).then(response => response.data)
    }

}

export class Applications {

    //зателефонувати зараз
    callNow(id) {
        return axios.post(`?section=requests&action=callNowByRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data)
    }

    // cтворити пропущенний
    createMissing(obj) {
        return axios.post(`https://api.inside.skyservice.pro/?section=uaats&action=missing`, obj).then(response => response.data)
    }

    // удалить все звонки 
    // deleteAllCall() {
    //     return axios.post(`?section=requests&action=removeRequests&token=${savedTokenAndCompany}`).then(response => response.data)
    // }

    //отложить заявку, перенос на другое время
    delayRequest(id, obj) {
        return axios.post(`?section=requests&action=delayRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }
    
    //отменить заявку
    cancelApplic(id, obj) {
        return axios.post(`?section=requests&action=cancelRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }
    
    //добавление репорта по отмене заявки
    addReportOnCancelRequest(id, obj) {
        return axios.post(`?section=requests&action=addReportOnCancelRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    //взять в работу заявку
    addRequestInJob(id) {
        return axios.get(`?section=requests&action=addRequestInJob&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data)
    }

    //редактировать заявку
    editRequest(obj, id) {
        return axios.post(`?section=requests&action=editRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    // получить список всех заявок
    getRequests(obj) {
        return axios.get(`?section=requests&action=getRequests&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //Получить карточку заявки
    getRequest(id) {
        return axios.get(`?section=requests&action=getRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }
    
    //удалить карточку заявки
    deleteRequest(id) {
        return axios.get(`?section=requests&action=deleteRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    //добавить карточку заявки
    addRequest(obj) {
        return axios.post(`?section=requests&action=addRequest&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //закрыть заявку
    closeRequest(id, obj) {
        return axios.post(`?section=requests&action=closeRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj);
    }

    // передать заявку другому оператору
    transferRequest(id, idWorker, obj) {
        return axios.post(`?section=requests&action=transferRequest&token=${savedTokenAndCompany}&requestId=${id}&workerId=${idWorker}`, obj).then(response => response.data);
    }

    //фиксация
    fixationTransferRequest(id, idWorker) {
        return axios.post(`?section=requests&action=fixationTransferRequest&token=${savedTokenAndCompany}&requestId=${id}&workerId=${idWorker}`).then(response => response.data);
    }

    //скасувати передачу заявки 
    rejectTransferRequest(id) {
        return axios.post(`?section=requests&action=rejectTransferRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // змінити статус на "подзвонити зараз" (одразу декілька заявок)
    packCallNow(obj) {
        return axios.post(`?section=requests&action=callNowByRequests&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

}

export class Dumps {

    //отримати відвали
    getDumps(obj) {
        return axios.get(`?section=dumps&action=getDumps&token=${savedTokenAndCompany}`, {params : obj}).then(response => response.data);
    }

    //картка відвалу
    getDump(id) {
        return axios.get(`?section=dumps&action=getDump&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    //додати відвал
    addDump(obj) {
        return axios.post(`?section=dumps&action=addDump&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // редагування картки відвалу
    editDump(id, obj) {
        return axios.post(`?section=dumps&action=editDump&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    //удалить карточку відвала
    deleteDump(id) {
        return axios.get(`?section=dumps&action=deleteDump&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    //взяти в роботу відвал
    addDumpInJob(id) {
        return axios.get(`?section=dumps&action=addDumpInJob&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data)
    }

    // додавання репорту зі скасування відвалу
    addReportOnCancelDump(id, obj) {
        return axios.post(`?section=dumps&action=addReportOnCancelDump&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    // зателефонувати зараз по відвалу, todo dev Борис
    callNowByDump(id) {
        return axios.post(`?section=dumps&action=callNowByDump&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data)
    }

    // скасування відвалу
    cancelDump(id, obj) {
        return axios.post(`?section=dumps&action=cancelDump&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    // закриття відвалу
    closeDump(id, obj) {
        return axios.post(`?section=dumps&action=closeDump&token=${savedTokenAndCompany}&requestId=${id}`, obj);
    }

    //отложить відвал, перенос на другое время
    // {
    //     "delayedTime": "12:00",
    //     "delayedDate": "2023-06-02"
    // }
    delayDump(id, obj) {
        return axios.post(`?section=dumps&action=delayDump&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    // фіксація відвалу під час передачі іншому співробітнику
    fixationTransferDump(id) {
        return axios.post(`?section=dumps&action=fixationTransferDump&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // відмова від відвалу
    rejectTransferDump(id) {
        return axios.post(`?section=dumps&action=rejectTransferDump&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    //передати відвал
    transferDump(id, idWorker) {
        return axios.post(`?section=dumps&action=transferDump&token=${savedTokenAndCompany}&requestId=${id}&workerId=${idWorker}`).then(response => response.data);
    }

    // import dumps for new admin panel
    importDumps(){
        return axios.post(`?section=dumps&action=importDumps&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // подзвонити зараз (пачкою)
    packCallNow(obj){
        return axios.post(`?section=dumps&action=callNowByDumps&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }
}

export class OtherCalls {

    //добавление категории (другие звонки)
    addCategory(obj) {
        return axios.post(`?section=categoryByCalls&action=addCategory&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //удаление категории (другие звонки)
    deleteCategory(id) {
        return axios.get(`?section=categoryByCalls&action=deleteCategory&token=${savedTokenAndCompany}&categoryId=${id}`).then(response => response.data);
    }

    // редактирование категории (другие звонки)
    editCategory(id, obj) {
        return axios.post(`?section=categoryByCalls&action=editCategory&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response => response.data)
    }

    //список категорий (другие звонки)
    getCategories(obj) {
        return axios.get(`?section=categoryByCalls&action=getCategories&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получение данных о категории (другие звонки)
    getCategory(id) {
        return axios.get(`?section=categoryByCalls&action=getCategory&token=${savedTokenAndCompany}&categoryId=${id}`).then(response => response.data);
    }

    //создание заявки (другие звонки)
    addRequest(id, obj) {
        return axios.post(`?section=requestByCalls&action=addRequest&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response => response.data);
    }

    //удаление заявки (другие звонки)
    deleteRequest(id) {
        return axios.get(`?section=requestByCalls&action=deleteRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    //редактирование заявки (другие звонки)
    editRequest(obj, id) {
        return axios.post(`?section=requestByCalls&action=editRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    // получение данных о заявке (другие звонки)
    getRequest(id) {
        return axios.get(`?section=requestByCalls&action=getRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // получение списка заявок (другие звонки)
    getRequests(id, obj) {
        return axios.get(`?section=requestByCalls&action=getRequests&token=${savedTokenAndCompany}&categoryId=${id}`, { params: obj }).then(response => response.data);
    }

    //прием в роботу заявки (другие звонки)
    addRequestInJob(id) {
        return axios.get(`?section=requestByCalls&action=addRequestInJob&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data)
    }

    // позвонить сейчас по заявке (другие звонки)
    callNow(id) {
        return axios.get(`?section=requestByCalls&action=callNowByRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data)
    }

    // отмена заявки (другие звонки)
    cancelApplic(id, obj) {
        return axios.post(`?section=requestByCalls&action=cancelRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    // закрытие заявки (другие звонки)
    closeRequest(id, obj) {
        return axios.post(`?section=requestByCalls&action=closeRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data);
    }

    // отложить заявку (другие звонки)
    delayRequest(id, obj) {
        return axios.post(`?section=requestByCalls&action=delayRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data)
    }

    // фиксация заявки при передаче другому сотруднику (другие звонки)
    fixationTransferRequest(id, idWorker) {
        return axios.post(`?section=requestByCalls&action=fixationTransferRequest&token=${savedTokenAndCompany}&requestId=${id}&workerId=${idWorker}`).then(response => response.data);
    }

    // передача заявки (другие звонки)
    transferRequest(id, idWorker, obj) {
        return axios.post(`?section=requestByCalls&action=transferRequest&token=${savedTokenAndCompany}&requestId=${id}&workerId=${idWorker}`, obj).then(response => response.data);
    }
    rejectTransferRequest(id) {
        return axios.post(`?section=requestByCalls&action=rejectTransferRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

}

export class Tasks {

    // оновити статус заявки
    refreshStatusTask(id, obj){
        return axios.post(`?section=tasks&action=updateStatus&token=${savedTokenAndCompany}&taskId=${id}`, obj).then(response => response.data);
    }

    // получить список всех задач
    getTasks(obj) {
        return axios.get(`?section=tasks&action=getTasks&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить задачу
    addTask(obj) {
        return axios.post(`?section=tasks&action=addTask&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить карточку задачи
    getTask(id) {
        return axios.get(`?section=tasks&action=getTask&token=${savedTokenAndCompany}&taskId=${id}`).then(response => response.data);
    }

    //взять в работу задачу (кто выполняет)
    addTaskInJob(id) {
        return axios.post(`?section=tasks&action=performTask&token=${savedTokenAndCompany}&taskId=${id}`).then(response => response.data);
    }

    //взять в работу задачу (колцентр)
    addTaskInJobCallCenter(id) {
        return axios.post(`?section=tasks&action=addTaskInJob&token=${savedTokenAndCompany}&taskId=${id}`).then(response => response.data);
    }

    //выполнить задачу
    completeTask(id, obj) {
        return axios.post(`?section=tasks&action=completeTask&token=${savedTokenAndCompany}&taskId=${id}`, obj).then(response => response.data);
    }

    //передача задачи 
    transferTask(id, workerId, obj) {
        return axios.post(`?section=tasks&action=transferTask&token=${savedTokenAndCompany}&taskId=${id}&workerId=${workerId}`, obj).then(response => response.data);
    }

    //скасувати передачу задачі 
    rejectTransferTask(id) {
        return axios.post(`?section=tasks&action=rejectTransferTask&token=${savedTokenAndCompany}&taskId=${id}`).then(response => response.data);
    }

    //фіксація передачі задачі 
    fixationTransferTask(id, workerId) {
        return axios.post(`?section=tasks&action=fixationTransferTask&token=${savedTokenAndCompany}&taskId=${id}&workerId=${workerId}`).then(response => response.data);
    }

    //отмена задачи 
    cancelTask(id, obj) {
        return axios.post(`?section=tasks&action=cancelTask&token=${savedTokenAndCompany}&taskId=${id}`, obj).then(response => response.data);
    }

    //подтверждение задачи
    confirmTask(id, obj) {
        return axios.post(`?section=tasks&action=confirmTask&token=${savedTokenAndCompany}&taskId=${id}`, obj).then(response => response.data);
    }

    //редактировать задачу
    editTask(id, obj) {
        return axios.post(`?section=tasks&action=editTask&token=${savedTokenAndCompany}&taskId=${id}`, obj).then(result=>result.data);
    }

    //перенести задачу
    delayTask(id, obj){
        return axios.post(`?section=tasks&action=delayTask&token=${savedTokenAndCompany}&taskId=${id}`, obj).then(result=>result.data);
    }
}

export class Tickets {
    // Виключити співробітника з тікету
    kickUser(id) {
        return axios.post(`?section=tickets&action=removeInJobWorker&token=${savedTokenAndCompany}&ticketId=${id}`).then(response=>response.data);
    }

    // Вийти з назначенного тікету
    exitTicket(id) {
        return axios.post(`?section=tickets&action=exitAssignedTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response=>response.data);
    }

    //вийти з тікету
    leaveFromTicket(id) {
        return axios.post(`?section=tickets&action=exitTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response=>response.data);
    }

    //призупинити виконання тікету
    stopTicket(id) {
        return axios.post(`?section=tickets&action=stopTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response=>response.data);
    }

    //відновити роботу над тікетом
    continueTicket(id) {
        return axios.post(`?section=tickets&action=resumeTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response=>response.data);
    }

    // відновлення тіккету
    recoverTicket(id) {
        return axios.post(`?section=tickets&action=restoreTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response=>response.data);
    }

    // получить список всех тикетов
    getTickets(obj) {
        return axios.get(`?section=tickets&action=getTickets&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить карточку тикета wait
    createTicket(obj) {
        return axios.post(`?section=tickets&action=createTicket&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить карточку тикета wait
    getTicket(id) {
        return axios.get(`?section=tickets&action=getTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response => response.data);
    }

    //взять в работу тикет
    inJobTicket(id) {
        return axios.get(`?section=tickets&action=inJobTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response => response.data);
    }

    //Принудительное закрепление тикета за сотрудниками
    inJobTicketManually(id, obj) {
        return axios.post(`?section=tickets&action=inJobTicketManually&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //закрыть тикет
    closeTicket(id, obj) {
        return axios.post(`?section=tickets&action=closeTicket&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //добавить решение к тикету 
    decideTicket(id, obj) {
        return axios.post(`?section=tickets&action=decideTicket&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //переоткрытие тикета 
    reopenTicket(id, obj) {
        return axios.post(`?section=tickets&action=reopenTicket&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //добавить/удалить номер клиента к тикету
    savePhonesByTicket(id, obj) {
        return axios.post(`?section=tickets&action=savePhonesByTicket&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //изменить тикет
    editTicket(id, obj) {
        return axios.post(`?section=tickets&action=editTicket&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //удаленние тиккета
    deleteTicket(id) {
        return axios.get(`https://api.inside.skyservice.pro/?section=tickets&action=deleteTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response=>response.data)
    }

    //Получить сообщения по тикету
    getMessages(id) {
        return axios.get(`?section=ticketChat&action=getMessages&token=${savedTokenAndCompany}&ticketId=${id}`).then(response => response.data);
    }
    
    //добавить сообщение по тикету
    sendMessage(id, obj) {
        return axios.post(`?section=ticketChat&action=sendMessage&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //добавить реплай по тикету
    replyOnMessage(id, messageId, obj) {
        return axios.post(`?section=ticketChat&action=replyOnMessage&token=${savedTokenAndCompany}&ticketId=${id}&messageId=${messageId}`, obj).then(response => response.data);
    }

    //добавить устройство к тикету
    addDevice(id, obj) {
        return axios.post(`?section=ticketDevices&action=addDevice&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }
    
    //редактировать устройство в тикету
    editDevice(id, deviceId, obj) {
        return axios.post(`?section=ticketDevices&action=editDevice&token=${savedTokenAndCompany}&ticketId=${id}&deviceTicketId=${deviceId}`, obj).then(response => response.data);
    }

    //удалить устройство в тикету
    deleteDevice(id) {
        return axios.post(`?section=ticketDevices&action=deleteDevice&token=${savedTokenAndCompany}&deviceTicketId=${id}`).then(response => response.data);
    }

    deleteMessage(id) {
        return axios.post(`?section=ticketChat&action=deleteMessage&token=${savedTokenAndCompany}&messageId=${id}`).then(response => response.data);
    }

    editMessage(id, obj) {
        return axios.post(`?section=ticketChat&action=editMessage&token=${savedTokenAndCompany}&messageId=${id}`, obj).then(response => response.data);
    }

    //удалить файл в тикете
    deleteFile(id) {
        return axios.get(`?section=ticketFiles&action=deleteFile&token=${savedTokenAndCompany}&fileId=${id}`).then(response => response.data);
    }

    //загрузить файл в тикет (бинарный)
    uploadFile(id, data, type) {
        return axios.post(`?section=ticketFiles&action=uploadFile&token=${savedTokenAndCompany}&ticketId=${id}`, data, {
            headers: {
                'Content-Type': type,
            },
        }).then(response => response.data);
    }

    //загрузить файлы в тикет (несколько, через форму)
    uploadFiles(id, formData) {
        return axios.post(`?section=ticketFiles&action=uploadFiles&token=${savedTokenAndCompany}&ticketId=${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }).then(response => response.data);
    }

    //получить файлы в тикете
    getFiles(id){
        return axios.get(`?section=ticketFiles&action=getFiles&token=${savedTokenAndCompany}&ticketId=${id}`).then(response => response.data);
    }

    //просмотр файла в тикете
    getFile(id) {
        return axios.get(`?section=ticketFiles&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }
    
    //зміна пріоритету тікету
    changePriority(id, obj) {
        return axios.post(`?section=tickets&action=changePriority&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //редагувати середовище проблеми
    changeProblemEnvironment(id, obj) {
        return axios.post(`?section=tickets&action=changeProblemEnvironment&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //додавання задачі до тікету
    addTask(id, obj) {
        return axios.post(`?section=ticketTasks&action=addTicketTasks&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    // відмітити таску по тікетам як виконану
    checkTask(id, obj) {
        return axios.post(`?section=ticketTasks&action=completeTicketTask&token=${savedTokenAndCompany}&taskId=${id}`, obj).then(response => response.data);
    }

    // назначити співробітників за тікетом
    addTeammates(id, obj){
        return axios.post(`?section=tickets&action=saveAssignedWorkers&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    // зміна статусу тікету на очікування релізу 
    ticketInRelease(id) {
        return axios.post(`?section=tickets&action=waitReleaseTicket&token=${savedTokenAndCompany}&ticketId=${id}`).then(response => response.data);
    }

    // видалення таски з тікету
    taskRemove(id) {
        return axios.post(`?section=ticketTasks&action=deleteTicketTask&token=${savedTokenAndCompany}&taskId=${id}`).then(response => response.data);
    }

    // отримання кількості не закритих тікетів за номером телефону клієнта, та масиву тікетів з назвою
    getUnclosedTicketsByPhones(phone) {
        return axios.get(`?section=tickets&action=getUnclosedTicketsByPhones&token=${savedTokenAndCompany}&phone=${phone}`).then(response=>response.data)
    }

    // https://api.inside.skyservice.pro/?section=tickets&action=getUnclosedTickets&token=rDhfbYFG4Fd3kdBd6RHF8E446k44dbAQ&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&page=1&pagelimit=5&more=3
    // отримання незакритих тікетів по номерам
    getUnclosedTickets(obj) {
        return axios.get(`?section=tickets&action=getUnclosedTickets&token=${savedTokenAndCompany}`, { params: obj }).then(response=>response.data)
    }

    // отримання списку незакритих тикетів по співробітникам
    getUnclosedTicketsByWorkers(obj) {
        return axios.get(`?section=tickets&action=getUnclosedTicketsByWorkers&token=${savedTokenAndCompany}`, { params: obj }).then(response=>response.data)
    }

    // отримання списку не закритих тикетів із середовищ проблем
    getUnclosedTicketsByEnvironments(obj) {
        return axios.get(`?section=tickets&action=getUnclosedTicketsByEnvironments&token=${savedTokenAndCompany}`, { params: obj }).then(response=>response.data)
    }

    // таймлайн
    getTicketHistory(ticketId) {
        return axios.get(`?section=tickets&action=getTicketHistory&token=${savedTokenAndCompany}&ticketId=${ticketId}`).then(response => response.data);
    }

    // зміна статусу рішення тікету
    changeDecidedStatus(ticketId, obj) {
        return axios.post(`?section=tickets&action=changeDecidedStatus&token=${savedTokenAndCompany}&ticketId=${ticketId}`, obj).then(response => response.data);
    }

}

export class ticketAppeals {
    
    // Додати повторне звернення до тікету
    addAppeal(ticketId, phone, obj) {
        return axios.post(`?section=ticketAppeals&action=addAppeal&token=${savedTokenAndCompany}&ticketId=${ticketId}&phone=${phone}`, obj).then(response => response.data);
    }

    // отримання списку повторних звернень за тікетом
    getAppealsByClient(ticketId, phone) {
        return axios.get(`?section=ticketAppeals&action=getAppealsByClient&token=${savedTokenAndCompany}&ticketId=${ticketId}&phone=${phone}`).then(response=>response.data)
    }

    // видалення повторного звернення по тікету
    deleteAppeal(appealId) {
        return axios.get(`?section=ticketAppeals&action=deleteAppeal&token=${savedTokenAndCompany}&appealId=${appealId}`).then(response => response.data);
    }

    // редагування повторного звернення по тікету
    editAppeal(appealId, obj) {
        return axios.post(`?section=ticketAppeals&action=editAppeal&token=${savedTokenAndCompany}&appealId=${appealId}`, obj).then(response => response.data)
    }

}

export class Tags {

    // додавання тегу
    addTag(obj) {
        return axios.post(`?section=tags&action=addTag&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // видалення тегу
    deleteTag(id) {
        return axios.get(`?section=tags&action=deleteTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

    // редагування тегу
    editTag(obj, id) {
        return axios.post(`?section=tags&action=editTag&token=${savedTokenAndCompany}&tagId=${id}`, obj).then(response => response.data)
    }

    // отримання тегу
    getTag(id) {
        return axios.get(`?section=tags&action=getTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

    // список тегів
    getTags(obj) {
        return axios.get(`?section=tags&action=getTags&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // відновлення тегу
    restoreTag(obj) {
        return axios.post(`?section=tags&action=restoreTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

}

export class faqPostTags {

    // додавання тегу
    addTag(obj) {
        return axios.post(`?section=faqPostTags&action=addTag&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // видалення тегу
    deleteTag(id) {
        return axios.get(`?section=faqPostTags&action=deleteTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

    // редагування тегу
    editTag(obj, id) {
        return axios.post(`?section=faqPostTags&action=editTag&token=${savedTokenAndCompany}&tagId=${id}`, obj).then(response => response.data)
    }

    // отримання тегу
    getTag(id) {
        return axios.get(`?section=faqPostTags&action=getTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

    // список тегів
    getTags(obj) {
        return axios.get(`?section=faqPostTags&action=getTags&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // відновлення тегу
    restoreTag(obj) {
        return axios.post(`?section=faqPostTags&action=restoreTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

}

export class lessonTags {

    // додавання тегу
    addTag(obj) {
        return axios.post(`?section=lessonTags&action=addTag&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // видалення тегу
    deleteTag(id) {
        return axios.get(`?section=lessonTags&action=deleteTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

    // редагування тегу
    editTag(obj, id) {
        return axios.post(`?section=lessonTags&action=editTag&token=${savedTokenAndCompany}&tagId=${id}`, obj).then(response => response.data)
    }

    // отримання тегу
    getTag(id) {
        return axios.get(`?section=lessonTags&action=getTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

    // список тегів
    getTags(obj) {
        return axios.get(`?section=lessonTags&action=getTags&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // відновлення тегу
    restoreTag(obj) {
        return axios.post(`?section=lessonTags&action=restoreTag&token=${savedTokenAndCompany}&tagId=${id}`).then(response => response.data);
    }

}

export class Reports {


    // репорт на удаление
    addReportOnDelete(id, obj) {
        return axios.post(`?section=requests&action=addReportOnDelete&token=${savedTokenAndCompany}&requestId=${id}`, obj)
    }

}

export class ReportsTickets {

    //Получить репорты по тикету
    getReportsTickets(ticketId) {
        return axios.get(`?section=ticketReports&action=getReports&token=${savedTokenAndCompany}&ticketId=${ticketId}`).then(response => response.data);
    }
    
    //Получить карточку репорта
    getReport(id) {
        return axios.get(`?section=ticketReports&action=getReport&token=${savedTokenAndCompany}&reportId=${id}`).then(response => response.data);
    }

    //удалить карточку репорта 
    deleteReport(id) {
        return axios.get(`?section=ticketReports&action=deleteReport&token=${savedTokenAndCompany}&reportId=${id}`).then(response => response.data);
    }

    // репорт на ticket
    addReportTicket(id, obj) {
        return axios.post(`?section=ticketReports&action=addReport&token=${savedTokenAndCompany}&ticketId=${id}`, obj).then(response => response.data);
    }

    //загрузить файлы в репорты (несколько, через форму)
    uploadFiles(id, formData) {
        return axios.post(`?section=ticketReportFiles&action=uploadFiles&token=${savedTokenAndCompany}&reportId=${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }).then(response => response.data);
    }

    //удалить файл в репорте
    deleteFile(id) {
        return axios.get(`?section=ticketReportFiles&action=deleteFile&token=${savedTokenAndCompany}&fileId=${id}`).then(response => response.data);
    }

    //получить файлы в репорте
    getFiles(id){
        return axios.get(`?section=ticketReportFiles&action=getFiles&token=${savedTokenAndCompany}&reportId=${id}`).then(response => response.data);
    }

    //просмотр файла в репорте
    getFile(id) {
        return axios.get(`?section=ticketReportFiles&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

}

export class Teams {

    // получить список всех команд
    getTeams(obj) {
        return axios.get(`?section=teams&action=getTeams&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить карточку команды
    addTeam(obj) {
        return axios.post(`?section=teams&action=addTeam&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить карточку команды
    getTeam(id) {
        return axios.get(`?section=teams&action=getTeam&token=${savedTokenAndCompany}&teamId=${id}`).then(response => response.data);
    }

    //удалить карточку команды 
    deleteTeam(id) {
        return axios.get(`?section=teams&action=deleteTeam&token=${savedTokenAndCompany}&teamId=${id}`).then(response => response.data);
    }

    //изменить команду
    editTeam(id, obj) {
        return axios.get(`?section=teams&action=editTeam&token=${savedTokenAndCompany}&teamId=${id}`, obj).then(response => response.data);
    }

}

export class Users {

    // получить список всех юзеров
    getAllUsers(obj) {
        return axios.get(`?section=workers&action=getWorkers&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    addWorker(obj) {
        return axios.post(`?section=workers&action=addWorker&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить карточку сотрудника
    getUser(id) {
        return axios.get(`?section=workers&action=getWorker&token=${savedTokenAndCompany}&workerId=${id}`).then(response => response.data);
    }

    //удалить карточку сотрудника 
    deleteWorker(id) {
        return axios.get(`?section=workers&action=deleteWorker&token=${savedTokenAndCompany}&workerId=${id}`).then(response => response.data);
    }

    // змінити аватар співробітника
    uploadAvatar(id, obj) {
        return axios.post(`?section=workers&action=saveAvatar&token=${savedTokenAndCompany}&workerId=${id}`, obj).then(response => response.data);
    }

    //изменить сотрудника
    editUser(id, obj) {
        return axios.post(`?section=workers&action=editWorker&token=${savedTokenAndCompany}&workerId=${id}`, obj).then(response => response.data);
    }

    //Получить права доступа
    getPermsUser(id) {
        return axios.get(`?section=rights&action=getPerms&token=${savedTokenAndCompany}&workerId=${id}`).then(response => response.data);
    }

    //изменить права доступа
    editPermsUser(obj) {
        return axios.post(`?section=rights&action=savePerms&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить настройки сотрудника
    getSettingsUser(id) {
        return axios.get(`?section=workers&action=getWorkerSettings&token=${savedTokenAndCompany}&workerId=${id}`).then(response => response.data);
    }

    //изменить настройки сотрудника
    saveWorkerSettings(id, obj) {
        return axios.post(`?section=workers&action=saveWorkerSettings&token=${savedTokenAndCompany}&workerId=${id}`, obj).then(response => response.data);
    }

    //отправить приглашение 
    sendInvitation(obj) {
        return axios.post(`?section=workers&action=inviteWorker&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // получить список смен
    getAllShifts(obj) {
        return axios.get(`?section=shifts&action=getShifts&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // открытие смены
    getOpenShift() {
        return axios.get(`?section=shifts&action=openShift&token=${savedTokenAndCompany}`).then(response => response.data);
    } 

    // закрытие смены
    getCloseShift(id, obj) {
        return axios.get(`?section=shifts&action=closeShift&token=${savedTokenAndCompany}&shiftId=${id}`, { params: obj }).then(response => response.data);
    }

    // проверка на наличие открытой смены
    getCheckOpenShift() {
        return axios.get(`?section=shifts&action=verifyShift&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // добавить причину опоздания
    addlateForShift(id, obj) {
        return axios.post(`?section=shifts&action=lateForShift&token=${savedTokenAndCompany}&shiftId=${id}`, obj).then(response => response.data);
    }
    
    // получение данных о смене
    getCardShift(id) {
        return axios.get(`?section=shifts&action=getShift&token=${savedTokenAndCompany}&shiftId=${id}`).then(response => response.data);
    }

    //сохранение настроек смены по сотруднику 
    saveShiftSettings(id, obj) {
        return axios.post(`?section=workers&action=saveShiftSettings&token=${savedTokenAndCompany}&workerId=${id}`, obj).then(response => response.data);
    }

    //закрытие смены раньше времени
    addearlyCloseShift(id, obj) {
        return axios.post(`?section=shifts&action=earlyCloseShift&token=${savedTokenAndCompany}&shiftId=${id}`, obj).then(response => response.data);
    }

    //сохранение настройки ip по сотруднику
    saveWorkerIp(id, obj) {
        return axios.post(`?section=workers&action=saveWorkerIp&token=${savedTokenAndCompany}&workerId=${id}`, obj).then(response => response.data);
    }

    //берем настройку ip по сотруднику
    getWorkerIp(id) {
        return axios.get(`?section=workers&action=getWorkerIp&token=${savedTokenAndCompany}&workerId=${id}`).then(response => response.data);
    }

    //опоздания
    getLatenesses(obj) {
        return axios.get(`?section=shifts&action=getLatenesses&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //історія входу
    loginHistory(workerId, obj) {
        return axios.get(`?section=adminPanel&action=loginHistory&token=${savedTokenAndCompany}&workerId=${workerId}`, { params: obj }).then(response => response.data);
    }

    // Закриття зміни співробітнику
    closeShiftForWorker(id) {
        return axios.post(`?section=shifts&action=closeShiftByWorker&token=${savedTokenAndCompany}&workerId=${id}`).then(response => response.data);
    }

}

export class Holidays {

    //добавление заявки на отпуск
    addRequest(obj) {
        return axios.post(`?section=holidays&action=addRequest&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //подтверждение заявки на отпуск
    confirmRequest(id, obj) {
        return axios.post(`?section=holidays&action=confirmRequest&token=${savedTokenAndCompany}&holidayId=${id}`, obj).then(response => response.data);
    }

    //отмена заявки на отпуск
    cancelRequest(id, obj) {
        return axios.post(`?section=holidays&action=cancelRequest&token=${savedTokenAndCompany}&holidayId=${id}`, obj).then(response => response.data);
    }

    //редактирование данных заявки на отпуск
    editRequest(id, obj) {
        return axios.post(`?section=holidays&action=editRequest&token=${savedTokenAndCompany}&holidayId=${id}`, obj).then(response => response.data);
    }

    //получение данные заявки на отпуск
    getRequest(id) {
        return axios.get(`?section=holidays&action=getRequest&token=${savedTokenAndCompany}&holidayId=${id}`).then(response => response.data);
    }

    //список заявок на отпуск
    getRequests(obj) {
        return axios.get(`?section=holidays&action=getRequests&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // получение списка отгулов
    getHolidays(obj) {
        return axios.get(`?section=holidays&action=getHolidays&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //удаление заявки на отпуск
    deleteRequest(id) {
        return axios.get(`?section=holidays&action=deleteRequest&token=${savedTokenAndCompany}&holidayId=${id}`).then(response => response.data);
    }

}

export class Clients {

    // получить карточку клиента ( с устройствами, приложениями, рефф)
    getClient(number) {
        return axios.get(`https://admin.skyservice.pro/new-api/?action=client_skyservice&apitoken=jj886bdLKJNh7dBdN9hHVDNh7dB45D8VDjj886bdLKJNh7dBdN9hHVDNh7dB45D8VD&phone=${number}`).then(response => response.data);
    }

    // получить номер аккаунта по номеру сотрудника (без устройств, приложений, рефф)
    getAccEmp(number){
        return axios.get(`https://online.skyservice.pro/new-api/?action=search_user_company&apitoken=jj886bdLKJNh7dBdN9hHVDNh7dB45D8VDjj886bdLKJNh7dBdN9hHVDNh7dB45D8VD&phone=${number}`).then(response => response.data);
    }

    // получить оплаты клиента
    getPayClient(number) {
        return axios.get(`https://admin.skyservice.pro/new-api/?action=client_skyservice_getpayments&apitoken=jj886bdLKJNh7dBdN9hHVDNh7dB45D8VDjj886bdLKJNh7dBdN9hHVDNh7dB45D8VD&section=db&db=banklist&phone=${number}`).then(response => response.data);
    }

    // сбросить пароль
    reset_passwordClient(number) {
        return axios.get(`https://admin.skyservice.pro/new-api/?action=resetpassword&apitoken=jj886bdLKJNh7dBdN9hHVDNh7dB45D8VDjj886bdLKJNh7dBdN9hHVDNh7dB45D8VD&phone=${number}`).then(response => response.data);
    }

    // сбросить пароль с новой регистрации
    new_reset_passwordClient(accountId) {
        return axios.get(`https://api.auth.skyservice.online/?section=settings&action=resetPassword&secret=7n115KwZeCahXdxbqLpDwTFNe6GneMbc&accountId=${accountId}`).then(response => response.data);
    }

    // получить страну для калькулятора
    getCountry(country) {
        return axios.get(`?section=countries&action=getCountry&country=${country}`).then(response => response.data);
    }

    // получить список стран
    getCountries() {
        return axios.get(`?section=countries&action=getCountries`).then(response => response.data);
    }

    // добавить комментарий к клиенту
    addComment(client_phone, comment, user_phone) {
        return axios.post(`?action=client_skyservice_addcomments&apitoken=jj886bdLKJNh7dBdN9hHVDNh7dB45D8VDjj886bdLKJNh7dBdN9hHVDNh7dB45D8VD&phone=${client_phone}&text=${comment}&manager=${user_phone}`).then(response => response.data);
    }

    // отправить смс
    sendSms(obj) {
        return axios.post(`?section=sendMessages&action=sendSms&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //фиксация комментария
    fixationData(obj) {
        return axios.post(`?section=comments&action=fixationData&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // отримати коментарі
    getComments(phone, obj) {
        return axios.get(`?section=comments&action=getComments&token=${savedTokenAndCompany}&phone=${phone}`, { params: obj }).then(response => response.data);
    }

    getCommentsWithoutComp(accId, obj) {
        return axios.get(`?section=comments&action=getCommentsWithoutCompany&token=${savedTokenAndCompany}&accountId=${accId}`, { params: obj }).then(response => response.data);
    }
    
    //получить историю
    getAccountHistory(phone, obj) {
        return axios.get(`?section=accounts&action=getAccountHistory&token=${savedTokenAndCompany}&phone=${phone}`, { params: obj }).then(response => response.data);
    }

}

export class Archive {
    // Отримати список моніторингів
    getAllArchiveMonitoring(obj) {
        return axios.get(`?section=archive&action=getArchiveMonitoringList&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // получить список всех задач 
    getAllArchiveTasks(obj) {
        return axios.get(`?section=archive&action=getArchiveTasks&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить карточку задачи
    getArchiveTask(id, obj) {
        return axios.get(`?section=archive&action=getArchiveTask&token=${savedTokenAndCompany}&taskId=${id}`, {params:obj}).then(result=>result.data)
    }

    // получить список всех заявок 
    getAllArchiveApplics(obj) {
        return axios.get(`?section=archive&action=getArchiveRequests&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить карточку заявки
    getArchiveRequest(id, obj) {
        return axios.get(`?section=archive&action=getArchiveRequest&token=${savedTokenAndCompany}&requestId=${id}`, {params:obj}).then(result=>result.data)
    }

    // получить список всех тикетов 
    getAllArchiveTickets(obj) {
        return axios.get(`?section=archive&action=getArchiveTickets&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить карточку тикета
    getArchiveTicket(id, obj) {
        return axios.get(`?section=archive&action=getArchiveTicket&token=${savedTokenAndCompany}&ticketId=${id}`, {params:obj}).then(result=>result.data)
    }

    // получить список всех проектов
    getAllArchiveProjects(obj) {
        return axios.get(`?section=archive&action=getArchiveProjects&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить карточку проекта
    getArchiveProject(id, obj) {
        return axios.get(`?section=archive&action=getArchiveProject&token=${savedTokenAndCompany}&projectId=${id}`, {params:obj}).then(result=>result.data)
    }

    // получить список смен
    getAllArchiveShifts(obj) {
        return axios.get(`?section=archive&action=getArchiveShifts&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить карточку смены
    getArchiveShift(id, obj) {
        return axios.get(`?section=archive&action=getArchiveShift&token=${savedTokenAndCompany}&shiftId=${id}`, {params:obj}).then(result=>result.data)
    }

    // получить список смс
    getAllArchiveSms(obj) {
        return axios.get(`?section=archive&action=getArchiveSms&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить карточку sms
    getArchiveSmsInfo(id, obj) {
        return axios.get(`?section=archive&action=getArchiveSmsInfo&token=${savedTokenAndCompany}&smsId=${id}`, {params:obj}).then(result=>result.data)
    }

    //получить список презентаций
    getAllPresentations(obj) {
        return axios.get(`?section=archive&action=getArchivePresentations&token=${savedTokenAndCompany}`, {params:obj}).then(result=>result.data)
    }

    //получить карточку презентации
    getArchivePresentation(id, obj) {
        return axios.get(`?section=archive&action=getArchivePresentation&token=${savedTokenAndCompany}&presentationId=${id}`, {params:obj}).then(result=>result.data)
    }

    //архів відвалів
    getArchiveDumps(obj) {
        return axios.get(`?section=archive&action=getArchiveDumps&token=${savedTokenAndCompany}`, { params: obj }).then(result=>result.data)
    }

    //отримати картку відвалу
    getArchiveDump(id, obj) {
        return axios.get(`?section=archive&action=getArchiveDump&token=${savedTokenAndCompany}&requestId=${id}`, {params:obj}).then(result=>result.data)
    }

    // архів холодних дзвінків
    getColdCalls(obj){
        return axios.get(`?section=archive&action=getArchiveColdCalls&token=${savedTokenAndCompany}`, {params:obj}).then(result => result.data)
    }

    // отримати картку з архіву холодних дзвінків
    getCardColdCall(id){
        return axios.get(`?section=archive&action=getArchiveColdCall&token=${savedTokenAndCompany}&requestId=${id}`).then(result => result.data)
    }

}

export class Services { 

    // получить весь список услуг
    getService(obj) {
        return axios.get(`?section=services&action=getServices&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить услугу
    addService(obj) {
        return axios.post(`?section=services&action=addService&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // получить услугу
    getCardService(id) {
        return axios.get(`?section=services&action=getService&token=${savedTokenAndCompany}&nomenclatureId=${id}`).then(response => response.data);
    }

    //изменение услуги 
    editService(id, obj) {
        return axios.post(`?section=services&action=editService&token=${savedTokenAndCompany}&nomenclatureId=${id}`, obj).then(response => response.data);
    }

    //удалить услугу
    deleteService(id) {
        return axios.get(`?section=services&action=deleteService&token=${savedTokenAndCompany}&nomenclatureId=${id}`).then(response => response.data);
    }

}

export class Connects { 

    // получить весь настроек подключения
    getConnects(obj) {
        return axios.get(`?section=connectionSettings&action=getSettingsList&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить настройку подключения
    createSettings(obj) {
        return axios.post(`?section=connectionSettings&action=createSettings&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //изменить настройку подключения
    editSettings(id, obj) {
        return axios.post(`?section=connectionSettings&action=editSettings&token=${savedTokenAndCompany}&connectionSettingsId=${id}`, obj).then(response => response.data);
    }

    //Получить карточку настройки подклю
    getSettings(id) {
        return axios.get(`?section=connectionSettings&action=getSettings&token=${savedTokenAndCompany}&connectionSettingsId=${id}`).then(response => response.data);
    }

    //удалить карточку настройки 
    removeSettings(id) {
        return axios.delete(`?section=connectionSettings&action=removeSettings&token=${savedTokenAndCompany}&connectionSettingsId=${id}`).then(response => response.data);
    }

}

export class KW { 

    deleteLessons(id) {
        return axios.post(`?section=knowledgeBase&action=deleteLessons&token=${savedTokenAndCompany}&lessonIds=${id}`).then(response=>response.data);
    }

    transferLessons(obj) {
        return axios.post(`?section=knowledgeBase&action=editLessonsCategory&token=${savedTokenAndCompany}`, obj).then(response=>response.data);
    }

    getPosts(obj) {
        return axios.get(`?section=knowledgeBase&action=getLessons&token=${savedTokenAndCompany}`, { params: obj }).then(response=>response.data);
    } 

    //создать урок
    createLesson(obj) {
        return axios.post(`?section=knowledgeBase&action=addLesson&token=${savedTokenAndCompany}`, obj).then(response=>response.data)
    }

    // получить список уроков
    getCateg(obj) {
        return axios.get(`?section=knowledgeBaseCategory&action=getCategories&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить категорию
    addCategory(obj) {
        return axios.post(`?section=knowledgeBaseCategory&action=addCategory&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить карточку урока
    getPost(id) {
        return axios.get(`?section=knowledgeBase&action=getLesson&token=${savedTokenAndCompany}&lessonId=${id}`).then(response => response.data);
    }

    //удалить урок 
    deleteLesson(id) {
        return axios.get(`?section=knowledgeBase&action=deleteLesson&token=${savedTokenAndCompany}&lessonId=${id}`).then(response => response.data);
    }

    //удалить категорию 
    deleteCateg(id) {
        return axios.get(`?section=knowledgeBaseCategory&action=deleteCategory&token=${savedTokenAndCompany}&categoryId=${id}`).then(response => response.data);
    }
    
    //изменить урок
    editLesson(id, obj) {
        return axios.post(`?section=knowledgeBase&action=editLesson&token=${savedTokenAndCompany}&lessonId=${id}`, obj)
            .then(response => response.data)
            .catch(error => {
                storeS.preloaderTable = false;
                var data = {
                    id: id,
                    obj: obj
                }
                eventB.emit("showRepeatBox", data );
            });
    }

    editCateg(id, obj) {
        return axios.post(`?section=knowledgeBaseCategory&action=editCategory&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response=>response.data)
    }

    //видалити категорію з уроками
    delWithLessons(id, obj) {
        return axios.post(`?section=knowledgeBaseCategory&action=deleteCategory&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response=>response.data)
    }
    //видалити категорію з уроками

    //добавить теги в пост
    // addTagsPost(id, obj) {
    //     return axios.post(`http://localhost:3001/post/${id}/`, obj).then(response => response.data);
    // }

    // збереження дерева категорій у базі знань
    saveCategoriesTree() {
        return axios.post(`?section=knowledgeBaseCategory&action=saveCategoriesTree&token=${savedTokenAndCompany}`).then(response=>response.data)
    }

    // отримання дерева категорій у базі знань
    getCategoriesTree() {
        return axios.post(`?section=knowledgeBaseCategory&action=getCategoriesTree&token=${savedTokenAndCompany}`).then(response=>response.data)
    }

    // отримання списку уроків за категорією
    getLessonsList(id, obj) {
        return axios.get(`?section=knowledgeBase&action=getLessonsList&token=${savedTokenAndCompany}&categoryId=${id}`, { params: obj }).then(response => response.data);
    }

    // видалити батьківську категорію
    delWithCategories(id, obj) {
        return axios.post(`?section=knowledgeBaseCategory&action=removeParentCategory&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response=>response.data)
    }
}

export class knowledgeBaseProject { 

    // створення проекту
    addProject(obj) {
        return axios.post(`?section=knowledgeBaseProject&action=addProject&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // удаление проекта
    deleteProject(id) {
        return axios.post(`?section=knowledgeBaseProject&action=deleteProject&token=${savedTokenAndCompany}&projectId=${id}`).then(response=>response.data)
    }

    // редагування проекту
    editProject(id, obj) {
        return axios.post(`?section=knowledgeBaseProject&action=editProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data)
    }

    // отримати картку інформації про проект
    getProject(id) {
        return axios.get(`?section=knowledgeBaseProject&action=getProject&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data)
    }

    // отримати список проектів
    getProjects(obj){
        return axios.get(`?section=knowledgeBaseProject&action=getProjects&token=${savedTokenAndCompany}`, {params: obj}).then(response => response.data)
    }

    // відновлення проекту
    restoreProject(id) {
        return axios.post(`?section=knowledgeBaseProject&action=restoreProject&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data)
    }

    // отримання списку категорій за проектом
    getCategoriesTree(id) {
        return axios.get(`?section=knowledgeBaseCategory&action=getCategoriesTree&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data)
    }

    // збереження дерева категорій у базі знань (за проектами) -> поле додано projectId
    saveCategoriesTree(obj) {
        return axios.post(`?section=knowledgeBaseCategory&action=saveCategoriesTree&token=${savedTokenAndCompany}`, obj).then(response => response.data)
    }

}

export class FAQ { 
    // отримати картку посту
    getPost(id) {
        return axios.get(`?section=faqPosts&action=getPost&token=${savedTokenAndCompany}&postId=${id}`).then(response => response.data)
    }
    // отримати картку посту
    
    // отримати всі уроки
    getPosts(obj){
        return axios.get(`?section=faqPosts&action=getPosts&token=${savedTokenAndCompany}`, {params: obj}).then(response => response.data)
    }
    // отримати всі уроки 

    //редактировать категорию
    editCateg(id, obj) {
        return axios.post(`?section=faq&action=editCategory&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response => response.data)
    }

    //получить карточку категории
    getCategCart(id) {
        return axios.get(`?section=faq&action=getCategory&token=${savedTokenAndCompany}&categoryId=${id}`).then(response=> response.data)
    }

    // получение категорий
    getCateg(obj) {
        return axios.get(`?section=faq&action=getCategories&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }


    //добавить категорию
    addCategoryF(obj) {
        return axios.post(`?section=faq&action=addCategory&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //добавить faq в категорию
    addFaqCateg(obj) {
        return axios.post(`?section=faqPosts&action=addPost&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }


    //удалить урок 
    deleteLesson(id) {
        return axios.get(`?section=faqPosts&action=deletePost&token=${savedTokenAndCompany}&postId=${id}`).then(response => response.data);
    }

    //удалить категорию 
    deleteCateg(id) {
        return axios.get(`?section=faq&action=deleteCategory&token=${savedTokenAndCompany}&categoryId=${id}`).then(response => response.data);
    }

    //изменить урок
    editLesson(id, obj) {
        return axios.post(`?section=faqPosts&action=editPost&token=${savedTokenAndCompany}&postId=${id}`, obj).then(response => response.data);
    }

    //видалити категорію з уроками
    deleteCategWithLessons(id, obj) {
        return axios.post(`?section=faq&action=deleteCategory&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response => response.data);
    }
}


export class Shortcuts { 

    //получить ярлыки
    getLabels(obj) {
        return axios.get(`?section=shortcuts&action=getShortcuts&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить ярлык
    addShortcut(obj) {
        return axios.post(`?section=shortcuts&action=addShortcut&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить карточку ярлыка
    getLabel(id) {
        return axios.get(`?section=shortcuts&action=getShortcut&token=${savedTokenAndCompany}&shortcutId=${id}`).then(response => response.data);
    }

    //удалить ярлык 
    deleteShortcut(id) {
        return axios.get(`?section=shortcuts&action=deleteShortcut&token=${savedTokenAndCompany}&shortcutId=${id}`).then(response => response.data);
    }

    //изменить ярлык
    editShortcut(id, obj) {
        return axios.post(`?section=shortcuts&action=editShortcut&token=${savedTokenAndCompany}&shortcutId=${id}`, obj).then(response => response.data);
    }

    //группы к ярлыкам

    //получить группы
    getGroupsLabels() {
        return axios.get(`?section=groups&action=getGroups&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    //добавить группу
    addGroup(obj) {
        return axios.post(`?section=groups&action=addGroup&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //удалить группу 
    deleteGroup(id) {
        return axios.get(`?section=groups&action=deleteGroup&token=${savedTokenAndCompany}&groupId=${id}`).then(response => response.data);
    }
    
    //Получить карточку группы
    getGroupCard(id) {
        return axios.get(`?section=groups&action=getGroup&token=${savedTokenAndCompany}&groupId=${id}`).then(response => response.data);
    }

    //изменить группу
    editGroup(id, obj) {
        return axios.post(`?section=groups&action=editGroup&token=${savedTokenAndCompany}&groupId=${id}`, obj).then(response => response.data);
    }

}

export class Patterns {
    
    //добавить группу
    addGroup(obj) {
        return axios.post(`?section=sendPulse&action=addGroup&&token=${savedTokenAndCompany}`, obj).then(response=>response.data);
    }

    //добавить шаблон
    addTemplate(obj) {
        return axios.post(`?section=sendPulse&action=addTemplate&token=${savedTokenAndCompany}`, obj).then(response=>response.data);
    }

    //список групп
    getGroups(obj) {
        return axios.get(`?section=sendPulse&action=getGroups&token=${savedTokenAndCompany}`, { params: obj }).then(response=>response.data);
    }

    //отправить mail
    sendEmail(obj) {
        return axios.post(`?section=sendPulse&action=sendEmail&token=${savedTokenAndCompany}`, obj).then(response=>response.data);
    }

    // видалити групу
    deletePattern(id) {
        return axios.post(`?section=sendPulse&action=deleteGroup&token=${savedTokenAndCompany}&id=${id}`).then(response=>response.data);
    }

    // редагувати шаблон
    editPattern(id, obj) {
        return axios.post(`?section=sendPulse&action=editTemplate&token=${savedTokenAndCompany}&templateId=${id}`, obj).then(response=>response.data);
    }
}

export class Projects { 
    
    //відновити проєкт
    recoverProject(id) {
        return axios.post(`?section=projects&action=restoreProject&timezone=-3&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    //получить проекты
    getProjects(obj) {
        return axios.get(`?section=projects&action=getProjects&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить карточку проект
    getCardProjects(id) {
        return axios.get(`?section=projects&action=getProject&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    //добавить проект
    addProject(obj) {
        return axios.post(`?section=projects&action=addProject&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //закрыть проект
    closeProject(id, obj) {
        return axios.post(`?section=projects&action=closeProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data)
    }

    //виконати проект
    completeProject(id, obj) {
        return axios.post(`?section=projects&action=completeProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data)
    }

    //додати рішення до проекту
    decideProject(id, obj) {
        return axios.post(`?section=projects&action=decideProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data)
    }

    //взять проект в работу
    toWorkProject(id) {
        return axios.post(`?section=projects&action=addProjectInJob&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data)
    }

    //изменить проект
    editProject(id, obj) {
        return axios.post(`?section=projects&action=editProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

    //удалить проект 
    deleteProject(id) {
        return axios.get(`?section=projects&action=deleteProject&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    //изменить правку к проекту
    // editEditProject(id, obj) {
    //     return axios.put(`http://localhost:3001/editprojects/${id}/`, obj).then(response => response.data);
    // }

    //получить проекты
    getArchiveProjects(obj) {
        return axios.get(`?section=archive&action=getArchiveProjects&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //Получить сообщения по проекту
    getMessages(id) {
        return axios.get(`?section=projectChat&action=getMessages&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }
    
    //Редактирование сообщения
    editMessage(id, obj) {
        return axios.post(`?section=projectChat&action=editMessage&token=${savedTokenAndCompany}&messageId=${id}`, obj).then(response=>response.data);
    }

    //Удаление сообщения
    deleteMessage(id) {
        return axios.get(`?section=projectChat&action=deleteMessage&token=${savedTokenAndCompany}&messageId=${id}`).then(response=>response.data)
    }

    //добавить сообщение по проекту
    sendMessage(id, obj) {
        return axios.post(`?section=projectChat&action=sendMessage&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }
    
    //правки по проекту
    getProjectEdits(id) {
        return axios.get(`?section=projectEdits&action=getProjectEdits&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    //подтверждение правки 
    approveProjectEdits(id) {
        return axios.get(`?section=projectEdits&action=confirmProjectEdit&token=${savedTokenAndCompany}&projectEditId=${id}`).then(response=>response.data);
    }

    //удаление правки
    deleteProjectEdits(id) {
        return axios.get(`?section=projectEdits&action=deleteProjectEdit&token=${savedTokenAndCompany}&projectEditId=${id}`).then(response=>response.data);
    }

    //карточка правки по проекту
    getProjectEdit(id) {
        return axios.get(`?section=projectEdits&action=getProjectEdit&token=${savedTokenAndCompany}&projectEditId=${id}`).then(response => response.data);
    }

    //добавление правок по проекту
    addProjectEdit(id, obj) {
        return axios.post(`?section=projectEdits&action=addProjectEdit&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }
    
    //список задач по проекту
    getProjectTasks(id) {
        return axios.get(`?section=projectTasks&action=getProjectTasks&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    //сохранение задач по проекту
    saveProjectTasks(id, obj) {
        return axios.post(`?section=projectTasks&action=saveProjectTasks&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }
    
    //добавить реплай по проекту
    replyOnMessage(id, messageId, obj) {
        return axios.post(`?section=projectChat&action=replyOnMessage&token=${savedTokenAndCompany}&projectId=${id}&messageId=${messageId}`, obj).then(response => response.data);
    }

    //додавання співробітників до проекту
    addWorkersToProject(id, obj) {
        return axios.post(`?section=projects&action=addWorkersToProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

    //редактирование сотрудников в проекте
    editWorkersInProject(id, obj) {
        return axios.post(`?section=projects&action=editWorkersInProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

    //выполнение правки по проекту
    completeProjectEdit(id, obj) {
        return axios.post(`?section=projectEdits&action=completeProjectEdit&token=${savedTokenAndCompany}&projectEditId=${id}`, obj).then(response => response.data);
    }

    //загрузить файлы в проект (несколько, через форму)
    uploadFiles(id, formData) {
        return axios.post(`?section=projectFiles&action=uploadFiles&token=${savedTokenAndCompany}&projectId=${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }).then(response => response.data);
    }

    //удалить файл в проекте
    deleteFile(id) {
        return axios.get(`?section=projectFiles&action=deleteFile&token=${savedTokenAndCompany}&fileId=${id}`).then(response => response.data);
    }
    
    //получить файлы в проекте
    getFiles(id){
        return axios.get(`?section=projectFiles&action=getFiles&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    //просмотр файла в проекте
    getFile(id) {
        return axios.get(`?section=projectFiles&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

    //зміна пріоритету проекту
    changePriority(id, obj) {
        return axios.post(`?section=projects&action=changePriority&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

}

export class Invoices { 

    //Получить карточку счета 
    getInv(id) {
        return axios.get(`?section=sales&action=getSale&documentId=${id}&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    //получить все счета
    getAllInv(obj) {
        return axios.get(`?section=sales&action=getSales&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить счет-фактуру
    addSale(obj) {
        return axios.post(`?section=sales&action=addSale&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //удалить карточку счета
    deleteInv(id) {
        return axios.get(`?section=sales&action=removeSale&token=${savedTokenAndCompany}&documentId=${id}`).then(response => response.data);
    }
    
    //подтверждения счета
    confirmSale(id, obj) {
        return axios.post(`?section=sales&action=confirmSale&token=${savedTokenAndCompany}&documentId=${id}`, obj).then(response => response.data);
    }

    //анулювання рахунку
    cancelSale(id, obj) {
        return axios.post(`?section=sales&action=cancelSale&token=${savedTokenAndCompany}&documentId=${id}`, obj).then(response => response.data);
    }

    //редагування рахунку
    editSale(id, obj) {
        return axios.post(`?section=sales&action=editSale&token=${savedTokenAndCompany}&documentId=${id}`, obj).then(response => response.data);
    }

    //скидання номера рахунку
    resettingSaleNumber(obj) {
        return axios.post(`?section=sales&action=resettingSaleNumber&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //скидання номерів рахунків за постачальниками
    resettingNumbersByProviders(obj) {
        return axios.post(`?section=sales&action=resettingNumbersByProviders&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }
    
}


export class Currencies {

    //Получить список валют 
    getCurrencies() {
        return axios.get(`?section=currencies&action=getCurrencies&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    //Получить карточку валюты 
    getCurrency(id) {
        return axios.get(`?section=currencies&action=getCurrency&token=${savedTokenAndCompany}&currencyId=${id}`).then(response => response.data);
    }

    //добавить валюту
    addCurrency(obj) {
        return axios.post(`?section=currencies&action=addCurrency&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //редактирование валюты
    editCurrency(id, obj) {
        return axios.post(`?section=currencies&action=editCurrency&token=${savedTokenAndCompany}&currencyId=${id}`, obj).then(response => response.data);
    }

    //удалить карточку валюты
    deleteCurrency(id) {
        return axios.get(`?section=currencies&action=deleteCurrency&token=${savedTokenAndCompany}&currencyId=${id}`).then(response => response.data);
    }

}

export class EcoSetting { 

    //Получить настройки магазина
    getSettings() {
        return axios.get(`?section=settings&action=getSettings&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    saveSettings(obj) {
        return axios.post(`?section=settings&action=saveSettings&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

}

export class Products { 

    //Получить карточку товара 
    getProduct(id) {
        return axios.get(`?section=products&action=getProduct&token=${savedTokenAndCompany}&nomenclatureId=${id}`).then(response => response.data);
    }

    //получить все товары
    getAllProducts(obj) {
        return axios.get(`?section=products&action=getProducts&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить товар
    addProduct(obj) {
        return axios.post(`?section=products&action=addProduct&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //редактирование товара
    editCategoryProducts(id, obj) {
        return axios.post(`?section=products&action=editProduct&token=${savedTokenAndCompany}&nomenclatureId=${id}`, obj).then(response => response.data);
    }

    //удалить карточку товар
    deleteProduct(id) {
        return axios.get(`?section=products&action=deleteProduct&token=${savedTokenAndCompany}&nomenclatureId=${id}`).then(response => response.data);
    }
}

export class productFiles {

    // загрузка файлов по товару
    // данные для формы: files[]
    uploadFiles(id, data, type) {
        return axios.post(`?section=productFiles&action=uploadFiles&token=${savedTokenAndCompany}&nomenclatureId=${id}`, data, {
            headers: {
                'Content-Type': type,
            },
        }).then(response => response.data);
    }


    // удаление файла по товару
    deleteFile(id) {
        return axios.get(`?section=productFiles&action=deleteFile&token=${savedTokenAndCompany}&fileId=${id}`).then(response => response.data);
    }
}

export class CategoriesProducts { 

    //Получить карточку категории 
    getCategory(id) {
        return axios.get(`?section=productCategory&action=getCategory&token=${savedTokenAndCompany}&categoryId=${id}`).then(response => response.data);
    }

    //получить все категории
    getAllCategories(search) {
        return axios.get(`?section=productCategory&action=getCategories&token=${savedTokenAndCompany}&search=${search}`).then(response => response.data);
    }

    //добавить категорию
    addCategoryProducts(obj) {
        return axios.post(`?section=productCategory&action=addCategory&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //изменение категории
    editCategoryProducts(id, obj) {
        return axios.post(`?section=productCategory&action=editCategory&token=${savedTokenAndCompany}&categoryId=${id}`, obj).then(response => response.data);
    }

    //удалить категорию
    deleteCategoryProducts(id) {
        return axios.get(`?section=productCategory&action=deleteCategory&token=${savedTokenAndCompany}&categoryId=${id}`).then(response => response.data);
    }
}

export class ProvidersProducts { 

    //Получить карточку поставщика 
    getProvider(id) {
        return axios.get(`?section=providers&action=getProvider&token=${savedTokenAndCompany}&providerId=${id}`).then(response => response.data);
    }

    //получить всех поставщиком
    getAllProviders(obj) {
        return axios.get(`?section=providers&action=getProviders&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить поставщика
    addProvider(obj) {
        return axios.post(`?section=providers&action=addProvider&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //удалить поставщика
    deleteProvide(id) {
        return axios.get(`?section=providers&action=deleteProvider&token=${savedTokenAndCompany}&providerId=${id}`).then(response => response.data);
    }

    // редактирование поставщика
    editProvider(id, obj) {
        return axios.post(`?section=providers&action=editProvider&token=${savedTokenAndCompany}&providerId=${id}`, obj).then(result=>result.data)
    }
}

export class Attributes { 

    //Получить карточку
    getAttr(id) {
        return axios.get(`http://localhost:3001/attributes/${id}/`).then(response => response.data);
    }

    //получить весь список
    getAllAttrs(obj) {
        return axios.get(`http://localhost:3001/attributes`, { params: obj }).then(response => response.data);
    }

    //добавить 
    addAttr(obj) {
        return axios.post(`http://localhost:3001/attributes/`, obj).then(response => response.data);
    }

    //удалить
    deleteAttr(id) {
        return axios.delete(`http://localhost:3001/attributes/${id}/`).then(response => response.data);
    }
}

export class Breaks { 

    //Получить карточку
    getBreak(id) {
        return axios.get(`?section=breaks&action=getBreak&token=${savedTokenAndCompany}&breakId=${id}`).then(response => response.data);
    }

    //получить весь список
    getAllBreaks(obj) {
        return axios.get(`?section=breaks&action=getBreaks&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить 
    createBreak(id, obj) {
        return axios.post(`?section=breaks&action=createBreak&token=${savedTokenAndCompany}&workerId=${id}`, obj).then(response => response.data);
    }

    //изменить 
    editBreak(id, obj) {
        return axios.put(`?section=breaks&action=editBreak&token=${savedTokenAndCompany}&breakId=${id}`, obj).then(response => response.data);
    }

    // закрытие перерыва 
    closeBreak(id) {
        return axios.get(`?section=breaks&action=closeBreak&token=${savedTokenAndCompany}&breakId=${id}`).then(response => response.data);
    }
}

export class Fines { 

    //Получить карточку
    getFine(id) {
        return axios.get(`?section=fines&action=getFine&token=${savedTokenAndCompany}&fineId=${id}`).then(response => response.data);
    }

    //получить весь список
    getAllFines(obj) {
        return axios.get(`?section=fines&action=getFines&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить 
    addFine(id, obj) {
        return axios.post(`?section=fines&action=addFine&token=${savedTokenAndCompany}&workerId=${id}`, obj).then(response => response.data);
    }

    //изменить 
    editFine(id, obj) {
        return axios.post(`?section=fines&action=editFine&token=${savedTokenAndCompany}&fineId=${id}`, obj).then(response => response.data);
    }

    //удалить
    deleteFine(id) {
        return axios.get(`?section=fines&action=deleteFine&token=${savedTokenAndCompany}&fineId=${id}`).then(response => response.data);
    }

    //подтвердить  
    confirmFine(id) {
        return axios.post(`?section=fines&action=confirmFine&token=${savedTokenAndCompany}&fineId=${id}`).then(response => response.data);
    }

    //скасувати  
    cancelFine(id, obj) {
        return axios.post(`?section=fines&action=cancelFine&token=${savedTokenAndCompany}&fineId=${id}`, obj).then(response => response.data);
    }

}


export class Updates { 

    // змінити статус дедлайну
    deadlineStatus(id, obj) {
        return axios.post(`?section=deadlines&action=changeDeadlineStatus&token=${savedTokenAndCompany}&deadlineId=${id}`, obj).then(response => response.data);
    }

    //Получить карточку
    getUpdate(id) {
        return axios.get(`?section=deadlines&action=getDeadline&token=${savedTokenAndCompany}&deadlineId=${id}`).then(response => response.data);
    }

    //получить весь список
    getAllUpdates(obj) {
        return axios.get(`?section=deadlines&action=getDeadlines&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить 
    addUpdate(obj) {
        return axios.post(`?section=deadlines&action=createDeadline&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //изменить 
    editUpdate(id, obj) {
        return axios.post(`?section=deadlines&action=editDeadline&token=${savedTokenAndCompany}&deadlineId=${id}`, obj).then(response => response.data);
    }

    //удалить
    deleteUpdate(id) {
        return axios.get(`?section=deadlines&action=deleteDeadline&token=${savedTokenAndCompany}&deadlineId=${id}`).then(response => response.data);
    }

    //сохранение изменений по проектам в дедлайне
    saveChangesProj(id, obj) {
        return axios.post(`?section=deadlines&action=saveProjectChanges&token=${savedTokenAndCompany}&deadlineId=${id}`, obj).then(response => response.data);
    }

    //сохранение изменений по тикетам в дедлайне
    saveChangesTick(id, obj) {
        return axios.post(`?section=deadlines&action=saveTicketChanges&token=${savedTokenAndCompany}&deadlineId=${id}`, obj).then(response => response.data);
    }

    // нові апі - 02.09.2024

    // покращення

    // Додавання покращення
    // {
    //     "text": "test"
    // }
    addImprovement(obj, deadlineId) {
        return axios.post(`?section=improvements&action=addImprovement&token=${savedTokenAndCompany}${deadlineId ? '&deadlineId='+deadlineId : ''}`, obj).then(response => response.data);
    }

    // Видалення покращення
    deleteImprovement(improvementId) {
        return axios.get(`?section=improvements&action=deleteImprovement&token=${savedTokenAndCompany}&improvementId=${improvementId}`).then(response => response.data);
    }

    // видалення файлу по покращенню
    deleteFileImprovement(fileId) {
        return axios.post(`?section=improvements&action=deleteFile&token=${savedTokenAndCompany}&fileId=${fileId}`).then(response => response.data);
    }

    // https://api.inside.skyservice.pro/?section=improvements&action=editImprovement&token=dQb9779ZQtHKRKKAA8s4tkZ24fYR9bZs&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&improvementId=2
    
    // Редагування покращення
    // {
    //     "text": "test",
    //     "passedTesting": true,
    //     "associatedWithTicket": true,
    //     "description": "desc",
    //     "workerIds": [1, 2],
    //     "ticketIds": [2, 3]
    // }
    editImprovement(improvementId, obj) {
        return axios.post(`?section=improvements&action=editImprovement&token=${savedTokenAndCompany}&improvementId=${improvementId}`, obj).then(response => response.data);
    }

    // Перегляд файла по покращенню
    getFileImprovement(id) {
        return axios.get(`?section=improvements&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

    // завантаження файлу за покращенням
    uploadFilesImprovement(id, data, type) {
        return axios.post(`?section=improvements&action=uploadFiles&token=${savedTokenAndCompany}&improvementId=${id}`, data, {
            headers: {
                'Content-Type': type,
            },
        }).then(response => response.data);
    }

    // https://api.inside.skyservice.pro/?section=improvements&action=getImprovement&token=dQb9779ZQtHKRKKAA8s4tkZ24fYR9bZs&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&improvementId=2
    // берем інформацію покращення ??
    getImprovement(improvementId) {
        return axios.post(`?section=improvements&action=getImprovement&token=${savedTokenAndCompany}&improvementId=${improvementId}`).then(response => response.data);
    }

    // Виправлення

    // Додавання Виправлення
    // {
    //     "text": "test"
    // }
    addFixed(obj, deadlineId) {
        return axios.post(`?section=deadlinesFixed&action=addFixed&token=${savedTokenAndCompany}${deadlineId ? '&deadlineId='+deadlineId : ''}`, obj).then(response => response.data);
    }

    // Видалення Виправлення
    deleteFixed(fixedId) {
        return axios.get(`?section=deadlinesFixed&action=deleteFixed&token=${savedTokenAndCompany}&fixedId=${fixedId}`).then(response => response.data);
    }

    // видалення файлу по Виправлення
    deleteFileDeadlinesFixed(fileId) {
        return axios.post(`?section=deadlinesFixed&action=deleteFile&token=${savedTokenAndCompany}&fileId=${fileId}`).then(response => response.data);
    }

    // Редагування Виправлення
    // {
    //     "text": "test",
    //     "passedTesting": true,
    //     "associatedWithTicket": true,
    //     "description": "desc",
    //     "workerIds": [1, 2],
    //     "ticketIds": [2, 3]
    // }
    editFixed(fixedId, obj) {
        return axios.post(`?section=deadlinesFixed&action=editFixed&token=${savedTokenAndCompany}&fixedId=${fixedId}`, obj).then(response => response.data);
    }

    // Перегляд файла по Виправлення
    getFileFixed(id) {
        return axios.get(`?section=deadlinesFixed&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

    // завантаження файлу за Виправлення
    uploadFilesFixed(id, data, type) {
        return axios.post(`?section=deadlinesFixed&action=uploadFiles&token=${savedTokenAndCompany}&fixedId=${id}`, data, {
            headers: {
                'Content-Type': type,
            },
        }).then(response => response.data);
    }

    // https://api.inside.skyservice.pro/?section=deadlinesFixed&action=getFixed&token=dQb9779ZQtHKRKKAA8s4tkZ24fYR9bZs&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&fixedId=1
    // берем інформацію Виправлення ??
    getFixed(fixedId) {
        return axios.post(`?section=deadlinesFixed&action=getFixed&token=${savedTokenAndCompany}&fixedId=${fixedId}`).then(response => response.data);
    }

    // Нові можливості

    // Додавання нової можливості
    // {
    //     "text": "test"
    // }
    addOpportunity(obj, deadlineId) {
        return axios.post(`?section=newOpportunities&action=addOpportunity&token=${savedTokenAndCompany}${deadlineId ? '&deadlineId='+deadlineId : ''}`, obj).then(response => response.data);
    }

    // Видалення нової можливості
    deleteOpportunity(opportunityId) {
        return axios.get(`?section=newOpportunities&action=deleteOpportunity&token=${savedTokenAndCompany}&opportunityId=${opportunityId}`).then(response => response.data);
    }

    // видалення файлу по новій можливості
    deleteFileNewOpportunities(fileId) {
        return axios.post(`?section=newOpportunities&action=deleteFile&token=${savedTokenAndCompany}&fileId=${fileId}`).then(response => response.data);
    }

    // Редагування нової можливості
    // {
    //     "text": "test",
    //     "passedTesting": true,
    //     "associatedWithTicket": true,
    //     "description": "desc",
    //     "workerIds": [1, 2],
    //     "ticketIds": [2, 3]
    // }
    editOpportunity(opportunityId, obj) {
        return axios.post(`?section=newOpportunities&action=editOpportunity&token=${savedTokenAndCompany}&opportunityId=${opportunityId}`, obj).then(response => response.data);
    }

    // Перегляд файла по нової можливості
    getFileOpportunities(id) {
        return axios.get(`?section=newOpportunities&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

    // завантаження файлів за нової можливості
    uploadFilesOpportunities(id, data, type) {
        return axios.post(`?section=newOpportunities&action=uploadFiles&token=${savedTokenAndCompany}&opportunityId=${id}`, data, {
            headers: {
                'Content-Type': type,
            },
            onUploadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }).then(response => response.data);
    }

    // завантаження файлу за нової можливості (бінарний)
    // uploadFileOpportunities(id, data, type) {
    //     return axios.post(`?section=newOpportunities&action=uploadFile&token=${savedTokenAndCompany}&opportunityId=${id}`, data, {
    //         headers: {
    //             'Content-Type': type,
    //         },
    //     }).then(response => response.data);
    // }

    // https://api.inside.skyservice.pro/?section=newOpportunities&action=getOpportunity&token=dQb9779ZQtHKRKKAA8s4tkZ24fYR9bZs&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&opportunityId=1
    // берем інформацію нової можливості ??
    getOpportunity(opportunityId) {
        return axios.post(`?section=newOpportunities&action=getOpportunity&token=${savedTokenAndCompany}&opportunityId=${opportunityId}`).then(response => response.data);
    }

}

// export class OtherCalls {

//     //получить весь список
//     getAll(obj) {
//         return axios.get(`http://localhost:3001/otherlist`, { params: obj }).then(response => response.data);
//     }

//     //добавить 
//     add(obj) {
//         return axios.post(`http://localhost:3001/otherlist/`, obj).then(response => response.data);
//     }

//     //получить весь список
//     getAllReq(obj) {
//         return axios.get(`http://localhost:3001/othercall`, { params: obj }).then(response => response.data);
//     }

//     //получить весь список по id списка
//     getAllCallslist(id, obj) {
//         return axios.get(`http://localhost:3001/othercall?id_category=${id}`, { params: obj }).then(response => response.data);
//     }

//     //Получить карточку
//     getOtherCall(id) {
//         return axios.get(`http://localhost:3001/othercall/${id}/`).then(response => response.data);
//     }

//     //добавить 
//     addReq(obj) {
//         return axios.post(`http://localhost:3001/othercall/`, obj).then(response => response.data);
//     }

//     //удалить
//     deleteReq(id) {
//         return axios.delete(`http://localhost:3001/othercall/${id}/`).then(response => response.data);
//     }

//     //получить с API
//     // https://mocki.io/v1/45ea3230-803b-4f8d-8ab6-7d827b6674a9
//     getlistAPI(link, obj) {
//         return axios.get(`${link}`, { params: obj }).then(response => response.data);
//     }

// }

export class Statistics {

    //получить статистику по кол-центру
    getAll(obj) {
        return axios.get(`?section=statistics&action=statsByCallCenter&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить for dev
    getStatAlldev(obj) {
        return axios.get(`?section=statistics&action=statsByDevs&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получить for users
    getStatAllusers(obj) {
        return axios.get(`?section=statistics&action=statsByWorkers&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // статистика по продажам
    statsBySales(obj) {
        return axios.get(`?section=statistics&action=statsBySales&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }
    
}

export class Presentations {

    // Призначити людину за презентацією
    assingOnPresent(id, obj) {
        return axios.post(`?section=presentations&action=saveAssignedWorkers&token=${savedTokenAndCompany}&presentationId=${id}`, obj).then(response => response.data);
    }

    // Вийти з призначенної презентації
    exitFromPresent(id) {
        return axios.get(`?section=presentations&action=exitAssignedPresentation&token=${savedTokenAndCompany}&presentationId=${id}`).then(response => response.data);
    }

    // видалити "пачкою"
    removePresentations(obj) {
        return axios.post(`?section=presentations&action=deletePresentations&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //получить 
    getList(obj) {
        return axios.get(`?section=presentations&action=getPresentations&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить карточку 
    add(obj) {
        return axios.post(`?section=presentations&action=addPresentation&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Получить карточку 
    getCard(id) {
        return axios.get(`?section=presentations&action=getPresentation&token=${savedTokenAndCompany}&presentationId=${id}`).then(response => response.data);
    }

    //удалить карточку 
    deleteCard(id) {
        return axios.get(`?section=presentations&action=deletePresentation&token=${savedTokenAndCompany}&presentationId=${id}`).then(response => response.data);
    }

    //взять в работу 
    addPresentationInJob(id) {
        return axios.post(`?section=presentations&action=addPresentationInJob&token=${savedTokenAndCompany}&presentationId=${id}`).then(response => response.data);
    }

    //отмена презентации 
    cancelPresentation(id, obj) {
        return axios.post(`?section=presentations&action=cancelPresentation&token=${savedTokenAndCompany}&presentationId=${id}`, obj).then(response => response.data);
    }

    //выполнить презентацию
    closePresentation(id, obj) {
        return axios.post(`?section=presentations&action=closePresentation&token=${savedTokenAndCompany}&presentationId=${id}`, obj).then(response => response.data);
    }

    //отложить презентацию
    delayPresentation(id, obj) {
        return axios.post(`?section=presentations&action=delayPresentation&token=${savedTokenAndCompany}&presentationId=${id}`, obj).then(response => response.data);
    }

    //передача презентации 
    transferPresentation(id, workerId) {
        return axios.post(`?section=presentations&action=transferPresentation&token=${savedTokenAndCompany}&presentationId=${id}&workerId=${workerId}`).then(response => response.data);
    }

    //фиксация презентации 
    fixationTransferPresentation(id, workerId) {
        return axios.post(`?section=presentations&action=fixationTransferPresentation&token=${savedTokenAndCompany}&presentationId=${id}&workerId=${workerId}`).then(response => response.data);
    }

    //скасувати передачу презентації 
    rejectTransferPresentation(id) {
        return axios.post(`?section=presentations&action=rejectTransferPresentation&token=${savedTokenAndCompany}&presentationId=${id}`).then(response => response.data);
    }

    //редактировать
    edit(id, obj) {
        return axios.post(`?section=presentations&action=editPresentation&token=${savedTokenAndCompany}&presentationId=${id}`, obj).then(response => response.data);
    }

    // змінити статус "йде зараз"
    onAir(obj) {
        return axios.post(`?section=presentations&action=inProgressPresentation&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }
}


export class ApiDocs {

    
    //получить список апи категории
    getApiCategory(id) {
        return axios.get(`?section=apiCategory&action=getApiCategory&token=${savedTokenAndCompany}&apiCategoryId=${id}`).then(response => response.data);
    }

    //добавить апи категорию 
    createApiCategory(obj) {
        return axios.post(`?section=apiCategory&action=createApiCategory&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //удаление апи категории 
    deleteCategory(id) {
        return axios.get(`?section=apiCategory&action=deleteApiCategory&token=${savedTokenAndCompany}&apiCategoryId=${id}`).then(response => response.data);
    }

    //редактирование апи категории 
    editApiCategory(id, obj) {
        return axios.post(`?section=apiCategory&action=editApiCategory&token=${savedTokenAndCompany}&apiCategoryId=${id}`, obj).then(response => response.data);
    }

    //получить список апи категори
    getApiCategories(obj) {
        return axios.post(`?section=apiCategory&action=getApiCategories&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //добавить атрибут
    addAttribute(obj) {
        return axios.post(`?section=attributes&action=addAttribute&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //удалить атрибут
    deleteAttribute(id) {
        return axios.post(`?section=attributes&action=deleteAttribute&token=${savedTokenAndCompany}&attributeId=${id}`).then(response => response.data);
    }
    
    //редактировать атрибут
    editAttribute(id, obj) {
        return axios.post(`?section=attributes&action=editAttribute&token=${savedTokenAndCompany}&attributeId=${id}`, obj).then(response => response.data);
    }

    //получить список атрибутов
    getAttributes(obj) {
        return axios.get(`?section=attributes&action=getAttributes&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получение данных об атрибуте
    getAttribute(id) {
        return axios.post(`?section=attributes&action=getAttribute&token=${savedTokenAndCompany}&attributeId=${id}`).then(response => response.data);
    }

    //добавить апи
    createApi(obj) {
        return axios.post(`?section=api&action=createApi&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //удалить апи
    deleteApi(id) {
        return axios.post(`?section=api&action=deleteApi&token=${savedTokenAndCompany}&apiId=${id}`).then(response => response.data);
    }
    
    //редактировать апи
    editApi(id, obj) {
        return axios.post(`?section=api&action=editApi&token=${savedTokenAndCompany}&apiId=${id}`, obj).then(response => response.data);
    }

    //получить список апи
    getApis(obj) {
        return axios.get(`?section=api&action=getApis&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //получение данных об апи
    getApi(id) {
        return axios.post(`?section=api&action=getApi&token=${savedTokenAndCompany}&apiId=${id}`).then(response => response.data);
    }

}

export class Notes {

    //получить список заметок
    getNotes(obj) {
        return axios.get(`?section=notes&action=getNotes&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //добавить заметку
    addNote(obj) {
        return axios.post(`?section=notes&action=addNote&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //редактировать атрибут
    editNote(id, obj) {
        return axios.post(`?section=notes&action=editNote&token=${savedTokenAndCompany}&noteId=${id}`, obj).then(response => response.data);
    }

    //удалить заметку
    deleteNote(id) {
        return axios.post(`?section=notes&action=deleteNote&token=${savedTokenAndCompany}&noteId=${id}`).then(response => response.data);
    }

}

export class DataCalendar {

    //получить список событий
    getEvents(obj) {
        return axios.get(`?section=events&action=getEvents&token=${savedTokenAndCompany}`, {params : obj}).then(response => response.data);
    }

    //отримати картку події
    getEvent(id) {
        return axios.get(`?section=events&action=getEvent&token=${savedTokenAndCompany}&eventId=${id}`).then(response => response.data);
    }

    //добавить событие
    addEvent(obj) {
        return axios.post(`?section=events&action=addEvent&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //редактировать событие
    editEvent(id, obj) {
        return axios.post(`?section=events&action=editEvent&token=${savedTokenAndCompany}&eventId=${id}`, obj).then(response => response.data);
    }

    //удалить событие
    deleteEvent(id) {
        return axios.post(`?section=events&action=deleteEvent&token=${savedTokenAndCompany}&eventId=${id}`).then(response => response.data);
    }

    //сохранение сотрудников по типу события
    saveWorkers(type, obj) {
        return axios.post(`?section=workersByEventType&action=saveWorkers&token=${savedTokenAndCompany}&eventType=${type}`, obj).then(response => response.data);
    }

    //список сотрудников по событию
    getWorkers(type) {
        return axios.get(`?section=workersByEventType&action=getWorkers&token=${savedTokenAndCompany}&eventType=${type}`).then(response => response.data);
    }

}

export class Unregistered {

    //отримавати список акаунтів
    getAccounts(obj) {
        return axios.get(`?section=unregisteredAccounts&action=getAccounts&token=${savedTokenAndCompany}`, {params : obj}).then(response => response.data);
    }

    //видалення даних незареєстрованого облікового запису
    deleteAccount(id) {
        return axios.post(`?section=unregisteredAccounts&action=deleteAccount&token=${savedTokenAndCompany}&id=${id}`).then(response => response.data);
    }

    //редагування даних незареєстрованого облікового запису
    editAccount(id, obj) {
        return axios.post(`?section=unregisteredAccounts&action=editAccount&token=${savedTokenAndCompany}&id=${id}`, obj).then(response => response.data);
    }

    // отримання даних про незареєстрований обліковий запис
    getAccount(id) {
        return axios.post(`?section=unregisteredAccounts&action=getAccount&token=${savedTokenAndCompany}&id=${id}`).then(response => response.data);
    }
    
}

export class SuperAdmin {

    //список аккаунтов 
    getList(obj) {
        // https://api.admin.skyservice.online/?section=user&action=getUsers&searchMethod=${method}&token=575kAbA7YK4d3BfdNBZ9NnGFrfdYBFB9
        return axios.get(`?section=accounts&action=getAccounts&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //список аккаунтов на удаление
    getListDeleted(obj) {
        // https://api.admin.skyservice.online/?section=user&action=getDeleteAccounts&status=all&token=575kAbA7YK4d3BfdNBZ9NnGFrfdYBFB9
        return axios.get(`?section=accounts&action=getDeleteAccounts&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //Получить карточку по телефону
    getCard(userPhone) {
        // https://api.admin.skyservice.online/?section=user&action=getUser&userPhone=${accountId}&token=575kAbA7YK4d3BfdNBZ9NnGFrfdYBFB
        return axios.get(`?section=accounts&action=getAccount&token=${savedTokenAndCompany}&phone=${userPhone}`).then(response => response.data);
    }

    //Получить карточку по accountId
    getCardAcc(method, search) {
        return axios.get(`?section=accounts&action=getAccount&token=${savedTokenAndCompany}&${method}=${search}`).then(response => response.data);
    }

    //оновлення статусу
    updateAccountStatus(phone, obj) {
        return axios.post(`?section=accounts&action=updateAccountStatus&token=${savedTokenAndCompany}&phone=${phone}`, obj).then(response => response.data);
    }

    //видалити аккаунт //не додавав
    deleteAccount(accountId) {
        return axios.post(`?section=accounts&action=deleteAccount&token=${savedTokenAndCompany}&accountId=${accountId}`).then(response => response.data);
    }

    // відновити акаунт //не додавав
    restoreAccount(accountId) {
        return axios.post(`?section=accounts&action=restoreAccount&token=${savedTokenAndCompany}&accountId=${accountId}`).then(response => response.data);
    }

    //Получить карточку на удаление
    getCardDeleted(accountId) {
        // https://api.admin.skyservice.online/?section=user&action=getDeleteAccount&accountId=${accountId}&token=575kAbA7YK4d3BfdNBZ9NnGFrfdYBFB9
        return axios.get(`?section=accounts&action=getDeleteAccount&token=${savedTokenAndCompany}&accountId=${accountId}`).then(response => response.data);
    }

    //список компаній
    getListCompany(search) {
        return axios.get(`https://api.admin.skyservice.online/?section=company&action=getCompaniesList&page=1&search=${search}&searchMethod=phone&pageLimit=19&token=575kAbA7YK4d3BfdNBZ9NnGFrfdYBFB9`).then(response => response.data)
    }

    //список компаній по id
    getListCompanyBycompanyId(search) {
        return axios.get(`https://api.admin.skyservice.online/?section=company&action=getCompaniesList&page=1&search=${search}&searchMethod=companyId&pageLimit=19&token=575kAbA7YK4d3BfdNBZ9NnGFrfdYBFB9`).then(response => response.data)
    }

    // Апі на отримання даних по тарифу компанії
    getTariff(companyId) {
        return axios.get(`?section=companies&action=getCompanyTariff&token=${savedTokenAndCompany.split('&')[0]}&companyId=${companyId}`).then(response => response.data)
    }


    //выбрать компанию
    getCompClient(companyId) {
        // https://api.admin.skyservice.online/?section=company&action=getCompany&companyId=${companyId}&token=575kAbA7YK4d3BfdNBZ9NnGFrfdYBFB9
        return axios.get(`?section=companies&action=getCompany&token=${savedTokenAndCompany}&companyId=${companyId}`).then(response => response.data);
    }

    //список успешных регистраций 
    getRegisterList(obj) {
        return axios.get(`?section=registrations&action=getCompleted&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //зберегти додаткові дані по акаунту
    saveAccountData(phone, obj) {
        return axios.post(`?section=accounts&action=saveAccountData&token=${savedTokenAndCompany}&phone=${phone}`, obj).then(response => response.data);
    }

    // змінити кількість компаній (ліміт)
    editMaxNumberOfCompanies(accountId, obj) {
        // https://api.inside.skyservice.pro/?section=companies&action=editMaxNumberOfCompanies&token=&companyId=
        return axios.post(`https://api.admin.skyservice.online/?section=user&action=editMaxNumberOfCompanies&token=${savedTokenAndCompany}&accountId=${accountId}`, obj).then(response => response.data);
        // return axios.post(`?section=companies&action=editMaxNumberOfCompanies&token=${savedTokenAndCompany}&accountId=${accountId}`, obj).then(response => response.data);
    }

    // створити нову компанію з новою адмінкою клієнту
    createSkyNewForClient(accountId) {
        return axios.post(`https://api.admin.skyservice.online/?section=user&action=createSkyNewForClient&token=${savedTokenAndCompany}&accountId=${accountId}`).then(response => response.data);
        // return axios.post(`?section=companies&action=createSkyNewForClient&token=${savedTokenAndCompany}&accountId=${accountId}`).then(response => response.data);
    }

    // створити нову компанію з новою адмінкою клієнту (з копіюванням даних зі старого скаю)
    createAndTransferDataSkyNew(obj) {
        // https://api.inside.skyservice.pro/?section=companies&action=createAndTransferDataSkyNew&token=zAGZzsRy34ZkahFdeG3H8ADZ6zTtDEFz&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&lang=uk
        return axios.post(`https://api.admin.skyservice.online/?section=company&action=createAndTransferDataSkyNew&token=${savedTokenAndCompany}`, obj).then(response => response.data);
        // return axios.post(`?section=companies&action=createAndTransferDataSkyNew&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //змінити тип акаунту на власника
    editUserType(accountId, obj) {
        // return axios.post(`?section=accounts&action=editAccountType&token=${savedTokenAndCompany}&accountId=${accountId}`, obj).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=user&action=editUserType&token=${savedTokenAndCompany}&accountId=${accountId}`, obj).then(response => response.data);
    }

    // скопіювати дані з старої адмінки в нову
    transDataFromOldCompany(obj) {
        // https://api.inside.skyservice.pro/?section=companies&action=transDataFromOldCompany&token=&companyId=
        // return axios.post(`?section=companies&action=transDataFromOldCompany&token=${savedTokenAndCompany}`, obj).then(response => response.data);
        return axios.post(`https://api.admin.skyservice.online/?section=company&action=transDataFromOldCompany&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // доступ до акаунту клієнта
    entryInUserAccount(accountId){
        return axios.get(`https://api.admin.skyservice.online/?section=user&action=entryInUserAccount&accountId=${accountId}&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // доступ до акаунту клієнта
    copyInNewVersions(obj){
        return axios.post(`https://api.admin.skyservice.online/?section=company&action=transDataFromNewServerCompany&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }
}

export class Banking {

    //Оновити дату сплати клієнту
    setNewDatepayments(obj) {
        return axios.post(`?section=clientBanking&action=updatePaymentDate&token=${savedTokenAndCompany}`, obj).then(response => response.data);
        // return axios.post(`https://api.admin.skyservice.online/?section=banking&action=setNewDatepayments&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //Встановити коефіцієнт клієнту на компанію
    setNewCoeficient(obj) {
        return axios.post(`?section=clientBanking&action=updateCoefficient&token=${savedTokenAndCompany}`, obj).then(response => response.data);
        // return axios.post(`https://api.admin.skyservice.online/?section=banking&action=setNewCoeficient&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

}

export class Messages {

    //отримати список повідомлень
    getMessages(obj) {
        return axios.get(`?section=messages&action=getMessages&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // прочитати повідомлення
    readMessage(id, projectId, ticketId) {
        return axios.get(`?section=messages&action=readMessage&token=${savedTokenAndCompany}&id=${id}&projectId=${projectId}&ticketId=${ticketId}`).then(response => response.data);
    }

    // прочитати всі повідомлення
    readMessages(ids) {
        return axios.post(`?section=messages&action=readMessages&token=${savedTokenAndCompany}`, ids).then(response => response.data);
    }

}

export class Logs {

    // отримати список логів
    getLogs(obj){
        return axios.get(`?section=logs&action=getLogs&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }
    
}

export class Notices {

    //отримати сповіщення
    getNotices(obj) {
        return axios.get(`?section=notices&action=getNotices&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    readNotices(ids) {
        return axios.post(`?section=notices&action=readNotices&token=${savedTokenAndCompany}`, ids).then(response => response.data);
    }
    //прочитати всі сповіщення
}

export class Scripts {
    
    //отримати список скриптів 
    getScripts(obj) {
        return axios.get(`?section=saleScripts&action=getScripts&token=${savedTokenAndCompany}`, {params: obj}).then(response => response.data);
    }

    //додати скрипт
    addScript(obj) {
        return axios.post(`?section=saleScripts&action=addScript&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //редагувати скрипт 
    editScript(id, obj) {
        return axios.post(`?section=saleScripts&action=editScript&token=${savedTokenAndCompany}&scriptId=${id}`, obj).then(response => response.data);
    }
    
    //видалити скрипт 
    deleteScript(id) {
        return axios.post(`?section=saleScripts&action=deleteScript&token=${savedTokenAndCompany}&scriptId=${id}`).then(response => response.data);
    }
    
}

export class ATS {
    // історія дзвінків
    historyCall(id) {
        return axios.get(`?section=calls&action=historyOfCalls&token=${savedTokenAndCompany}&workerId=${id}`).then(response => response.data);
    }

    // реєстрація в АТС
    registration() {
        return axios.post(`?section=ats&action=register&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // дереєстрація в АТС
    unregistration() {
        return axios.post(`?section=ats&action=unregister&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // отримати список юзерів онлайн
    getOnlineUsers() {
        return axios.get(`?section=ats&action=getOnlineWorkers&token=${savedTokenAndCompany}`).then(response => response.data);
    }
}

export class fileManager {

    //створення папки
    createFolder(obj) {
        return axios.post(`?section=filemanager&action=createFolder&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    //видалення папки
    deleteFolder(id) {
        return axios.post(`?section=filemanager&action=deleteFolder&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }

    //отримання списка папок
    getFolders(obj) {
        return axios.get(`?section=filemanager&action=getFolders&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    //отримання списку останніх завантажень (30 останніх)
    lastDownloads() {
        return axios.get(`?section=filemanager&action=lastDownloads&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // відкриття папки (отримання файлів у папці)
    openFolder(id, obj) {
        return axios.get(`?section=filemanager&action=openFolder&token=${savedTokenAndCompany}&fileManagerId=${id}`, { params: obj }).then(response => response.data);
    }

    // перейменувати папку (filemanager)
    renameFolder(id, obj) {
        return axios.post(`?section=filemanager&action=renameFolder&token=${savedTokenAndCompany}&fileManagerId=${id}`, obj).then(response => response.data);
    }

    // переміщення папки/файлу (filemanager)
    move(id, obj) {
        return axios.post(`?section=filemanager&action=move&token=${savedTokenAndCompany}&fileManagerId=${id}`, obj).then(response => response.data);
    }

    // додати файл/папку до обраного (filemanager)
    addToFavorites(id) {
        return axios.post(`?section=filemanager&action=addToFavorites&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }

    // прибрати файл/папку з обраного (filemanager)
    removeFromFavorites(id) {
        return axios.post(`?section=filemanager&action=removeFromFavorites&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }

    // поділитися файлом/папкою (filemanager)
    // 1 - можна лише подивитися
    // 2 - можна подивитися та редагувати
    share(id, obj) {
        return axios.post(`?section=filemanager&action=share&token=${savedTokenAndCompany}&fileManagerId=${id}`, obj).then(response => response.data);
    }

    //завантаження файлів у папку (filemanager)
    uploadFiles(id, formData) {
        return axios.post(`?section=filemanager&action=uploadFiles&token=${savedTokenAndCompany}&fileManagerId=${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }).then(response => response.data);
    }

    //перегляд файлів в файловому менеджері
    getFile(id) {
        return axios.get(`?section=filemanager&action=getFile&token=${savedTokenAndCompany}&fileManagerId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

    // видалення файлу (filemanager)
    deleteFile(id) {
        return axios.get(`?section=filemanager&action=deleteFile&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }

    //повне видалення файлу з кошику
    fullDeleteFile(id) {
        return axios.get(`?section=filemanager&action=fullDeleteFile&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }
    
    //повне видалення папки з кошику
    fullDeleteFolder(id) {
        return axios.get(`?section=filemanager&action=fullDeleteFolder&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }

    //отримати видалені файли (кошик)
    getDeleted() {
        return axios.get(`?section=filemanager&action=getDeleted&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    //відновлення файлу з кошика (filemanager)
    restoreFile(id) {
        return axios.post(`?section=filemanager&action=restoreFile&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }

    //відновлення папки з кошика (filemanager)
    restoreFolder(id) {
        return axios.post(`?section=filemanager&action=restoreFolder&token=${savedTokenAndCompany}&fileManagerId=${id}`).then(response => response.data);
    }

    //отримання списку, з якими поділилися зі мною (filemanager)
    getSharedWith() {
        return axios.post(`?section=filemanager&action=getSharedWith&token=${savedTokenAndCompany}`).then(response => response.data);
    }

}

export class Crisp {

    getActiveConversations(obj) {
        return axios.get(`?section=crisp&action=getActiveConversations&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

}

export class ColdCalls {

    // отримання списку холодних дзвінків
    getCalls(obj) {
        return axios.get(`?section=coldCalls&action=getCalls&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // прийом у роботу холодного дзвінка
    addCallInJob(requestId) {
        return axios.get(`?section=coldCalls&action=addCallInJob&token=${savedTokenAndCompany}&requestId=${requestId}`).then(response => response.data);
    }

    // імпорт даних потенційних клієнтів
    importCalls(callFolderId, obj) {
        return axios.get(`?section=coldCalls&action=importCalls&token=${savedTokenAndCompany}&apiKey=n7peCfQICN&callFolderId=${callFolderId}`, { params: obj }).then(response => response.data);
    }

    // додавання репорту зі скасування холодного дзвінка
    // {
    //     "comment": "lol"
    // }
    addReportOnCancelCall(requestId, obj) {
        return axios.post(`?section=coldCalls&action=addReportOnCancelCall&token=${savedTokenAndCompany}&requestId=${requestId}`, obj).then(response => response.data);
    }

    // зателефонувати зараз за холодним дзвінком
    callNowByCall(requestId) {
        return axios.get(`?section=coldCalls&action=callNowByCall&token=${savedTokenAndCompany}&requestId=${requestId}`).then(response => response.data);
    }
    
    // скасування холодного дзвінка
    // {
    //     "comment": "lol"
    // }
    cancelCall(requestId, obj) {
        return axios.post(`?section=coldCalls&action=cancelCall&token=${savedTokenAndCompany}&requestId=${requestId}`, obj).then(response => response.data);
    }

    // закриття холодного дзвінка
    // {
    //     "comment": "lol"
    // }
    closeCall(requestId, obj) {
        return axios.post(`?section=coldCalls&action=closeCall&token=${savedTokenAndCompany}&requestId=${requestId}`, obj).then(response => response.data);
    }

    // відкласти холодний дзвінок (перенесення на інший час)
    // {
    //     "delayedTime": "12:00",
    //     "delayedDate": "2023-12-22"
    // }
    delayCall(requestId, obj) {
        return axios.post(`?section=coldCalls&action=delayCall&token=${savedTokenAndCompany}&requestId=${requestId}`, obj).then(response => response.data);
    }

    // видалення холодного дзвінка
    deleteCall(requestId) {
        return axios.get(`?section=coldCalls&action=deleteCall&token=${savedTokenAndCompany}&requestId=${requestId}`).then(response => response.data);
    }

    // редагування даних про холодний дзвінок
    // {
    //     "userPhone": 380885859495,
    //     "priority": 1,
    //     "comment": "lol"
    // }
    editCall(requestId, obj) {
        return axios.post(`?section=coldCalls&action=editCall&token=${savedTokenAndCompany}&requestId=${requestId}`, obj).then(response => response.data);
    }

    // фіксація холодного дзвінка під час передачі іншому співробітнику
    fixationTransferCall(requestId) {
        return axios.get(`?section=coldCalls&action=fixationTransferCall&token=${savedTokenAndCompany}&requestId=${requestId}`).then(response => response.data);
    }

    // отримання даних про холодний дзвінок
    getCall(requestId) {
        return axios.get(`?section=coldCalls&action=getCall&token=${savedTokenAndCompany}&requestId=${requestId}`).then(response => response.data);
    }

    // відмова від холодного дзвінка
    rejectTransferCall(requestId) {
        return axios.get(`?section=coldCalls&action=rejectTransferCall&token=${savedTokenAndCompany}&requestId=${requestId}`).then(response => response.data);
    }

    // передача холодного дзвінка
    transferCall(requestId, workerId, obj) {
        return axios.get(`?section=coldCalls&action=transferCall&token=${savedTokenAndCompany}&requestId=${requestId}&workerId=${workerId}`, obj).then(response => response.data);
    }

    //  статистика використаних дзвінків
    statistic(){
        return axios.get(`?action=statistics&section=coldCalls&token=${savedTokenAndCompany}&apiKey=n7peCfQICN`).then(response => response.data);
    }

    // подзвонити зараз (пачкою)
    packCallNow(obj) {
        return axios.post(`?section=coldCalls&action=callNowByCalls&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

}

export class coldCallFolders {

    // створення папки
    createFolder(obj) {
        return axios.post(`?section=coldCallFolders&action=createFolder&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // створення папок
    createFolders(obj) {
        return axios.post(`?section=coldCallFolders&action=createFolders&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // видалення папки
    deleteFolder(id) {
        return axios.get(`?section=coldCallFolders&action=deleteFolder&token=${savedTokenAndCompany}&callFolderId=${id}`).then(response => response.data);
    }

    // повне видалення папки
    fullDeleteFolder(id) {
        return axios.get(`?section=coldCallFolders&action=fullDeleteFolder&token=${savedTokenAndCompany}&callFolderId=${id}`).then(response => response.data);
    }

    // отримання виддалених (кошик)
    getDeleted() {
        return axios.get(`?section=coldCallFolders&action=getDeleted&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // отримання списку папок
    getFolders(obj) {
        return axios.get(`?section=coldCallFolders&action=getFolders&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // відкриття папки (отримання холодних дзвінків у папці)
    openFolder(id, obj) {
        return axios.get(`?section=coldCallFolders&action=openFolder&token=${savedTokenAndCompany}&callFolderId=${id}`, {params : obj}).then(response => response.data);
    }

    // перейменування папки
    rename(id, obj) {
        return axios.post(`?section=coldCallFolders&action=rename&token=${savedTokenAndCompany}&callFolderId=${id}`, obj).then(response => response.data);
    }

    // відновлення папки
    restoreFolder(id) {
        return axios.post(`?section=coldCallFolders&action=restoreFolder&token=${savedTokenAndCompany}&callFolderId=${id}`).then(response => response.data);
    }

}

export class MessagesInSky {
    // отримати повідомлення
    getMessages() {
        return axios.get(`?section=skyMessages&action=getMessages&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // додати шаблон повідомлення
    addMessage(obj) {
        return axios.post(`?section=skyMessages&action=addMessage&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // отримати картку повідомлення 
    getMessage(id) {
        return axios.get(`?section=skyMessages&action=getMessage&token=${savedTokenAndCompany}&id=${id}`).then(response => response.data);
    }

    // підтвердження повідомлення
    confirmMessage(id) {
        return axios.post(`?section=skyMessages&action=confirmMessage&token=${savedTokenAndCompany}&id=${id}`).then(response => response.data);
    }

    // видалення повідомлення
    deleteMessage(id) {
        return axios.post(`?section=skyMessages&action=deleteMessage&token=${savedTokenAndCompany}&id=${id}`).then(response => response.data);
    }

    // редагувати повідомлення
    editMessage(id, obj) {
        return axios.post(`?section=skyMessages&action=editMessage&token=${savedTokenAndCompany}&id=${id}`, obj).then(response => response.data);
    }

    // додати інформацію
    addInfo(id, obj) {
        return axios.post(`?section=skyLongMessages&action=addMessage&token=${savedTokenAndCompany}&id=${id}`, obj).then(response => response.data);
    }

    // видалити інформацію
    deleteInfo(id) {
        return axios.post(`?section=skyLongMessages&action=deleteMessage&token=${savedTokenAndCompany}&id=${id}`).then(response => response.data);
    }

    // редагувати інформацію
    editInfo(id, obj) {
        return axios.post(`?section=skyLongMessages&action=editMessage&token=${savedTokenAndCompany}&id=${id}`, obj).then(response => response.data);
    }

    // завантажити зображення
    // https://api.inside.skyservice.pro/?section=skyMessages&action=uploadImageInMessage&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f&token=dQb9779ZQtHKRKKAA8s4tkZ24fYR9bZs
    // передавать поле img со значением в base64 (метод POST)
    uploadImageInMessage(img) {
        return axios.post(`?section=skyMessages&action=uploadImageInMessage&token=${savedTokenAndCompany}`, img).then(response => response.data);
    }

}

export class TriggerCalls {
    // отримання списку тріггер дзвінків
    getTriggerCalls() {
        return axios.get(`?section=triggerRequests&action=getRequests&token=${savedTokenAndCompany}`).then(response => response.data);
    }

    // отримання тріггер дзвінка
    getTriggerCall(id) {
        return axios.get(`?section=triggerRequests&action=getRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // додати репорт на відміну заявки
    addReportForCancel(id, obj) {
        return axios.get(`?section=triggerRequests&action=addReportOnCancelRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data);
    }

    // взяти в роботу заявку-тріггер
    addTriggerInJob(id) {
        return axios.get(`?section=triggerRequests&action=addRequestInJob&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // "подзвонити зараз" по заявці-тріггер
    callNowByRequest(id) {
        return axios.get(`?section=triggerRequests&action=callNowByRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // "подзвонити зараз" по заявкам-тріггер 
    callNowByRequests(obj) {
        return axios.get(`?section=triggerRequests&action=callNowByRequests&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // скасування заявки тріггера
    cancelRequest(obj, id) {
        return axios.get(`?section=triggerRequests&action=cancelRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data);
    }

    // закриття заявки тріггера
    closeRequest(obj, id) {
        return axios.get(`?section=triggerRequests&action=closeRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data);
    }

    // відкласти заявку трігер
    delayRequest(obj, id) {
        return axios.get(`?section=triggerRequests&action=delayRequest&token=${savedTokenAndCompany}&requestId${id}`, obj).then(response => response.data);
    }

    // видалити заявку тріггер
    deleteRequest(obj, id) {
        return axios.get(`?section=triggerRequests&action=deleteRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data);
    }

    // редагувати заявку тріггер
    editTriggerRequest(obj, id){
        return axios.get(`?section=triggerRequests&action=editRequest&token=${savedTokenAndCompany}&requestId=${id}`, obj).then(response => response.data);
    }

    // прийняти передачу заявки
    acceptTransfer(id) {
        return axios.get(`?section=triggerRequests&action=fixationTransferRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // відмовитися від передачі заявки
    rejectTransfer(id) {
        return axios.get(`?section=triggerRequests&action=rejectTransferRequest&token=${savedTokenAndCompany}&requestId=${id}`).then(response => response.data);
    }

    // видалння заявок тріггерів "пачкою"
    removeTrigger(obj) {
        return axios.get(`?section=triggerRequests&action=removeRequests&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // створити запит на передачу заявки тріггера
    addTransferRequest(obj, workerId, id) {
        return axios.get(`?section=triggerRequests&action=transferRequest&token=${savedTokenAndCompany}&requestId=${id}&workerId=${workerId}`, obj).then(response => response.data);
    }
}

export class AuthCabinet {

    // отримання власних компаній
    // https://api.auth.skyservice.online/?section=company&action=getCompanies&token=N68iQ646KaDB4n3e7Kab95NkniQzinST&timezone=-3
    getCompanies() {
        return axios.get(`https://api.auth.skyservice.online/?section=company&action=getCompanies&token=${token}&timezone=-3`).then(response => response.data);
    }

}

export class Monitoring {

    // назначити співробітників за моніторингом
    assignWorkers(id, obj) {
        return axios.post(`?section=monitoring&action=saveAssignedWorkers&token=${savedTokenAndCompany}&monitoringId=${id}`, obj).then(result => result.data)
    }

    // зупинити роботу над моніторингом
    stopWork(id, obj) {
        return axios.post(`?section=monitoring&action=stopMonitoring&token=${savedTokenAndCompany}&monitoringId=${id}`, obj).then(result => result.data)
    }

    // додавання до списку моніторінгу
    addToMonitoring(obj) {
        return axios.post(`?section=monitoring&action=addToMonitoring&token=${savedTokenAndCompany}`, obj).then(result => result.data);
    }

    // редагування моніторингу
    editMonitoring(obj, id) {
        return axios.post(`?section=monitoring&action=editMonitoringData&token=${savedTokenAndCompany}&monitoringId=${id}`, obj).then(result => result.data);
    }

    // отримання картки моніторингу
    getMonitoring(id) {
        return axios.get(`?section=monitoring&action=getMonitoringData&token=${savedTokenAndCompany}&monitoringId=${id}`).then(result => result.data);
    }

    // отримання даних з моніторингу
    getMonitorings(obj) {
        return axios.get(`?section=monitoring&action=getMonitoringList&token=${savedTokenAndCompany}`, {params : obj}).then(result => result.data);
    }

    // коментар до моніторингу
    addComment(id, obj) {
        return axios.post(`?section=monitoringComment&action=addComment&token=${savedTokenAndCompany}&monitoringId=${id}`, obj).then(result => result.data);
    }

    // Перевірити моніторинг
    checkMonitoring(id) {
        return axios.get(`?section=monitoring&action=checkMonitoring&token=${savedTokenAndCompany}&monitoringId=${id}`).then(result => result.data);
    }
}

export class HashTags {
    // зберегти хештеги по тікету
    saveHashtags(ticketId, obj) {
        return axios.post(`?section=ticketHashtags&action=saveHashtags&token=${savedTokenAndCompany}&ticketId=${ticketId}`, obj).then(result => result.data);
    }

    // видалити хештег
    deleteHashtag(hashtagId) {
        return axios.get(`?section=hashtags&action=deleteHashtag&token=${savedTokenAndCompany}&hashtagId=${hashtagId}`).then(result => result.data);
    }

    // отримати список хештегів
    getHashtags() {
        return axios.get(`?section=hashtags&action=getHashtags&token=${savedTokenAndCompany}`).then(result => result.data);
    }
}


export class Testing {

    // отримання списку карток тестінгу
    getTestingList(obj) {
        return axios.get(`?section=testing&action=getTestingList&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }
    
    // створення картки тестінгу
    createTesting(obj) {
        return axios.post(`?section=testing&action=createTesting&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // видалення картки тестінгу
    deleteTesting(id) {
        return axios.get(`?section=testing&action=deleteTesting&token=${savedTokenAndCompany}&testingId=${id}`).then(response => response.data);
    }

    // отримання картки тестування
    getTesting(id) {
        return axios.get(`?section=testing&action=getTesting&token=${savedTokenAndCompany}&testingId=${id}`).then(response => response.data);
    }

    // редагування картки тестування
    editTesting(id, obj) {
        return axios.post(`?section=testing&action=editTesting&token=${savedTokenAndCompany}&testingId=${id}`, obj).then(response => response.data);
    }

    // взяти в роботу тестування
    inJobTesting(id) {
        return axios.get(`?section=testing&action=inJobTesting&token=${savedTokenAndCompany}&testingId=${id}`).then(response => response.data);
    }

    // зміна результату пункту тестування
    changeResult(id) {
        return axios.get(`?section=testingClauses&action=changeResult&token=${savedTokenAndCompany}&clauseId=${id}`).then(response => response.data);
    }

    // завантаження 
    // https://api.inside.skyservice.pro/?section=testingFiles&action=uploadFiles&token=rDhfbYFG4Fd3kdBd6RHF8E446k44dbAQ&companyId=792fe762-b138-4c3e-9b5f-a4a310fc4a0f
    uploadFiles(testingUuid, data, type) {
        return axios.post(`?section=testingFiles&action=uploadFiles&token=${savedTokenAndCompany}&testingUuid=${testingUuid}`, data, {
            headers: {
                'Content-Type': type,
            },
        }).then(response => response.data);
    }

    // видалення файлу з тестінгу
    deleteFile(id) {
        return axios.post(`?section=testingFiles&action=deleteFile&token=${savedTokenAndCompany}&fileId=${id}`).then(response => response.data);
    }

    // перегляд файлу
    getFile(id) {
        return axios.get(`?section=testingFiles&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

    // отримання файлів в картці тестування
    getFiles(testingUuid) {
        return axios.get(`?section=testingFiles&action=getFiles&token=${savedTokenAndCompany}&testingUuid=${testingUuid}`).then(response => response.data);
    }

}

export class testingReports {

    // отримання списку баг-репортів
    getReports() {
        return axios.get(`?section=testingReports&action=getReports&token=${savedTokenAndCompany}`).then(response => response.data);
    }
    
    // створення картки баг-репорту
    createReport(obj) {
        return axios.post(`?section=testingReports&action=createReport&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // видалення картки баг-репорту
    deleteReport(id) {
        return axios.get(`?section=testingReports&action=deleteReport&token=${savedTokenAndCompany}&reportId=${id}`).then(response => response.data);
    }

    // отримання картки баг-репорту
    getReport(id) {
        return axios.get(`?section=testingReports&action=getReport&token=${savedTokenAndCompany}&reportId=${id}`).then(response => response.data);
    }

    // редагування картки тестування
    editReport(id, obj) {
        return axios.post(`?section=testingReports&action=editReport&token=${savedTokenAndCompany}&reportId=${id}`, obj).then(response => response.data);
    }


    // завантаження 
    uploadFiles(data, type) {
        return axios.post(`?section=testingReportFiles&action=uploadFiles&token=${savedTokenAndCompany}`, data, {
            headers: {
                'Content-Type': type,
            },
        }).then(response => response.data);
    }

    // видалення файлу з тестінгу
    deleteFile(id) {
        return axios.post(`?section=testingReportFiles&action=deleteFile&token=${savedTokenAndCompany}&fileId=${id}`).then(response => response.data);
    }

    // перегляд файлу
    getFile(id) {
        return axios.get(`?section=testingReportFiles&action=getFile&token=${savedTokenAndCompany}&fileId=${id}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            onDownloadProgress: function( progressEvent ) {
                storeS.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            }.bind(this)
        }
        ).then(response => response);
    }

    // отримання файлів в картці тестування
    getFiles(testingUuid) {
        return axios.get(`?section=testingReportFiles&action=getFiles&token=${savedTokenAndCompany}&testingUuid=${testingUuid}`).then(response => response.data);
    }

}

export class developmentProjects {

    // отримання списку проектів
    getProjects(obj) {
        return axios.get(`?section=developmentProjects&action=getProjects&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }
    
    // створення проекту
    addProject(obj) {
        return axios.post(`?section=developmentProjects&action=addProject&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // видалення проекту
    deleteProject(id) {
        return axios.get(`?section=developmentProjects&action=deleteProject&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    // редагування картки проекту
    editProject(id, obj) {
        return axios.post(`?section=developmentProjects&action=editProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

    // отримання картки проекту
    getProject(id) {
        return axios.get(`?section=developmentProjects&action=getProject&token=${savedTokenAndCompany}&projectId=${id}`).then(response => response.data);
    }

    // додавання/видалення проєкту до/з обраних
    saveFavoriteProject(id, obj) {
        return axios.post(`?section=developmentProjects&action=saveFavoriteProject&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

    // доступ до проєкту
    saveWorkers(id, obj) {
        return axios.post(`?section=devProjectWorkers&action=saveWorkers&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

    // Зведення проєкту
    reduction(projectId) {
        return axios.get(`?section=developmentProjects&action=reduction&token=${savedTokenAndCompany}&projectId=${projectId}`).then(response => response.data);
    }

    // Колонки до Kanban

    // отримання дошки за проектом
    getProjectBoard(projectId) {
        return axios.get(`?section=developmentProjects&action=getProjectBoard&token=${savedTokenAndCompany}&projectId=${projectId}`).then(response => response.data);
    }

    // додавання колонки
    addColumn(id, obj) {
        return axios.post(`?section=devProjectColumns&action=addColumn&token=${savedTokenAndCompany}&projectId=${id}`, obj).then(response => response.data);
    }

    // видалення колонки
    deleteColumn(id) {
        return axios.get(`?section=devProjectColumns&action=deleteColumn&token=${savedTokenAndCompany}&columnId=${id}`).then(response => response.data);
    }

    // редагування проєкту
    editColumn(id, obj) {
        return axios.post(`?section=devProjectColumns&action=editColumn&token=${savedTokenAndCompany}&columnId=${id}`, obj).then(response => response.data);
    }

    // сортування колонки у дошці
    sortColumn(columnId) {
        return axios.post(`?section=devProjectColumns&action=sortColumn&token=${savedTokenAndCompany}&columnId=${columnId}`, obj).then(response => response.data);
    }
    
    // сортування колонки у дошці
    sortColumns(projectId, obj) {
        return axios.post(`?section=devProjectColumns&action=sortColumns&token=${savedTokenAndCompany}&projectId=${projectId}`, obj).then(response => response.data);
    }

    // Завдання на дошці

    // додавання завдання на дошці
    addTask(columnId, obj) {
        return axios.post(`?section=devProjectTasks&action=addTask&token=${savedTokenAndCompany}&columnId=${columnId}`, obj).then(response => response.data);
    }

    // видалення завдання на дошці
    deleteTask(taskId) {
        return axios.get(`?section=devProjectTasks&action=deleteTask&token=${savedTokenAndCompany}&taskId=${taskId}`).then(response => response.data);
    }

    // редагування завдання на дошці
    editTask(taskId, obj) {
        return axios.post(`?section=devProjectTasks&action=editTask&token=${savedTokenAndCompany}&taskId=${taskId}`, obj).then(response => response.data);
    }

    // отримання даних про завдання у дошці
    getTask(taskId) {
        return axios.get(`?section=devProjectTasks&action=getTask&token=${savedTokenAndCompany}&taskId=${taskId}`).then(response => response.data);
    }

    // сортування завдань у дошці
    sortTasks(projectId, columnId, obj) {
        return axios.post(`?section=devProjectTasks&action=sortTasks&token=${savedTokenAndCompany}&projectId=${projectId}&columnId=${columnId}`, obj).then(response => response.data);
    }

    // переміщення завдання між колонкам
    moveTask(projectId, obj) {
        return axios.post(`?section=devProjectTasks&action=moveTask&token=${savedTokenAndCompany}&projectId=${projectId}`, obj).then(response => response.data);
    }

    // змінення дедлайну завдання
    changeDeadlineTime(taskId, obj) {
        return axios.post(`?section=devProjectTasks&action=changeDeadlineTime&token=${savedTokenAndCompany}&taskId=${taskId}`, obj).then(response => response.data);
    }

    // Список тасок

    // Отримання списку завдань
    getTasks(obj) {
        return axios.get(`?section=devProjectTasks&action=getTasks&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

    // Архівні таски
    getArchiveProjectTasks(obj) {
        return axios.get(`?section=archive&action=getArchiveProjectTasks&token=${savedTokenAndCompany}`, { params: obj }).then(response => response.data);
    }

}

export class Notion {
    // Додати документацію
    addDocumentation(obj) {
        return axios.post(`?section=devProjectDocumentations&action=addDocumentation&token=${savedTokenAndCompany}`, obj).then(response => response.data);
    }

    // Видалити документацію
    removeDocumentation(id) {
        return axios.get(`?section=devProjectDocumentations&action=deleteDocumentation&token=${savedTokenAndCompany}&documentationId=${id}`).then(response => response.data);
    }

    // Редагувати документацію
    editDocumentation(obj, id) {
        return axios.post(`?section=devProjectDocumentations&action=editDocumentation&token=${savedTokenAndCompany}&documentationId=${id}`, obj).then(response => response.data);
    }

    // Отримати документацію
    getDoc(id) {
        return axios.get(`?section=devProjectDocumentations&action=getDocumentation&token=${savedTokenAndCompany}&documentationId=${id}`).then(response => response.data);
    }

    // Отримати список документацій
    getDocs(obj) {
        return axios.get(`?section=devProjectDocumentations&action=getDocumentations&token=${savedTokenAndCompany}`, {params: obj}).then(response => response.data);
    }

    // Призначити документацію
    assignDoc(docId, projectId) {
        return axios.post(`?section=devProjectDocumentations&action=linkDocumentation&token=${savedTokenAndCompany}&documentationId=${docId}&projectId=${projectId}`).then(response => response.data);
    }
}