<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('ticket_type') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioTypes" id="all_types" v-model="type" :value="''">
                    <label class="form-check-label" for="all_types">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioTypes" :id="'type'+index" v-model="type" :value="value.value">
                        <label class="form-check-label" :for="'type'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            type: "",
            opt: [
                {
                    name: this.$t('problem_to_develop'),
                    value: "problem",
                },
                {
                    name: this.$t('wish_to_develop'),
                    value: "wishes",
                }
            ]
        }
    },
    methods: {
        change(){
            this.$emit('change', 'types', [this.type])
        },
    },
    computed: {
        // providers(){
        //     return storeS.providers
        // }
    }
}
</script>