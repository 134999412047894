<template>
    <!-- Фільтр по статусам архівованих проєктів -->
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('Status') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioStatus" id="all_status" v-model="status" :value="''">
                    <label class="form-check-label" for="all_status">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioStatus" :id="'status'+index" v-model="status" :value="value.value">
                        <label class="form-check-label" :for="'status'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            status: "",
            opt: [
                {
                    name: this.$t('statusCompleted'), // статус завершених
                    value: "completed",
                },
                {
                    name: this.$t('statusInWork'), // статус в роботі
                    value: "jobs",
                },
                {
                    name: this.$t('statusNew'), // нові
                    value: "new",
                },
                {
                    name: this.$t('statusrescheduled'), // відкладені
                    value: "rescheduled",
                },
                {
                    name: this.$t('closed'), // закриті
                    value: "closed",
                },
                {
                    name: this.$t('statuswait_delete'), // очікує видалення
                    value: "wait_delete",
                },
                {
                    name: this.$t('Removed'), // видалені
                    value: "deleted",
                },
            ]
        }
    },
    methods: {
        change(){
            this.$emit('change', 'status', this.status)
        },
    }
}
</script>