<template>
    <!-- Компонент для вибору проекту по оновленням -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="type-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('update') }}
        </label>
        <br />
        <div class="row g-2 list_filtr" >
             <!-- Вибір "Усі" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioupdate" id="all_update" v-model="selectupdate" :value="''">
                    <label class="form-check-label" for="all_update">{{ $t('all') }}</label>
                </div>
            </div>
            <!-- Цикл для виведення чекбоксів для кожного проекту -->
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioupdate" :id="'update'+index" v-model="selectupdate" :value="value.value">
                        <label class="form-check-label" :for="'update'+index">{{ value.label }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            selectupdate: "", // Обраний проект
            opt: []
        }
    },
    created() {
        this.changeSelectDevProjects()
    },
    methods: {
        changeSelectDevProjects(){
            // Робимо вигляд проєктів під мультіселект

            this.devProjects = [];
            for(var item in this.developmentProjects){
                var project = this.developmentProjects[item]
                this.opt.push({
                    "label": project.projectName,
                    "value": project.projectKey
                })
            }
            
        },
        change(){
            // Метод, що викликається при зміні фільтру

            this.$emit('change', 'update', this.selectupdate)
        },
    },
    computed: {
        developmentProjects() {
            // Отримання проектів
            return storeS.developmentProjects
        }
    }
}
</script>