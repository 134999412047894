<template>
    <!-- Карточка з класом "card" -->
    <div class="card">
        <!-- Тіло карточки з класом "card-body" та центруванням тексту -->
        <div class="card-body text-center">
          <!-- Заголовок карточки з класом "card-title" та лівим вирівнюванням тексту -->
          <h6 class="card-title mb-3 flex-grow-1 text-start">Time Tracking</h6>
          
          <!-- Виводимо список розробників з таймером, коли взято тікет в роботу -->
          <div
            v-for="(workers, index) in this.workers"    
            :key="index"
            style="display: flex;"
          >
            <div style="flex: 1; text-align: justify;">
              {{ workers.workerName }}
            </div>
            <div>
              {{ this.formatTime(workers.inJobDatetime) < 1 ? $t('inWorkRecently') : this.formatTime(workers.inJobDatetime) + " " + $t("days")}}
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  // Властивості, які передаються з батьківського компонента
  props: ['workers'],

  // Дані компонента
  data() {
    return {
      form: '',
      resulttime: 0,
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
    }
  },

  // Методи, які викликаються при створенні компонента
  created() {
    console.log(this.workers)
  },
  methods: {
    // Знаходимо різницю в часі 
    formatTime(startTime) {
      let data = new Date(startTime)
      let now = new Date()

      let delta = now - data
      
      const diffDays = delta / (1000 * 60 * 60 * 24);

      return Math.floor(diffDays)
    }
  }
}
</script>