<template v-slot>
    <!-- Модальне вікно створення завдання -->
    <modal @close="this.$emit('close')" :key="this.componentKey" @send="send()">
        <!-- Слот шапки модального вікна  -->
        <template v-slot:title>{{ $t('newTask') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <!-- Відкрити вікно з інформацією по завданням todo dev -->
            <!-- <button 
                class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
                @click="showInfoBox = true" 
            >
                <i class="bx bxs-info-circle align-bottom"></i>
            </button> -->

            <!-- Кнопка для закриття модального вікна, та збереження інформації, щоб продовжити її потім -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="savecomment"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>
                <b-row class="border-bottom mb-3">

                    <!-- 
                        Блок на вказування номера, по якому буде відбуватися завдання
                    -->
                    <b-col lg="6">
                        <div class="mb-3">
                            <!-- Заголовок -->
                            <h4 class="fs-15">{{ $t('enterPhoneNumber') }}</h4>
                            <div class="input-group mb-3" >
                                <!-- Введення номера телефону -->
                                <input type="text" v-model="form.userPhone" class="form-control">

                                <!-- 
                                    Кнопка для отримання компанії по номеру телефона
                                    - йде перевірка по назві завдання, назва не має бути "Змінити тип облікового запису/change_account_type"
                                -->
                                <button 
                                    class="btn btn-outline-success" 
                                    v-if="
                                        form.taskName?.value != 'change_account_type' && 
                                        form.taskName?.value != 'enable_old_version'
                                    " 
                                    id="contactNumber" 
                                    type="button" 
                                    :disabled="form.userPhone == ''" 
                                    @click="getCompany(form.userPhone)"
                                >
                                    {{$t('getsCompany')}}
                                </button>

                            </div>
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вибір завдання
                    -->
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('chooseTask') }}</h4>
                            <!-- Селектор для вибору одного завдання -->
                            <Multiselect 
                                v-model="this.form.taskName"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.tasktype"
                                :object="true"
                                :placeholder="$t('enterSearchWordTask')"
                                @change="this.offCompaniesWithNewAdminPanel($event)"
                            />
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування e-mail
                        - по ньому відбувається сповіщення клієнта
                    -->
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('enterMail') }}</h4>
                            <!-- Поле введення для електронної пошти -->
                            <input type="email" class="form-control" id="inputEmail4" placeholder="Email" v-model="this.form.userEmail">
                        </div>
                    </b-col>

                    <!-- 
                        Блок пріоритету завдання
                    -->
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                            <!-- Селектор для вибору одного пріоритету -->
                            <Multiselect 
                                v-model="this.form.priority"  
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.listPriority"
                                :placeholder="$t('priority')"
                            />
                        </div>
                    </b-col>

                </b-row>
               

                

                <!-- 
                    Блок на вказування основної(першої) компанії
                    - йде перевірка по назві завдання, назва не має бути "Змінити тип облікового запису/change_account_type", оскільки в акаунті не буде компаній
                -->
                <b-row class="border-bottom mb-3" v-if="form.taskName?.value != 'change_account_type' && form.taskName?.value != 'enable_old_version'">

                    <!-- 
                        Введення основної компанії 
                        - йде перевірка по назві завдання, назва не має бути "Копіювання даних (франшиза)/copy_data_franchise" та "Копіювати дані зі старої версії в нову (між аккаунтами)/trans_data_from_old_company_franchise"
                    -->
                    <b-col 
                    lg="12" 
                    v-if="
                        this.form.taskName?.value != 'copy_data_franchise' && 
                        form.taskName?.value != 'trans_data_from_old_company_franchise' && 
                        form.taskName?.value != 'transfer_funds_companies_between_accounts' && 
                        form.taskName?.value != 'trans_data_from_newServer_company_franchise' && 
                        form.taskName?.value != 'trans_data_from_newServer_company'
                    ">
                        <div class="mb-4" v-if="this.perm === false">
                            <h4 
                                class="fs-15" 
                                v-if="form.taskName?.value != 'transfer_funds_companies_in_account'"
                            >
                                {{ 
                                    (
                                        form.taskName?.value == 'copying_data_inside' || 
                                        form.taskName?.value == "trans_data_from_old_company" || 
                                        form.taskName?.value == "trans_data_from_newServer_company" 
                                    ) ? 
                                        $t('enterCompanyFirst') : 
                                        $t('enterCompany') 
                                }}
                            </h4>
                            <h4     
                                class="fs-15" 
                                v-if="form.taskName?.value == 'transfer_funds_companies_in_account'"
                            >{{ $t('selectCompanyFromFundsTransferredPeriod') }}</h4>
                            <Multiselect
                                v-model="mainCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :object="true"
                                :create-option="false"
                                :options="this.company"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>
                        <div class="mb-4" v-else>
                            <h4 
                                class="fs-15" 
                                v-if="form.taskName?.value != 'transfer_funds_companies_between_accounts'"
                            > 
                                {{ 
                                    (
                                        form.taskName?.value == 'copying_data_inside' || 
                                        form.taskName?.value == "trans_data_from_old_company" ||  
                                        form.taskName?.value == "trans_data_from_newServer_company"
                                    ) ? 
                                        $t('enterCompanyFirst') : $t('enterCompany') 
                                }}
                            </h4>
                            <h4 
                                class="fs-15" 
                                v-if="form.taskName?.value == 'transfer_funds_companies_between_accounts'"
                            >{{ $t('selectCompanyFromFundsTransferredPeriod') }}</h4>
                            <Multiselect
                                v-model="mainCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :object="true"
                                :options="this.company"
                                :default="this.form.companyId"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>
                    </b-col>

                    <!-- 
                        Введення основної компанії 
                        - йде перевірка по назві завдання, назва не має бути "Копіювання даних між новими версіями (в межах облікового запису)/trans_data_from_newServer_company"
                    -->
                    <b-col lg="12" v-if="this.form.taskName?.value == 'trans_data_from_newServer_company'">
                        <div class="mb-4" v-if="this.perm === false">
                            <h4 class="fs-15">{{ $t('enterCompanyFirst') }}</h4>
                            <Multiselect
                                v-model="mainCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :object="true"
                                :create-option="false"
                                :options="this.newServerCompany"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>
                        <div class="mb-4" v-else>
                            <h4 class="fs-15" >{{ $t('enterCompanyFirst') }}</h4>
                            <Multiselect
                                v-model="mainCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :object="true"
                                :options="this.newServerCompany"
                                :default="this.form.companyId"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>
                    </b-col>

                    <!-- 
                        Блок на "Копіювати дані зі старої версії" при створенні компанії з новою версією
                        - йде перевірка по назві завдання, назва має бути "Увімкнути нову версію/enable_new_version"
                        - в селекторі надається для вибору дві опції, 1 - true (дозволяє вказати точку для копіювання), 2 - false (створює лише компанію без копіювання)
                    -->
                    <b-col lg="6" v-if="form.taskName?.value == 'enable_new_version'">
                        <div class="mb-4">
                            <h4 class="fs-15">{{ $t('CopyDataFromOldVersion') }}</h4>
                            <b-form-select v-model="checkCopyNewVer" :options="optionsNewVers" @change="checkTT($event)"></b-form-select>
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування другої компанії, якщо відбувається копіювання між компаніями в межаї облікового запису
                        - йде перевірка по назві завдання, назва має бути "Копіювання даних (всередині облікового запису між компаніями)/copying_data_inside" або "Копіювати дані зі старої версії до нової (в межах облікового запису)/trans_data_from_old_company"
                        - в селекторі надається для вибору компанії, на яку буде відбуватись копіювання
                    -->
                    <b-col 
                        lg="12" 
                        v-if="  
                            form.taskName?.value == 'copying_data_inside' || 
                            form.taskName?.value == 'trans_data_from_old_company' || 
                            form.taskName?.value == 'transfer_funds_companies_in_account'
                        "
                    >
                        <div class="mb-4" >
                            <h4 
                            class="fs-15" 
                            v-if="
                                (
                                    form.taskName?.value == 'copying_data_inside' || 
                                    form.taskName?.value == 'trans_data_from_old_company'
                                )
                            ">{{ $t('enterCompanySecond') }}</h4> <!-- Заголовок "Виберіть компанію, на яку відбуватиметься копіювання" -->
                            
                            <h4 
                                class="fs-15" 
                                v-if="form.taskName?.value == 'transfer_funds_companies_in_account'"
                            >{{ $t('selectCompanyToFundsTransferredPeriod') }}</h4> <!-- Виберіть компанію, на яку буде відбуватись перенесення коштів або періоду" -->
                            <Multiselect
                                v-model="otherCompany"
                                mode="tags"
                                :close-on-select="true"
                                :searchable="true"
                                :object="true"
                                :create-option="false"
                                :options="this.company"
                                :placeholder="$t('enterCompany')"
                            />    
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування другої компанії, якщо відбувається копіювання між компаніями в межаї облікового запису (між новими версіями)
                        - йде перевірка по назві завдання, назва має бути "Копіювання даних між новими версіями (в межах облікового запису)/trans_data_from_newServer_company"
                        - в селекторі надається для вибору компанії, на яку буде відбуватись копіювання (нові версії)
                    -->
                    <b-col lg="12" v-if="form.taskName?.value == 'trans_data_from_newServer_company'">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('enterCompanySecond') }}</h4> <!-- Заголовок "Виберіть компанію, на яку відбуватиметься копіювання" -->
                            <Multiselect
                                v-model="otherCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :object="true"
                                :create-option="false"
                                :options="this.newServerCompany"
                                :placeholder="$t('enterCompany')"
                            />    
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування першої компанії, якщо відбувається копіювання між компаніями (між акаунтами)
                        - йде перевірка по назві завдання, назва має бути "Копіювання даних (франшиза)/copy_data_franchise" або "Копіювати дані зі старої версії в нову (між аккаунтами)/trans_data_from_old_company_franchise"
                        - вказується перший номер акаунту, з якого буде робитись копіювання
                    -->
                    <b-col 
                        lg="6" 
                        v-if="
                            this.form.taskName?.value == 'copy_data_franchise' || 
                            form.taskName?.value == 'trans_data_from_old_company_franchise' || 
                            form.taskName?.value == 'transfer_funds_companies_between_accounts' || 
                            form.taskName?.value == 'trans_data_from_newServer_company_franchise' ||
                            form.taskName?.value == 'strenght_switch_owner_of_comp' ||
                            form.taskName?.value == 'strenght_switch_owner_of_acc'
                        ">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('phone_number') }} (1)</h4>
                            <input type="text" class="form-control" id="contactNumber" placeholder="+380" v-model="this.form.userPhone" disabled>
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування першої компанії, якщо відбувається копіювання між компаніями (між акаунтами)
                        - йде перевірка по назві завдання, назва має бути "Копіювання даних (франшиза)/copy_data_franchise" або "Копіювати дані зі старої версії в нову (між аккаунтами)/trans_data_from_old_company_franchise"
                        - в селекторі надається для вибору компанію, з якої буде робитись копіювання
                    -->
                    <b-col lg="12">
                        <div 
                        class="mb-4" 
                        v-if="
                            this.form.taskName?.value == 'copy_data_franchise' || 
                            form.taskName?.value == 'trans_data_from_old_company_franchise' || 
                            form.taskName?.value == 'transfer_funds_companies_between_accounts'
                        ">
                            <h4 class="fs-15">{{ $t('enterCompany') }}</h4>
                            <Multiselect
                                v-model="mainCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :object="true"
                                :options="this.company"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>

                        <!-- 
                            Блок на вказування першої компанії, якщо відбувається копінювання між новими версіями
                            - йде перевірка по назві завдання, назва має бути "Копіювання даних між новими версіями (між аккаунтами)/trans_data_from_newServer_company_franchise"
                            - в селекторі надається вибір компанії (тільки нові версії), з якої буде відбуватись копіювання
                        -->
                        <div class="mb-4" v-if="form.taskName?.value == 'trans_data_from_newServer_company_franchise'">
                            <h4 class="fs-15">{{ $t('enterCompany') }}</h4>
                            <Multiselect
                                v-model="mainCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :object="true"
                                :options="this.newServerCompany"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>

                    </b-col>

                </b-row>

                <!-- 
                    Блок на вказування другої компанії, якщо відбувається копіювання між компаніями (між акаунтами)
                    - йде перевірка по назві завдання, назва має бути "Копіювання даних (франшиза)/copy_data_franchise" або "Копіювати дані зі старої версії в нову (між аккаунтами)/trans_data_from_old_company_franchise"
                -->
                <b-row 
                v-if="
                    this.form.taskName?.value == 'copy_data_franchise' || 
                    form.taskName?.value == 'trans_data_from_old_company_franchise' || 
                    form.taskName?.value == 'transfer_funds_companies_between_accounts' || 
                    form.taskName?.value == 'trans_data_from_newServer_company_franchise' ||
                    form.taskName?.value == 'strenght_switch_owner_of_comp' ||
                    form.taskName?.value == 'strenght_switch_owner_of_acc'
                ">

                    <!-- 
                        Блок на вказування другого номеру акаунту
                        - та по методу "getCopyCompany" отримуються компанії по другому номеру, яку потім можна вибрати нижче
                    -->
                    <b-col lg="6">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('secondNumber') }} (2)</h4>
                            <div class="input-group mb-3">
                                <input type="text" v-model="form.copyPhone" class="form-control">
                                <button class="btn btn-outline-success" type="button" :disabled="form.copyPhone == ''" @click="getCopyCompany(form.copyPhone)">{{$t('getsCompany')}}</button>
                            </div>
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування другої компанії (якщо не вибрано перенос зі старої на нову)
                        - в селекторі надається для вибору компанію, на яку буде відбуватись копіювання
                    -->
                    <b-col 
                    lg="12" 
                    v-if="
                        this.form.taskName?.value != 'trans_data_from_newServer_company_franchise'
                    ">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('enterCompany') }}</h4>
                            <Multiselect
                                v-model="otherCompany"
                                mode="tags"
                                :close-on-select="true"
                                :searchable="true"
                                :object="true"
                                :create-option="false"
                                :options="this.copyCompany"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування другої компанії, при копіюванні між новими версіями
                        - в селекторі надається для вибору компанію, на яку буде відбуватись копіювання (тільки нові версії)
                    -->
                    <b-col 
                        lg="12" 
                        v-if="
                            this.form.taskName?.value == 'trans_data_from_newServer_company_franchise'
                        "
                    >
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('enterCompany') }}</h4>
                            <Multiselect
                                v-model="otherCompany"
                                :close-on-select="true"
                                :searchable="true"
                                :object="true"
                                :create-option="false"
                                :options="this.copyNewServerCompany"
                                :placeholder="$t('enterCompany')"
                            />
                        </div>
                    </b-col>

                    <!-- 
                        Блок на вказування додаткової інформації по 2-му акаунту
                        - вказується ім'я власника номеру
                    -->
                    <b-col lg="6">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('enterName') }}</h4>
                            <div class="input-group mb-3">
                                <input type="text" v-model="form.copyUserName" class="form-control">
                            </div>
                        </div>
                    </b-col>
                    
                    <!-- 
                        Блок на вказування додаткової інформації по 2-му акаунту
                        - вказується e-mail власника номеру
                    -->
                    <b-col lg="6">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('enterMail') }}</h4>
                            <div class="input-group mb-3">
                                <input type="text" v-model="form.copyUserEmail" class="form-control">
                            </div>
                        </div>
                    </b-col>

                </b-row>

                <!-- 
                    Блок на вказування інформацію, по якій точці відбувається копіювання
                    - йде перевірка по назві завдання
                    - - назва має бути "Увімкнути нову версію/enable_new_version" та перевірка на згоду копіювання з старої версії (checkCopyNewVer)
                    - - назва має бути "Відновлення ТТ/repair_tt"
                    - - назва має бути "Копіювання даних (франшиза)/copy_data_franchise"
                    - - назва має бути "Копіювання даних (всередині облікового запису між компаніями)/copying_data_inside"
                    - - назва має бути "Копіювати дані зі старої версії до нової (в межах облікового запису)/trans_data_from_old_company"
                    - - назва має бути "Копіювати дані зі старої версії в нову (між аккаунтами)/trans_data_from_old_company_franchise"
                -->
                <b-row>
                    <b-col lg="12">
                        <div 
                            class="mb-4" 
                            v-if="
                                    (form.taskName?.value == 'enable_new_version' && checkCopyNewVer == true) 
                                    || form.taskName?.value == 'repair_tt' 
                                    || form.taskName?.value == 'copy_data_franchise' 
                                    || form.taskName?.value == 'copying_data_inside' 
                                    || form.taskName?.value == 'trans_data_from_old_company' 
                                    || form.taskName?.value == 'trans_data_from_old_company_franchise'
                                    || form.taskName?.value == 'trans_data_from_newServer_company'
                                    || form.taskName?.value == 'trans_data_from_newServer_company_franchise'
                            "
                            
                        >
                           

                            <!-- 
                                Селектор на вказування id точок (масив)
                                - йде перевірка по назві завдання
                                - - назва має бути "Відновлення ТТ/repair_tt"
                                - - назва має бути "Копіювання даних (франшиза)/copy_data_franchise"
                                - - назва має бути "Копіювання даних (всередині облікового запису між компаніями)/copying_data_inside"
                            -->
                            <template
                                v-if="
                                        this.form.taskName?.value == 'repair_tt' 
                                        || form.taskName?.value == 'copy_data_franchise' 
                                        || form.taskName?.value == 'copying_data_inside'
                                        || form.taskName?.value == 'trans_data_from_newServer_company'
                                        || form.taskName?.value == 'trans_data_from_newServer_company_franchise'
                                    "
                            >
                                <h4 class="fs-15">{{ $t('specifyIdTt') }}</h4>
                                <Multiselect
                                    id="inIdtt"
                                    v-model="selectedTT"
                                    mode="tags"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :object="true"
                                    :create-option="true"
                                    :options="tt"
                                />
                            </template>

                            <!-- 
                                Селектор на вибір точки (не масив)
                                - йде перевірка по назві завдання
                                - - назва має бути "Увімкнути нову версію/enable_new_version" та перевірка на згоду копіювання з старої версії (checkCopyNewVer)
                                - - назва має бути "Копіювати дані зі старої версії до нової (в межах облікового запису)/trans_data_from_old_company"
                                - - назва має бути "Копіювати дані зі старої версії в нову (між аккаунтами)/trans_data_from_old_company_franchise"
                            -->
                            <template
                                v-if="
                                        (form.taskName?.value == 'enable_new_version' && checkCopyNewVer == true) 
                                        || form.taskName?.value == 'trans_data_from_old_company' 
                                        || form.taskName?.value == 'trans_data_from_old_company_franchise'
                                        // || form.taskName == 'trans_data_from_newServer_company'
                                        // || form.taskName == 'trans_data_from_newServer_company_franchise'
                                    "
                            >
                                <h4 class="fs-15">{{ $t('EnterTradePoint') }}</h4>
                                <Multiselect
                                    v-model="selectedOneTT"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :object="true"
                                    :options="tt"
                                />
                            </template>

                            <!-- 
                                Кнопка для отримання торгових точок по обраній компанії
                            -->
                            <button class="btn btn-outline-success mt-2 mb-2" type="button" @click="getTT">{{ $t('getRequestTT') }}</button>

                            <!-- 
                                Блок нотатка нагадування, про обробку завдання
                            -->
                            <div class="alert alert-info" v-if="form.taskName?.value != 'enable_new_version'">
                                <p class="mb-0">
                                    <span class="fw-semibold">{{ $t('note') }} :</span>
                                    {{ $t('treatmentRecovery') }}
                                </p>
                            </div>

                        </div>
                    </b-col>
                </b-row>

                <b-row 
                    :class="`border-bottom ${form.taskName?.value == 'transfer_funds_companies_in_account' ? '' : 'border-top pt-3' } mb-3`"
                    v-if="form.taskName?.value == 'transfer_funds_companies_between_accounts' || form.taskName?.value == 'transfer_funds_companies_in_account'"
                >
                    <!-- 
                        Блок вибору типу переносу 
                        - переноситься тільки гроші
                        - переноситься тільки залишок періоду
                        - переноситься залишок періоду та гроші
                    -->
                    <b-col lg="6">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('SelectTransferType') }}</h4>
                            <b-form-select v-model="typeTransferLicen" :options="optionsTypeLicen"></b-form-select>
                        </div>
                    </b-col>

                    <!-- 
                        Блок для вказування суми
                        - йде перевірка на типу переносу, він має дорівнювати 'balance'
                    -->
                    <b-col lg="6" v-if="typeTransferLicen == 'balance'">
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('EnterTransferAmount') }}</h4>
                            <input type="number" class="form-control" :placeholder="$t('enteraNumber')" v-model="form.descType" />
                        </div>
                    </b-col>
                </b-row>

                    <!-- 
                        Блок нотатка нагадування, про обробку завдання "Перенесення коштів/transfer_funds_companies_in_account"
                    -->
                    <div class="mb-4" v-if="this.form.taskName?.value == 'transfer_funds_companies_in_account' && typeTransferLicen != ''">
                        <div class="alert alert-info">
                            <p class="mb-0">
                                <span class="fw-semibold">{{ $t('note') }} :</span>
                                <template v-if="typeTransferLicen == 'balance'">{{ $t('noteTransferBalance') }}</template>
                                <template v-if="typeTransferLicen == 'period'">{{ $t('noteTransferPeriod') }}</template>
                                <template v-if="typeTransferLicen == 'balance_period'">{{ $t('noteTransferBalancePeriod') }}</template>
                                <br>
                                <span class="fw-semibold">{{ $t('treatment') }} :</span>
                                {{ $t('treatmentDeleteAccount') }}
                            </p>
                        </div>
                    </div>

                    <!-- 
                        Блок нотатка нагадування, про обробку завдання "Очищення статистики/clear_stats"
                    -->
                    <div class="mb-4" v-if="this.form.taskName?.value == 'clear_stats'">
                        <div class="alert alert-info">
                            <p class="mb-0">
                                <span class="fw-semibold">{{ $t('note') }} :</span>
                                {{ $t('noteClearStat') }}<br>
                                <span class="fw-semibold">{{ $t('treatment') }} :</span>
                                {{ $t('treatmentDeleteAccount') }}
                            </p>
                        </div>
                    </div>

                    <!-- 
                        Блок нотатка нагадування, про обробку завдання "Увімкнення нової версії/enable_new_version"
                    -->
                    <div class="mb-4" v-if="this.form.taskName?.value == 'enable_new_version'">
                        <div class="alert alert-info">
                            <p class="mb-0">
                                <span class="fw-semibold">{{ $t('note') }} :</span>
                                {{ $t('enable_new_versionDesc') }}<br>
                            </p>
                        </div>
                    </div>

                    <!-- 
                        Блок нотатка нагадування, про обробку завдання "Скидання пароля/discharge_password"
                    -->
                    <div class="mb-4" v-if="this.form.taskName?.value == 'discharge_password'">
                        <div class="alert alert-info">
                            <p class="mb-0">
                                <span class="fw-semibold">{{ $t('note') }} :</span>
                                {{ $t('noteResetPass') }}
                            </p>
                        </div>
                    </div>

                    <!-- 
                        Блок нотатка нагадування, про обробку завдання "Зміну домену/add_change_domain"
                    -->
                    <div class="mb-4" v-if="this.form.taskName?.value == 'add_change_domain'">
                        <h4 class="fs-15">{{ $t('specifyDomain') }}</h4>
                        <input type="text" class="form-control" v-model="form.descType"><br>
                        <div class="alert alert-info">
                            <p class="mb-0">
                                <span class="fw-semibold">{{ $t('note') }} :</span>
                                {{ $t('noteDomain') }}
                            </p>
                        </div>
                    </div>

                    <!-- 
                        Блок нотатка нагадування, про обробку завдання "Змінення ліміту компаній/change_limit_companies"
                    -->
                    <div class="mb-4" v-if="this.form.taskName?.value == 'change_limit_companies'">
                        <h4 class="fs-15">{{ $t('SpecifyLimitCompanies') }}</h4>
                        <input type="number" class="form-control"  :placeholder="$t('enteraNumber')" v-model="form.descType" oninput="this.value = this.value.replace(/\./g, '')"  /><br>
                        <div class="alert alert-info">
                            <p class="mb-0">
                                <span class="fw-semibold">{{ $t('note') }} :</span>
                                {{ $t('noteChangeLimitComp') }} <br />
                                <span class="fw-semibold">{{ $t('treatment') }} :</span>
                                {{ $t('ProcessingTakesThreeDays') }}
                            </p>
                        </div>
                    </div>
                    
                    <!-- 
                        Блок нотатка нагадування, про обробку завдання "Увімкнення режиму синхронізації/syncmode" та "Увімкнення режиму мультитерміналу/multimode"
                    -->
                    <div class="mb-4" v-if="this.form.taskName?.value == 'syncmode' || form.taskName?.value == 'multimode'">
                        <div class="alert alert-info">
                            <p class="mb-0">
                                <span class="fw-semibold">{{ $t('note') }} :</span>
                                {{ $t('ProcessingTakesThreeDays') }}
                            </p>
                        </div>
                    </div>


                <!-- 
                    Блок вказування дати та часу коли виконати
                    - за замовуванням обрано "виконати якнайшвидше"
                -->
                <b-row class="border-bottom mb-3" v-if="true">
                    <b-col lg="6">
                        <!-- Коли виконати -->
                        <div class="mb-4" >
                            <h4 class="fs-15">{{ $t('WhenToPerform') }}</h4>
                            <div class="input-group mb-3">
                                <b-form-select v-model="form.whenPerform" :options="listTimePerf"></b-form-select>
                            </div>
                        </div>
                    </b-col>

                    <b-col lg="6"></b-col>
                    <template v-if="form.whenPerform == 2">

                        <!-- Дата -->
                        <b-col lg="6">
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('date') }}</h4>
                                <flatpickr 
                                    v-model="timeObjPerf.date"
                                    :config="configDate" 
                                    class="form-control dash-filter-picker shadow"
                                    @onChange="change"
                                ></flatpickr>
                            </div>
                        </b-col>

                        <!-- Час -->
                        <b-col lg="6">
                            <div class="mb-3">
                                <h4 class="fs-15">{{ $t('time') }}</h4>
                                <flatpickr 
                                    v-model="timeObjPerf.time"
                                    :config="configTime" 
                                    class="form-control dash-filter-picker shadow"
                                    @onChange="change"
                                ></flatpickr>
                            </div>
                        </b-col>
                    </template>
                </b-row>

                <!-- 
                    Блок з вказуванням повідомлення/інформації до завдання
                -->
                <div class="mb-3">
                    <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label><br />
                    <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="this.form.comment" :placeholder="$t('infoFutureCall')"></textarea>
                </div>

        </template>
        <template v-slot:footer-bottom>
            <button v-if="Boolean(this.perm) == false" type="button" class="btn btn-success" @click="checkComp()">{{ $t('Add') }}</button>
            <button v-else type="button" class="btn btn-success" @click="edit()">{{ $t('Edit') }}</button>
        </template>
    </modal>

    <!--  
        Модальне вікно з запитом на відкриття створенного завдання
        - :edit - включає в себе статус редагування
        - @close - закриває вікно запиту, та вікно картки створення
        - @open - відкриває картку створенного завдання
        - @openFromEdit - відкриває картку редагованого завдання
        - :obj - пропс даних, які вказані в картці
        - :type - пропс типу даних
    -->
    <dialogOpen 
        v-if="showDialogOpen == true" 
        :edit="this.perm"
        @close="this.showDialogOpen = false; this.$emit('close')" 
        @open="openTask" 
        @openFromEdit="openFromEdit" 
        :obj="this.form" 
        :type="this.type = 'task'"
    />

    <!-- Модальне вікно з інформацією про завдання todo dev -->
    <infoBox
        v-if="showInfoBox"
        @close="showInfoBox = false"
    />

</template>

<script>
import modal from '@/components/modal-small' // модальне вікно для створення завдання
import dialogBox from '@/components/modal/dialog' // можливо видалити потрібно
import dialogOpen from '@/components/globaltable/actions/dialogOpen.vue'; // модальне вікно з запитом на відкриття створенного завдання
import Multiselect from "@vueform/multiselect"; // компонент мультіселектора
import "@vueform/multiselect/themes/default.css"; // стилі до компоненту мультіселектора
import { Tasks, SuperAdmin } from '@/API.js'; // клас API, який викорстовується в файлі
import { storeS } from '@/store.js' // Глобальне сховище даних
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js'
import infoBox from './infoBox'
import dbPromise from '../../indexedDB';

let apiServe = new Tasks();
let apiSuperAdmin = new SuperAdmin();

export default ({
    props:['obj', 'perm', 'phone', 'copyItem'],
    data(){
        return {
            showInfoBox: false, // прапорець на відображення вікна з деталями про завдання
            dontSave: false,
            showDialogOpen: false,
            type: 'task',
            modalDialog: false,
            login: "",
            componentKey: '',
            mainCompany: {},
            otherCompany: [],
            accountId: '',
            selectedTT: [],
            selectedOneTT: '',
            form: {
                userPhone: "", //номер клиента
                userEmail: "", //почта клиента
                taskName: "", //тип задачи
                priority: "0", //приоритет задачи
                comment: '', //коммент создания задачи
                companyId: '', // id компании
                tradepoints: [], //точки для копирования
                copyPhone: '', //номер владельца второго акк
                copyOnCompany: [], //id второй компании
                copyUserName: '', //имя владельца второго акк
                copyUserEmail: '', //почта владельца второго акк
                templateId: '', //id sendPulse pattern
                whenPerform: 1, // коли виконати завдання
                executionDatetime: '', // дата та час, якщо завдання потрібно виконати пізніше
                translateTaskName: ''
            },
            comment: '',
            newServerCompany: [],
            copyNewServerCompany: [],
            company: [],
            copyCompany: [],
            tt: [{
                label:"",
                value:""
            }],
            tasktype: [
                {
                    "label": this.$t('EnablenewVersion'), // включити нову версію
                    "value": "enable_new_version"
                },
                {
                    "label": this.$t('EnableOldVersion'), // включити стару версію
                    "value": "enable_old_version"
                },
                {
                    "label": this.$t('trans_data_from_newServer_company_franchise'), // копіювання даних з нової версії в нову (між акаунтами)
                    "value": "trans_data_from_newServer_company_franchise"
                },
                {
                    "label": this.$t('trans_data_from_newServer_company'), // копіювання даних з нової версії в нову (в межах акаунту)
                    "value": "trans_data_from_newServer_company"
                },
                {
                    "label": this.$t('trans_data_from_old_company_franchise'), // перенесення даних зі старої версії в нову (між акаунтами)
                    "value": "trans_data_from_old_company_franchise"
                },
                {
                    "label": this.$t('trans_data_from_old_company'), // перенесення даних зі старої версії в нову (в межах акаунту)
                    "value": "trans_data_from_old_company"
                },
                {
                    "label": this.$t('transfer_funds_companies_in_account'), // перенести кошти між компаніями (в межах акаунту)
                    "value": "transfer_funds_companies_in_account"
                },
                {
                    "label": this.$t('transfer_funds_companies_between_accounts'), // перенести кошти між компаніями (між акаунтами)
                    "value": "transfer_funds_companies_between_accounts"
                },
                // {
                //     "label": this.$t('ReceivingTokenfromPrivatBank'), // Отримання токену Приватбанк
                //     "value": "receiving_token"
                // },
                {
                    "label": this.$t('ChangeAccountType'), // змінити тип акаунту
                    "value": "change_account_type"
                },
                {
                    "label": this.$t('tt_recovery'), // відновлення торгової точки
                    "value": "repair_tt"
                },
                {
                    "label": this.$t('RestorationExpenseCategory'), // відновлення категорій витрат
                    "value": "restoration_expense_category"
                },
                {
                    "label": this.$t('clearing_statistics'), // очищення статистики
                    "value": "clear_stats"
                },
                // {
                //     "label": this.$t('clearing_products'), // очищення товарів
                //     "value": "clearing_products"
                // },
                {
                    "label": this.$t('clearing_account'), // очищення акаунту
                    "value": "clearing_account"
                },
                // {
                //     "label": this.$t('administrator_change'),
                //     "value": "administrator_change"
                // },
                {
                    "label": this.$t('copying_data_franchise'), // копіювання даних (франшиза)
                    "value": "copy_data_franchise"
                },
                {
                    "label": this.$t('copying_data_inside'), // копіювання даних (в межах акаунту)
                    "value": "copying_data_inside"
                },
                {
                    "label": this.$t('password_reset'), // відновлення паролю
                    "value": "discharge_password"
                },
                {
                    "label": this.$t('SyncMode'), // режим синхронізації
                    "value": "syncmode"
                },
                {
                    "label": this.$t('MultiMode'), // режим мультитерміналу
                    "value": "multimode"
                },
                {
                    "label": this.$t('add_change_domain'), // Додати (змінити) домен
                    "value": "add_change_domain"
                },
                {
                    "label": this.$t('ChangeLimitCompanies'), // змінити ліміт компаній
                    "value": "change_limit_companies"
                },
                {
                    "label": this.$t('strenght_switch_owner_of_comp'), // примусова зміна власника компанії
                    "value": "strenght_switch_owner_of_comp"
                },
                // {
                //     "label": this.$t('strenght_switch_owner_of_acc'), // примусова зміна власника акаунту
                //     "value": "strenght_switch_owner_of_acc"
                // },
            ],
            listPriority: [
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            checkCopyNewVer: '',
            optionsNewVers: [
                { value: true, text: this.$t('y') },
                { value: false, text: this.$t('n') },
            ],
            typeTransferLicen: '',
            optionsTypeLicen: [
                { value: 'balance', text: this.$t('balance') },
                { value: 'period', text: this.$t('ActivatedPeriod') },
                { value: 'balance_period', text: this.$t('BalanceActivatedPeriod') },
            ],
            choosed: [],
            listTimePerf: [ // масив зі списком варіантів коли виконати
                { value: '1', text: this.$t('ExecuteSoonPossible') }, // виконати якнайшвидше 
                { value: '2', text: this.$t('ExecuteLater') }, // виконати пізніше 
            ],
            timeObjPerf: { // параметри дата та часу
                date: '',
                time: ''
            },
            configDate: { // параметри для flatpickr
                altInput: true,
                // altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: "",
                defaultDate: []
            },
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                // minTime: "00:00",
                // maxTime: "23:00"
            }
        }
    },
    components: { 
        modal, 
        Multiselect, 
        dialogBox,
        dialogOpen, 
        flatpickr: Vueflatpickr,
        infoBox
    },
    created(){

        // Встановлення локалізації для flatpickr
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }

        // Параметри для створення копії
        if(this.copyItem?.status == true){
            this.form.userPhone = this.copyItem.data.userPhone
            this.form.userEmail = this.copyItem.data.userEmail
            this.form.taskName.value = this.copyItem.data.taskName
            this.form.priority = this.copyItem.data.priority
            this.form.companyId = this.copyItem.data.companyId
            this.form.whenPerform = this.copyItem.data.whenPerform ? this.copyItem.data.whenPerform : 1
            this.form.executionDatetime = this.copyItem.data.executionDatetime ? this.copyItem.data.executionDatetime : ''
        }

        if(this.perm === true) {
            this.form = this.obj
            this.form.priority = this.obj.priority
            this.getCompany(this.form.userPhone)

            if(this.form.copyPhone) {
                this.getCopyCompany(this.form.copyPhone)    
            } 

            if(this.obj.taskName) {
                this.form.taskName = this.tasktype.find(el => {el.value === this.obj.taskName})
            }
            
            if(
                // Якщо задача - увімкнення нової версії та копіювання нових даних
                (this.form.taskName == 'enable_new_version' && this.checkCopyNewVer == true) || 
                // або задача - перенесення даних з старої компанії
                this.form.taskName == 'trans_data_from_old_company' || 
                // або задача - перенесення даних з старої компанії франчайзинга
                this.form.taskName == 'trans_data_from_old_company_franchise'
            ) {
                // Перебираємо торгові точки (tradepoints) та встановлюємо вибрану торгову точку (selectedOneTT)
                this.obj.tradepoints.forEach((el, index) => {
                    this.selectedOneTT = {value: el, label: `${this.$t('outlet')} ${index + 1}`}
                })
            } else {
                // Перебираємо торгові точки (tradepoints) та додаємо їх до масиву вибраних торгових точок (selectedTT)
                this.obj.tradepoints.forEach((el, index) => {
                    this.selectedTT.push({value: el, label: `${this.$t('outlet')} ${index + 1}`})
                })
            }
        }
        
        if (this.phone) {
            this.form.userPhone = this.phone
        }

        // не забуди розкоментувати
        //перевірка на наявність не закінченних заявок
        dbPromise.getAll('tasks')
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            if(status.status == true) {
                dbPromise.get(status.form.id, status.form.category) // ставимо галочку, що взяли форму, далі вона видалиться

                this.form = JSON.parse(status.form.form)
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
    },
    methods: {
        offCompaniesWithNewAdminPanel(event) {
            if(event.value === 'enable_new_version') {
                this.getCompany(this.form.userPhone)
            }
        },
        // Перевіряємо чи копіювати дані, якщо точка була вибрана, але потім ми передумали копіюватиЮ то це очистить дані
        checkTT(e) {
            if(e === false) {
                this.selectedTT = []
                this.selectedOneTT = ""
            }
        },
        // перевірка чи вказана правильна таска, тільки при одній компанії
        checkComp(e) {

            if(
                this.form.taskName.value == 'trans_data_from_old_company_franchise' ||
                this.form.taskName.value == 'trans_data_from_old_company' ||
                this.form.taskName.value == 'copy_data_franchise' ||
                this.form.taskName.value == 'copying_data_inside'
            ) {
                // прибираємо додатки в назвах компаній
                if(this.mainCompany.label.indexOf(this.$t('owner_of_company')) != -1) {
                    // Якщо є частинка, що він керівник компанії
                    this.mainCompany.label = this.mainCompany.label.replace(' (' + this.$t('owner_of_company') + ')', '')
                } else {
                    // Якщо є частинка, що він співробітник
                    this.mainCompany.label = this.mainCompany.label.replace(' (' + this.$t('Employee') + ')', '')
                    console.log(this.mainCompany.label)
                }
                // перевіряємо чи компанія НА котру робиться дія є масивом
                if(Array.isArray(this.otherCompany) == false){
                    if(this.otherCompany.label.indexOf(this.$t('owner_of_company')) != -1){
                        // Якщо є частинка, що він керівник компанії
                        this.otherCompany.label = this.otherCompany.label.replace(' (' + this.$t('owner_of_company') + ')', '')
                        console.log(this.otherCompany.label)
                    } else {
                        // Якщо є частинка, що він співробітник
                        this.otherCompany.label = this.otherCompany.label.replace(' (' + this.$t('Employee') + ')', '')
                        console.log(this.otherCompany.label)
                    }
                } else {
                    if(this.otherCompany[0]) {
                        if(this.otherCompany[0].label.indexOf(this.$t('owner_of_company')) != -1){
                            // Якщо є частинка, що він керівник компанії
                            this.otherCompany[0].label = this.otherCompany[0].label.replace(' (' + this.$t('owner_of_company') + ')', '')
                        } else {
                            // Якщо є частинка, що він співробітник
                            this.otherCompany[0].label = this.otherCompany[0].label.replace(' (' + this.$t('Employee') + ')', '')
                        }
                    }
                }
                // прибираємо додатки в назвах компаній 

                if(this.otherCompany[0] == null || !this.otherCompany[0] || this.otherCompany == null || !this.otherCompany) {
                    //перевіряємо чи вказана компанія на котру копіювання тощо
                    this.$toast.error(this.$t('Введіть компанію на котру буде відбуватися дія'));

                    var errorText = '‼️ Можливий баг з вказанням компанії на котру відбуваюється операція'; 
                    var statusRequest = "error";
                    var api = 'addTask';
                    var fileRequest = 'src/views/tasks/createtask.vue';
                    var params = this.form;
                    var response = "this.form.copyOnCompany[0] == (null || undefined)"

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 

                    return    
                }

                // перевірка на адмінки у вказаних компаніях
                if(
                    (
                        this.mainCompany.label.split(' - ')[this.mainCompany.label.split(' - ').length - 1].split(" ")[0] != 
                        this.otherCompany[0].label?.split(' - ')[this.otherCompany[0].label?.split(' - ').length - 1].split(" ")[0]
                    ) && 
                    this.otherCompany.length === 1
                ){
                    // якщо назви адмінок різні та кількість введенних компаній на котрі йде копіювання 1, то йдемо змінювати
                    if(this.form.taskName.value == 'copy_data_franchise') {
                        this.form.taskName.value = 'trans_data_from_old_company_franchise'
                        this.add()
                    } else if(this.form.taskName.value == 'copying_data_inside') {
                        this.form.taskName.value = 'trans_data_from_old_company'
                        this.add()
                    } else {
                        this.add()
                    }
                } else if(this.otherCompany.length === 1){
                    // якщо назви адмінок однакові та кількість введенних компаній на котрі йде копіювання 1, то йдемо змінювати
                    if(this.form.taskName.value == 'trans_data_from_old_company_franchise') {
                        this.form.taskName.value = 'copy_data_franchise'
                        this.add()
                    } else if(this.form.taskName.value == 'trans_data_from_old_company') {
                        this.form.taskName.value = 'copying_data_inside'
                        this.add()
                    } else {
                        this.add()
                    }
                }
            } else {
                this.add()
            }
        },
        send() {
            if(this.perm == (false || undefined || null)) {
                this.add()
            } else {
                this.edit()
            }
        },
        openTask(e) {
            // відкрити картку завдання після створення
            let type = 'task'
            this.eventBus.emit('modalInModal', {
                status: true,
                data: e,
                type: type,
            })
            this.showDialogOpen = false
            this.$emit('close')
        },
        openFromEdit(e) {
            this.$emit('closelast')
            this.$emit('open', e)
        },
        getTT(){
            // отримати торгові точки
            this.tt = []

            apiSuperAdmin.getCompClient(this.mainCompany.value).then(result => {

                if(result.status == 'done'){

                    if(result.data.companyDataObj.tradepoints?.length > 0){

                        var tradepoints = result.data.companyDataObj.tradepoints;

                        for(var item in tradepoints){

                            // показуємо тільки ті точки, які не видалені
                            if (tradepoints[item].deleted == false) {
                                this.tt.push({
                                    label: tradepoints[item].tradepointName,
                                    value: tradepoints[item].tradepointId
                                })
                            }

                        }

                        this.$toast.success(this.$t('outletsReceived'));

                    } else {
                        this.$toast.error(this.$t('ThereNoTTFound'));
                    }

                } else {

                    this.$toast.error(this.$t('ThereNoTTFound'));

                    var errorText = 'Помилка відкриття компанії клієнта'; 
                    var statusRequest = result.status;
                    var api = 'getCompClient';
                    var fileRequest = 'src/views/tasks/createtask.vue';
                    var params = this.mainCompany.value;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 

                }
            })
        },
        getCompany(number) {
            this.company = [];
            this.newServerCompany = [];

            var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"] // прописємо коди операторів по Україні


            numcode.forEach(el => {
                if(String(number).substr(0, 3) == el ) {
                    number = "38" + number // якщо номер почався з коду оператора
                    this.form.userPhone = number
                }
            })
            
            // отримати компанії
            apiSuperAdmin.getCard(number).then(result => {
                if(result.status == 'done') {
                    if(result.data.accountId != false && result.data.accountId != undefined){

                        this.accountId = result.data.accountId;
                        var personalData = result.data.personalData
                        var сompanies = result.data.сompanies
                        this.form.userEmail = personalData.email != '' ? personalData.email : '' 

                        for(var item in сompanies) {
                            // додавання всіх компаній по аккаунту
                            this.company.push({
                                label: `${сompanies[item].companyName}` + " - " + 
                                    `${сompanies[item].more.companyServer == "s-1" || сompanies[item].more.companyServer == "online-2" ? 
                                        this.$t('OldAdmin') : 
                                        this.$t('NewAdmin_panel')}` + 
                                        " (" + (
                                                сompanies[item].personAdmin == true ? 
                                                this.$t('owner_of_company') : this.$t('Employee')
                                            ) + 
                                        ") " + `\n (${сompanies[item].companyId})`,
                                value: `${сompanies[item].companyId}`,
                            })

                            // додавання нових версій компаній
                            if (сompanies[item].more.companyServer != "s-1" || сompanies[item].more.companyServer != "online-2") {
                                
                                this.newServerCompany.push({
                                    label: `${сompanies[item].companyName}` + 
                                    " (" + (сompanies[item].personAdmin == true ? this.$t('owner_of_company') : this.$t('Employee')) + ") " + 
                                    `\n (${сompanies[item].companyId})`,
                                    value: `${сompanies[item].companyId}`,
                                })

                            }
                            
                            if(this.form.taskName.value == 'enable_new_version' && сompanies[item].more.companyServer != "s-1" && сompanies[item].more.companyServer != "online-2") {
                                this.company[this.company.length - 1].disabled = true
                            }
                        }

                        if(this.obj.companyId) {
                            this.mainCompany = {value: this.obj.companyId, label: this.obj.companyName}
                        }

                        this.$toast.success(this.$t('CompaniesReceived'));

                    } else if((result.data.accountId != false && result.data.accountId != undefined) && result.data.сompanies.length < 0){

                        this.$toast.error(this.$t('ThereNoCompaniesFound'));

                        var errorText = 'Помилка отриманння картки клієнта'; 
                        var statusRequest = result.status;
                        var api = 'getCard';
                        var fileRequest = 'src/views/tasks/createtask.vue';
                        var params = number;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    } else {
                        this.$toast.error(this.$t('AccountnotFound'));

                        var errorText = 'Помилка отриманння картки клієнта'; 
                        var statusRequest = result.status;
                        var api = 'getCard';
                        var fileRequest = 'src/views/tasks/createtask.vue';
                        var params = number;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                } else {
                    this.$toast.error(this.$t('AccountnotFound'));

                    var errorText = 'Помилка отриманння картки клієнта'; 
                    var statusRequest = result.status;
                    var api = 'getCard';
                    var fileRequest = 'src/views/tasks/createtask.vue';
                    var params = number;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
            // apiSuperAdmin.getListCompany(number).then(result => {
            //     if(result.status == 'done') {
            //         for(var item in result.data.items) {
            //             this.company.push({
            //                 label: `${result.data.items[item].companyName}`,
            //                 value: `${result.data.items[item].companyId}`,
            //             })
            //         }
            //     }
            // })
        },
        edit() {
            // зміна завдання
            let id = this.form.id;
            // delete this.form.id
            if (!this.form.userPhone) {
                //перевіряємо чи вказаний номер телефону
                this.$toast.error(this.$t('EnterNumberC'));
                return
            } else { // прибираємо пробіли в номерах
                this.form.userPhone.replace(' ', '')
                this.form.copyPhone.replace(' ', '')
            }
            if (!this.form.taskName) {
                //перевіряємо чи вказана задача
                this.$toast.error(this.$t('EnterTask'));
                return
            }
            if (!this.form.priority) {
                //перевіряємо чи вказаний пріоритет
                this.$toast.error(this.$t('EnterPriority'));
                return    
            }
            if (!this.form.companyId) {
                //перевіряємо чи вказана компанія клієнта
                this.$toast.error(this.$t('EnterCompanyC'));
                return
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний коментар
                this.$toast.error(this.$t('EnterComment'));
                return
            }

            console.log(this.selectedOneTT, this.selectedTT)

            // Якщо вибрано конкретну торгову точку (selectedOneTT), то встановлюємо її значення в поле tradepoints форми
            if(this.selectedOneTT.value) {
                this.form.tradepoints = [Number(this.selectedOneTT.value)]
            } 
            // Якщо вибрано кілька торгових точок (selectedTT), то встановлюємо їхні значення в поле tradepoints форми
            else if(this.selectedTT.length > 0){
                this.form.tradepoints = this.selectedTT
            }

            if(
                this.form.taskName?.value == 'copying_data_inside' || 
                this.form.taskName?.value == 'trans_data_from_old_company' || 
                this.form.taskName?.value == 'transfer_funds_companies_in_account'
            ) {
                // Встановлюємо значення першої компанії з масиву otherCompany в поле copyOnCompany форми
                this.form.copyOnCompany = this.otherCompany[0].value
            }

            this.form.translateTaskName = this.form.taskName.label
            this.form.taskName = this.form.taskName.value

            apiServe.editTask(this.form.id, this.form).then(result=>{
                if(result.status==='done') {
                    this.$toast.success(this.$t('alertChanged'));
                    if(this.checks['025'] == true) {
                        this.dontSave = true
                        this.form = result.data
                        this.showDialogOpen = true
                    } else {
                        this.dontSave = true
                        this.$emit('close')
                    }
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка при редагуванні завдання'; 
                    var statusRequest = result.status;
                    var api = 'editTask';
                    var fileRequest = 'src/views/tasks/createtask.vue';
                    this.form.id = id
                    var params = {form: this.form};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        getCopyCompany(number) {
            // отримуємо компанії для копіювання
            this.copyCompany = [];
            this.copyNewServerCompany = [];

            var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"] // прописємо коди операторів по Україні


            numcode.forEach(el => {
                if(String(number).substr(0, 3) == el ) {
                    number = "38" + number // якщо номер почався з коду оператора
                    this.form.copyPhone = number
                }
            })

            apiSuperAdmin.getCard(number).then(result => {
                if(result.status == 'done') {
                    if(result.data.accountId != false && result.data.accountId != undefined){

                        var personalData = result.data.personalData
                        var сompanies = result.data.сompanies
                        this.form.copyUserEmail = personalData.email != '' ? personalData.email : '' 
                        this.form.copyUserName = personalData.user_name != '' ? personalData.user_name : '' 

                        for(var item in сompanies) {

                            // додаємо всі компанії
                            this.copyCompany.push({
                                label: `${сompanies[item].companyName}` + 
                                " - " + 
                                `${сompanies[item].more.companyServer == "s-1" || сompanies[item].more.companyServer == "online-2" ? this.$t('OldAdmin') : this.$t('NewAdmin_panel') }` +
                                ` (${сompanies[item].companyId})`,
                                value: `${сompanies[item].companyId}`,
                            })

                            // додаємо компанії нової версії
                            if (сompanies[item].more.companyServer != "s-1" &&
                                сompanies[item].more.companyServer != "online-2"
                            ) {

                                this.copyNewServerCompany.push({
                                    label: `${сompanies[item].companyName}` +
                                    ` (${сompanies[item].companyId})`,
                                    value: `${сompanies[item].companyId}`,
                                })

                            }

                        }

                        // Якщо це редагування
                        if(this.obj.copyOnCompany) {

                            // Фільтруємо торгові точки
                            let hashTable = {}

                            // Перебираємо компанії, які потрібно скопіювати
                            this.obj.copyOnCompany.forEach((el, index) => {
                                // Створюємо хеш-таблицю для уникнення дублікатів
                                if(!hashTable[el]) {
                                    // Якщо компанія має назву, то використовуємо її, інакше генеруємо назву за допомогою індексу
                                    hashTable[el.companyId] = el.companyName? el.companyName : `${this.$t('company')} ${index + 1}`
                                }
                            })

                            // Очищуємо масив копіювання компанії
                            this.obj.copyOnCompany = []
                            // Фільтруємо торгові точки

                            // Перебираємо хеш-таблицю та створюємо масив otherCompany
                            Object.keys(hashTable).forEach(el => {
                                this.otherCompany.push({label: hashTable[el], value: el})
                            })

                            // Виводимо результати в консоль
                            console.log(hashTable, this.otherCompany)
                        }

                        this.$toast.success(this.$t('CompaniesReceived'));

                    } else if((result.data.accountId != false && result.data.accountId != undefined) && result.data.сompanies.length < 0){
                        this.$toast.error(this.$t('ThereNoCompaniesFound'));

                        var errorText = 'Помилка отриманння картки клієнта'; 
                        var statusRequest = result.status;
                        var api = 'getCard';
                        var fileRequest = 'src/views/tasks/createtask.vue';
                        var params = number;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    } else {
                        this.$toast.error(this.$t('AccountnotFound'));

                        var errorText = 'Помилка отриманння картки клієнта'; 
                        var statusRequest = result.status;
                        var api = 'getCard';
                        var fileRequest = 'src/views/tasks/createtask.vue';
                        var params = number;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                } else {
                    this.$toast.error(this.$t('AccountnotFound'));

                    var errorText = 'Помилка отриманння картки клієнта'; 
                    var statusRequest = result.status;
                    var api = 'getCard';
                    var fileRequest = 'src/views/tasks/createtask.vue';
                    var params = number;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
            // apiSuperAdmin.getListCompany(number).then(result => {
            //     if(result.status == 'done') {
            //         for(var item in result.data.items) {
            //             this.copyCompany.push({
            //                 label: `${result.data.items[item].companyName}`,
            //                 value: `${result.data.items[item].companyId}`,
            //             })
            //         }
            //     }
            // })
        },

        add() {
            // додавання завдання
            
            // Заносимо в форму компанію, з котрої будуть проводитися операції
            this.form.companyId = this.mainCompany.value;

            // беремо переклад таски в окрему змінну + правильне значення типу таски
            this.form.translateTaskName = this.form.taskName.label
            this.form.taskName = this.form.taskName.value
            // console.log(this.form.taskName)
            // return  

            console.log("if", this.selectedTT.length > 0, this.selectedOneTT.value)

            if(this.selectedTT.length > 0) { // якщо є вибрані точки, то додаємо їх
                for(var item in this.selectedTT){
                    this.form.tradepoints.push(this.selectedTT[item].value)
                }
            } else {

                if(this.selectedOneTT.value != "") {
                    this.form.tradepoints.push(this.selectedOneTT.value) // заносимо цю точку в масив
                }
            }

            if(
                !this.form.tradepoints[0] && (
                    this.form.taskName === 'repair_tt' 
                    || this.form.taskName === 'copy_data_franchise' 
                    || this.form.taskName === 'copying_data_inside'
                    || this.form.taskName === 'trans_data_from_newServer_company'
                    || this.form.taskName === 'trans_data_from_newServer_company_franchise'
                    || (this.form.taskName === 'enable_new_version' && this.checkCopyNewVer === true)
                    || this.form.taskName === 'trans_data_from_old_company' 
                    || this.form.taskName === 'trans_data_from_old_company_franchise'
                ) 
            ) {
                //перевіряємо чи вказані точки
                this.$toast.error(this.$t('enterTT'));
                return   
            }

            console.log("this.> ", this)
            console.log("otherCompany > ", this.otherCompany)

            if(this.otherCompany && this.otherCompany.length > 0) { // якщо є вибрані інші компанії і довжина масиво більше 0
                this.form.copyOnCompany.push(this.otherCompany[0].value)
            } else {
                this.form.copyOnCompany.push(this.otherCompany.value) // якщо просто вибрана компанія, але вона не масив
            }

            if (!this.form.userPhone) {
                //перевіряємо чи вказаний номер телефону
                this.$toast.error(this.$t('EnterNumberC'));
                return
            }
            if (!this.form.taskName) {
                //перевіряємо чи вказана задача
                this.$toast.error(this.$t('EnterTask'));
                return
            }
            if (!this.form.priority) {
                //перевіряємо чи вказаний пріоритет
                this.$toast.error(this.$t('EnterPriority'));
                return    
            }

            console.log("form add -> ", this.form);
            let langs = {
                "multimode": [{"ua": 1862791}, {"pl": 1862826}, {"en": 1862827}], 
                "syncmode": [{"pl":1860828}, {"ua":1860724}, {"en":1860723}]
            } // закидуємо id патернів sendpulse з мовами
            if(this.form.userPhone && this.form.taskName && this.form.priority){
                switch (this.form.taskName) {
                    case 'multimode':
                        if(String(this.form.userPhone).substring(0, 2) == '38') {
                            langs['multimode'].forEach(el => {
                                if(el['ua']) {
                                    this.form.templateId = el['ua']
                                } 
                            })
                        } else if(String(this.form.userPhone).substring(0, 2) == '48') {
                            langs['multimode'].forEach(el => {
                                if(el['pl']) {
                                    this.form.templateId = el['pl']
                                } 
                            })
                        } else {
                            langs['multimode'].forEach(el => {
                                if(el['en']) {
                                    this.form.templateId = el['en']
                                } 
                            })
                        }
                        
                    break;
                    case 'syncmode':
                        if(String(this.form.userPhone).substring(0, 2) == '38') {
                            langs['syncmode'].forEach(el => {
                                if(el['ua']) {
                                    this.form.templateId = el['ua']
                                } 
                            })
                        } else if(String(this.form.userPhone).substring(0, 2) == '48') {
                            langs['syncmode'].forEach(el => {
                                if(el['pl']) {
                                    this.form.templateId = el['pl']
                                } 
                            })
                        } else {
                            langs['syncmode'].forEach(el => {
                                if(el['en']) {
                                    this.form.templateId = el['en']
                                } 
                            })
                        }
                    break;
                    case 'receiving_token':
                        if(String(this.form.userPhone).substring(0, 2) == '38') {
                            this.form.templateId = 1880664
                        } 
                        // else if(String(this.form.userPhone).substring(0, 2) == '48') {
                        //     langs['syncmode'].forEach(el => {
                        //         if(el['pl']) {
                        //             this.form.templateId = el['pl']
                        //         } 
                        //     })
                        // } else {
                        //     langs['syncmode'].forEach(el => {
                        //         if(el['en']) {
                        //             this.form.templateId = el['en']
                        //         } 
                        //     })
                        // }
                    break;
                    default:
                        // if(this.form.userPhone.substring(0, 2) == '38') {
                            
                        // } else if(this.form.userPhone.substring(0, 2) == '48') {
                            
                        // } else {

                        // }
                        this.form.templateId = 1496469
                    break;
                }

                // застосування типу переносу коштів між компаніями
                if(this.form.taskName == 'transfer_funds_companies_between_accounts' || this.form.taskName == 'transfer_funds_companies_in_account'){
                    if(this.typeTransferLicen != 'balance'){
                        this.form.descType = this.typeTransferLicen
                    }
                }
                
                if(this.form.whenPerform == "2"){
                    this.form.executionDatetime = this.timeObjPerf.date + " " + this.timeObjPerf.time;
                } else {
                    this.form.executionDatetime = ""
                }

                apiServe.addTask(this.form).then(result => {
                    if(result.status === 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        if(this.checks['025'] == true) {
                            this.dontSave = true
                            this.form = result.data
                            this.showDialogOpen = true
                        } else {
                            this.dontSave = true
                            this.closeModal();
                        }
                        
                        // this.eventBus.emit('saveTask', true)
                        
                    } else if (result.status == 'error') {

                        if(result.error[0] == 'Не вказано торгових точок!') {
                            this.$toast.error(this.$t('specifyIdTt'));
                        } else {
                            this.$toast.error(this.$t('error') + " " + result.error[0]);

                            var errorText = 'Помилка додавання таски'; 
                            var statusRequest = result.status;
                            var api = 'addTask';
                            var fileRequest = 'src/views/tasks/createtask.vue';
                            var params = this.form;
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error)); 
                        }
                    }
                }) //запит на створення завдання
            }
        },
        closeModal() {
            //закрытие задачи
            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            this.$emit('close');
            // this.$emit('getdata');
            window.removeEventListener('popstate', this.handlePopstate);
        },
        savecomment:function() {
            let a = this.form;
            if(this.form.userPhone === '' || this.form.taskName === '') {
                this.$emit('close');    
            } else {
                this.$emit('close');  //cохраняем форму
            }
        },
        generComment(){
            // генеруємо коментар
            var task = '';
            switch (this.form.taskName) {
                case 'repair_tt':
                    task = 'Потрібно в компанії " ' + this.mainCompany.label + ' " відновити точки з ID - '
                    for(var item in this.selectedTT){
                        task += this.selectedTT[item].label + ", "
                    };
                    break;
                case 'clear_stats':
                    task = 'Потрібно очистити статистику в компанії ' + this.mainCompany.label
                    break; 
                case 'copy_data_franchise':
                    task = 'Потрібно скопіювати дані торгових точок - '
                    for(var item in this.selectedTT){
                        task += this.selectedTT[item].label + ", "
                    };
                    task += ' з компанії - ' + this.mainCompany.label + ' в '
                    for(var op in this.otherCompany){
                        task += "' " + this.otherCompany[op].label + "' , "
                    };
                    break;
                case 'copying_data_inside':
                    task = 'Потрібно скопіювати дані торгових точок - '
                    for(var item in this.selectedTT){
                        task += this.selectedTT[item].label + ", "
                    };
                    task += ' з компанії - ' + this.mainCompany.label + ' в '
                    for(var op in this.otherCompany){
                        task += "' " + this.otherCompany[op].label + "' , "
                    };
                    break;
                case 'trans_data_from_old_company':
                    task = 'Потрібно скопіювати дані з старої версії в нову - '
                    // for(var item in this.selectedTT){
                    //     task += this.selectedTT[item].label + ", "
                    // };
                    // task += ' з компанії - ' + this.mainCompany.label + ' в '
                    // for(var op in this.otherCompany){
                    //     task += "' " + this.otherCompany[op].label + "' , "
                    // };
                    break;
                case 'discharge_password':
                    task = 'Потрібно скинути пароль до 00000 до акаунту - ' + this.form.userPhone + '( ' +  this.accountId + ' )'
                    break;
                case 'syncmode':
                    task = 'Потрібно увімкнути режим синхронізації в компанії ' + this.mainCompany.label
                    break;
                case 'multimode':
                    task = 'Потрібно увімкнути режим мультитерміналу в компанії ' + this.mainCompany.label
                    break;
                case 'add_change_domain':
                    task = 'Потрібно змінити домен до SkyMarket в компанії - ' + this.mainCompany.label + " на ' " + this.form.descType + " '"
                    break;
                case 'change_limit_companies':
                    task = 'Потрібно змінити ліміт компаній до облікового запису - ' + this.accountId + " на ' " + this.form.descType + " '"
                    break;
                case 'enable_new_version':
                    task = 'Потрібно додати компанію з новою версією SkyService до облікового запису - ' + this.accountId
                    break;
            
                default:
                    break;
            }


            this.form.comment = task;
        },
        confirmExit(event) {
            // Перевірка, чи є необхідні дані відкриті в вікні
            if (this.haveUnsavedChanges()) {
                const message = this.$t('confirmCloseModalAlert');
                // Стандартна повідомлення браузера для підтвердження виходу
                event.returnValue = message;
                return message;
            }
        },
        haveUnsavedChanges() {
            //Перевірка полів, якщо хоча б одне заповнене, буде відображатись вікно чи точно бажаєте закрити вікно

            return this.form.userPhone !== '' 
                    || this.form.taskName !== '' 
                    || this.form.userEmail !== ''
                    || this.form.comment !== ''
                    || this.form.companyId !== ''
                    || this.form.copyPhone !== ''
                    || this.form.copyOnCompany !== ''
                    || this.form.copyUserName !== '';
        },
        change(selectedDates, dateStr, instance){
            /**
                * Метод, який викликається при зміні дати та часу в flatpickr.
                * @param {Array} selectedDates - Масив вибраних дат.
                * @param {string} dateStr - Рядок з вибраними датами.
                * @param {Object} instance - Екземпляр flatpickr.
            */

            this.form.executionDatetime = this.timeObjPerf.date + " " + this.timeObjPerf.time;
            console.log('dte>>', this.form);
        },
    },
    mounted() { 
        if(localStorage.getItem('form')) {
            this.form = JSON.parse(JSON.parse(localStorage.getItem('form')).form); 
        }
        localStorage.removeItem('form') //взятие формы и подставление

        // слухаємо події закриття, ребуту сторінки 
        window.addEventListener('beforeunload', this.confirmExit);
    },
    beforeUnmount() {
        // видаляєте слухача події
        window.removeEventListener('beforeunload', this.confirmExit);

        // збереження форми в indexedDB
        if(this.dontSave == false) {
            if(this.form.userPhone !== '' || this.form.userEmail !== '' || this.form.priority !== '' || this.form.comment !== '' || this.form.tradepoints.length > 0 || (this.form.copyPhone !== undefined && this.form.copyPhone !== '') || (this.form.copyOnCompany !== undefined && this.form.copyOnCompany.length > 0) || (this.form.copyUserName !== undefined && this.form.copyUserName !== '') || (this.form.copyUserEmail !== undefined && this.form.copyUserEmail !== '')) {
                
                let detail = {
                    dateTime: new Date(),
                    text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                    category: 'tasks',
                    form: JSON.stringify(this.form)
                }; 

                dbPromise.add(detail)
                
                //додавання в indexedDB
            }
        }
    },
    // watch: {
    //     form(newValue) {
    //         if(this.checks['009']) {
    //             localStorage.setItem("check_form", JSON.stringify(newValue));
    //             console.log(JSON.stringify(this.form));
    //         }
    //     }
    // },
    computed: {
        user() {
            return storeS.userbase
        },
        checks(){
            return storeS.checks
        },
    },
})
</script>

<style>

.errorInp {
    border-color: red!important;
}

</style>
