<template>
    <!-- Компонент для фільтру по призначеним. Приховуємо або показуємо призначені моніторинги -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('Приховати призначені') }}
        </label>

        <br />
        <div class="row g-2 list_filtr" >

            <!-- Цикл для виведення радіобатонів для типу заявки -->
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioAction" :id="'action'+index" v-model="hide" :value="value.value">
                        <label class="form-check-label" :for="'action'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            hide: false, // Обраний статус
            opt: [
                {
                    // Приховати
                    name: this.$t('y'),
                    value: true,
                },
                {
                    // Показати
                    name: this.$t('n'),
                    value: false,
                },
            ]
        }
    },
    methods: {
        change(){
            // Метод, що викликається при зміні фільтру
            this.$emit('change', 'hideMonitoringByWorker', this.hide)
        },
    },
}
</script>