<template>
    <div class="mb-4 border-top mt-3 pt-3 " >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('appointed') }}</label
        >
        
        <Multiselect 
            mode="tags"
            v-model="workerArr"
            :key="key"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="workersList"
            @select="this.change"
        />
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';

export default {
    props: ["assignedWorkers"],
    components: { Multiselect },
    data(){
        return {
            workerArr: [],
            workersList: [],
            key: 0
        }
    },
    created() {
        console.log(this.workerArr)
        this.workerArr = JSON.parse(this.assignedWorkers)
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }
        this.key++
    },
    methods: {
        change(){          
            this.$emit('change', 'assignedWorkerIds', this.workerArr)
        },
    },
    computed: {
        workers(){
            return storeS.workers
        },
    }
}
</script>