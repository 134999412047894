<template>
    <!-- 
        Компонент модального для запрошення працівників (створювалось під список співробітників)
    -->
    
    <div class="modal fade show" id="inviteMembersModal" style="display: block;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-0" >

                <!-- Заголовок модального вікна -->
                <div class="modal-header p-3 ps-4 bg-soft-success" >
                    <h5 class="modal-title" id="inviteMembersModalLabel">
                        {{$t('Employees')}}
                    </h5>

                    <!-- Кнопка закриття модального вікна -->
                    <button type="button" class="btn-close" @click="$emit('close')"></button>
                </div>

                <!-- Тіло модального вікна -->
                <div class="modal-body p-4" >

                    <!-- Пошукове поле -->
                    <div class="search-box mb-3">
                        <input type="text" class="form-control bg-light border-light" v-model="search" :placeholder="$t('searc_inp')">
                        <i class="ri-search-line search-icon"></i>
                    </div>

                    <!-- Кнопки для вибору та зняття виділення з усіх працівників -->
                    <div 
                        v-if="this.hideNotForYuraButtons != true"
                        class="mt-2 mb-2 hstack gap-sm-1 gap-1 align-items-center flex-wrap email-topbar-link"
                    >
                        <span class="copy badge bg-success fs-14" @click="selectAll">{{ $t('SelectAll') }}</span>
                        <span class="copy badge bg-danger fs-14" @click="removeAll">{{ $t('RemoveAll') }}</span>
                    </div>

                    <!-- Відображення вибраних працівників -->
                    <div 
                        v-if="this.hideNotForYuraButtons != true"
                        class="mb-2 d-flex align-items-center" 
                    >
                        <div class="me-2" >
                            <h5 class="mb-0 fs-16 fw-semibold mb-2">{{ $t('selectedWorkers') }} :</h5>
                            <template v-for="item in form.workers" :key="item">
                                <span v-if="item.checked" class="badge bg-info fs-12 m-1" style="margin-right: 5px;" >{{ item.workerName }}</span>
                            </template>
                        </div>
                    </div>

                    <!-- Список працівників з можливістю вибору -->
                    <div style="max-height: 257px; overflow: auto;" >
                        <!-- Перевірка, чи є працівники, які відповідають критеріям пошуку -->
                        <ul class="list-group list-group-flush border-dashed" v-if="filterWorkers.length > 0">
                            <!-- Виведення працівників -->
                            <li class="list-group-item ps-0" v-for="(item, index) in filterWorkers" :key="index">
                                <div class="d-flex align-items-start" >
                                    <div class="flex-grow-1" >
                                        <!-- Ім'я працівника -->
                                        <h5 class="fs-13 mb-0 copy" @click="this.$emit('open', item.workerId)">{{item.workerName}}</h5>
                                    </div>
                                    <div class="flex-shrink-0 ms-2">
                                        <!-- Кнопка додавання або видалення працівника -->
                                        <button 
                                            type="button" 
                                            :class="`btn btn-${item.checked ? 'danger' : 'success' } btn-sm`" 
                                            @click="item.checked ? remove(index) : add(item)"
                                        >
                                            {{ item.checked ? $t('Delete') : (
                                                this.hideNotForYuraButtons != true ? $t('Add') : $t('Appoint')
                                            ) }}
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        
                        <!-- Виведення повідомлення про відсутність працівників, які відповідають критеріям пошуку -->
                        <b v-else>{{ $t('noWorkersWithName') }}</b>
                    </div>

                </div>

                <div class="modal-footer" >
                    <!-- Кнопка для збереження вибраних працівників -->
                    <button 
                        type="button" 
                        class="btn btn-success w-xs"
                        @click="invite"
                    >
                        {{ $t('save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { storeS } from '@/store' // глобальне сховище даних

export default ({
    props: ['projectWorkers', 'isInviteOne', 'hideNotForYuraButtons'],
    data() {
        return{
            assignWorkers: [],
            form: {
                workers: []
            },
            search: '' // Пошук
        }
    },
    created(){
        
        for(var item in this.workers){
            this.form.workers.push({
                workerId: this.workers[item].workerId,
                workerName: this.workers[item].workerName,
                checked: this.checkWorkers(this.workers[item])
            })
        }
        
    },
    methods: {
        add(e){
            // Додати працівника до вибраних
            e.checked = true
            this.assignWorkers.push(e)
            
            if(this.isInviteOne === true) {
                this.inviteOne()
            }
        },
        checkWorkers(e){
            // Перевірити, чи вибраний працівник належить до проекту

            var check = "";
            this.projectWorkers.forEach((el) => {
                console.log(el)
                if(el.workerId === e.workerId) {
                    check = true
                    this.assignWorkers.push(e)
                }
            })
            return check
        },
        remove(index){
            // Видалити працівника із вибраних
            this.form.workers[index].checked = false
        },
        invite(){
            this.assignWorkers = this.form.workers.filter(el => el.checked === true)
            console.log(this.assignWorkers)
            // Запросити вибраних працівників

            var workersList = {
                workers: this.assignWorkers
            }
            console.log(workersList)

            if(workersList.workers.length > 0){
                this.$emit('invite', workersList);
                this.$emit('close');
            } else {
                this.$toast.error(this.$t('EnterWorker'));
            }
        },
        inviteOne() {
            this.$emit('inviteOne', this.assignWorkers);
            this.$emit('close');
        },
        selectAll(){
            // Вибрати всіх працівників

            for(var item in this.form.workers){
                this.form.workers[item].checked = true

                this.assignWorkers.push(this.form.workers[item])
            }
        },
        removeAll(){
            // Зняти виділення з усіх працівників

            for(var item in this.form.workers){
                this.form.workers[item].checked = false
                this.assignWorkers = [this.form.workers[item]]
            }
        }
    },
    computed: {
        filterWorkers: function() {
            // Відфільтровані працівники
            return Object.values(this.form.workers)
                // По полю пошуку
                .filter(item => {
                    return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        workers(){
            /*
                Беремо список співробітників з глобального стору.
            */
            return storeS.workers
        }
    }
})
</script>

<style scoped>
.modalshow {
    display: block;
    opacity: 1;
}

.modal {
    background: #2e2e2eb8;
    transition: opacity 0.15s linear;
    backdrop-filter: blur(5px);
}

</style>
