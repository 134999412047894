<template>
    <!-- моадльне вікно створення тікету -->
    <modal @send="this.send()">
        <template v-slot:title>{{ $t('new_ticket') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Тип тікету -->
                        <h4 class="fs-15">{{ $t('ticket_name') }}</h4>
                        <input type="text" class="form-control" v-if="this.checks['024'] == false" id="text" maxlength="60" v-model="form.ticketName" :placeholder="$t('for_example_name_ticket')">
                        <input type="text" class="form-control" v-else id="text" :maxlength="this.limit" v-model="form.ticketName" :placeholder="$t('for_example_name_ticket')">
                        <span class="fs-15" v-if="this.checks['024'] == false" :style="this.form.ticketName.length == 60 ? 'color:red' : ''" style="display: block; width: 100%;text-align: end;" >{{ this.form.ticketName.length }}/55</span>
                        <span class="fs-15" v-else @click="this.limit = (Math.random() * 120).toFixed(); this.form.ticketName = ''" :style="this.form.ticketName.length == this.limit ? 'color:red' : ''" style="display: block; width: 100%;text-align: end;" >{{ this.form.ticketName.length }}/{{this.limit}}</span>
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Тип тікету -->
                        <h4 class="fs-15">{{ $t('ticket_type') }}</h4>
                        <Multiselect 
                                v-model="this.form.type"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.typeTicket"
                                :placeholder='$t("problem_to_develop")+" , " + $t("wish_to_develop")'
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Вибір пріоритету -->
                        <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                            <Multiselect 
                                v-model="form.priority"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.listPriority"
                                :placeholder="$t('priority')"
                            />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <!-- Номери клієнтів -->
                        <h4 class="fs-15">{{ $t('clients_number') }}</h4>
                        <Multiselect
                            v-model="numbers"
                            mode="tags"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="true"
                            :options="this.frequentNumbers"
                            @change="findComp($event)"
                        />
                    </div>
                </b-col>

                <b-col lg="12" v-if="form.priority == '3'">
                    <div class="mb-3">
                        <!-- Причина критичності тікету -->
                        <h4 class="fs-15">{{ $t('ReasonsforCriticality') }}</h4>
                        <textarea type="text" class="form-control" v-model="form.reason" id="text"  :placeholder="$t('ReasonsCritPlaceh')"></textarea>
                    </div>
                </b-col>

                <b-col lg="12" v-if="Boolean(this.perm) !== true">
                    <!-- Cередовище проблеми -->
                    <h4 class="fs-15">{{ $t('problem_area') }} <span class="text-muted">({{devProjects.length}})</span></h4>
                    <Multiselect 
                        v-model="form.problemEnvironment"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="this.devProjects"
                        :placeholder="$t('device_printer')+', '+$t('device_rro')+', '+$t('dir_pos')"
                    />
                    <div v-if="form.devices != ''" class="hstack text-wrap gap-1 mt-3 mb-2">
                        <span class="fs-14 badge badge-soft-primary" v-for="item in form.devices" :key="item" @click="open(item)">{{$t(item.way_problem)}} {{$t(item.device.manufacturer)}}</span>
                    </div>
                    <button  type="button" class="btn btn-primary mt-2" @click="showdevice = true">{{ $t('addDevice') }}</button>
                </b-col>
            </b-row>
            <b-row>
                <!-- <b-col lg="6" class="mt-3">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_status') }}</h4>
                        <Multiselect 
                            v-model="form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.listStatus"
                            :placeholder="$t('Status')"
                        />
                    </div>
                </b-col> -->
                
            </b-row>
            <!-- <b-row>
                дедлайн не може вказуватись при додавані
                <div class="col-lg-12">
                            <div>
                                <label for="datepicker-deadline-input" class="form-label">Deadline</label>
                            <select class="form-select mb-3" data-plugin="choices" v-model="form.deadlineId" >
                                <option v-for="item in dealines" :key="item" :value="item.deadlineId">Ver: {{ item.version + " | " + item.deadlineDate}}</option>
                            </select>
                        </div>
                    </div>
            </b-row> -->
                
                <!-- <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('tags') }}</h4>
                            <Multiselect
                                v-model="form.tags"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="true"
                                :placeholder="$t('tags')"
                            />
                    </div>
                </div> -->
                <div class="mb-3 mt-3">
                    <!-- Опис тікету -->
                    <label for="VertimeassageInput" class="form-label">{{ $t('ticket_description') }}</label>
                    <QuillEditor v-model:content="form.comment" ref="quill" :modules="modules" theme="snow" contentType="html" toolbar="full"/>
                </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- Кнопки додавання та зміни тікету. 
                Є перевірка: 
                    - На наявність perm. Якщо true, то кнопка "Редагування"
            -->
            <button type="button" v-if="Boolean(this.perm) == false" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
            <button type="button" v-else class="btn btn-success" v-on:click="edit">{{ $t('Edit') }}</button>
        </template>
    </modal>

    <!-- 
        Модалка додавання девайсу:
            @close - подія, коли закривається вікно
            @addDeivce - подія, коли був вибраний девайся (доданий)
            @editDeivce - подія, коли був вибраний девайся (змінений)
            @removeDeivce - подія, коли видаленний девайс
            :numbers - пропс номерів клієнтів
            :itemdevice - пропс вибраного девайся
            :perm - пропс пермсу
    -->

    <adddevice 
        v-if="showdevice == true" 
        @close="closeItem" 
        @addDeivce="addDeivce"
        @editDeivce="editDeivce"
        @removeDeivce="removeDeivce"
        :numbers="form.userPhones"
        :itemdevice="itemdevice"
        :perm="false"
    />

    <!-- 
        Модалка вибору дії після створення:
            @open - дія "Відкриття" після створення
            @openFromEdit - дія "Відкриття" після редагування
            @close - дія закриття після створення
            :modules - пропс модуля
            :type - пропс типу модалки
            :obj - пропс, що передає форму
            :edit - пропс перму редагування
    -->

    <dialogOpenVue 
        v-if="this.showDialog==true" 
        @open="openTicket" 
        @openFromEdit="openTicket"
        @close="this.showDialog = false; this.$emit('close'); this.$emit('updateCard')" 
        :modules="modules" 
        :type="this.typeForModal" 
        :obj="this.result" 
        :edit="this.perm" 
    ></dialogOpenVue>

    <!-- 
        Модалка "Вибір компанії"
            @close - дія закриття після створення 
            @choseComp - дія, після створення компанії
            :numbers - пропс номерів клієнтів
    -->

    <enterCompany 
        v-if="showEnterComp === true" 
        @close="closeWithCheck" 
        @choseComp="addComp"
        :number="this.actualNumber" 
    >
    </enterCompany>

</template>

<script>
import enterCompany from './view/actions/enterCompany.vue';
import BlotFormatter from 'quill-blot-formatter'
import modal from '@/components/modal-small' 
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import adddevice from '@/components/ticketdeviceblock/index.vue'
import { Tickets, Clients, SuperAdmin } from '@/API.js';
import { storeS } from '@/store.js'
import dialogOpenVue from '@/components/globaltable/actions/dialogOpen.vue';
import { mutatePhone, mutateProblemEnv, mutateTypeTicket } from '../../usabilityScripts/globalMutate';
import { nameWorker, tgAccWorker } from '@/usabilityScripts/getNameWorker'
import { sendTelegramTicket, sendTelegramTotalTicketPhone } from '@/usabilityScripts/logsTelegram.js'
import dbPromise from '../../indexedDB';

let apiServe = new Tickets();
let apiClients = new Clients()

let apiAdmin = new SuperAdmin();

export default ({
    props: ["phone", "perm", "obj"],
    components: { 
        enterCompany,
        modal, 
        Multiselect, 
        adddevice,
        dialogOpenVue
    },
    setup: () => {
        const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
        }
        return { modules }
    },
    beforeMount(){
        if(this.perm == true) {
            console.log(this.obj)
            this.form.accountId = ''
            this.form = this.obj
            this.form.comment = this.form.createComment
            this.form.priority = String(this.form.priority)
            this.form.userPhones.forEach(el => {
                if(el.number){
                    this.numbers.push(String(el.number))
                    this.frequentNumbers.push(String(el.number))
                } else {
                    this.numbers.push(String(el))
                    this.frequentNumbers.push(String(el))
                }
            })
        }
    },
    data(){
        return {
            limit: 0,
            result: '',
            frequentNumbers: [],
            showdevice: false,
            itemdevice: "",
            showDialog: false,
            form: {
                deadlineId: '',
                accountId: '',
                devices: [],
                userPhones: [],
                ticketName: "",
                priority: '0',
                problemEnvironment: "",
                status: "new",
                type: "",
                comment: "",
                reason: ""
            },
            markdownOption: {
            },
            typeForModal: '',
            listStatus: [
                {
                    "label": this.$t('statusNew'),
                    "value": "new"
                },
                {
                    "label": this.$t('statusInWork'),
                    "value": "statusInWork"
                },
                {
                    "label": this.$t('statusCompleted'),
                    "value": "statusCompleted"
                }
            ],
            listPriority: [
                {
                    "label": this.$t('Critical'),
                    "value": "3"
                },
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            typeTicket: [
                {
                    "label": this.$t('problem_to_develop'),
                    "value": "problem"
                },
                {
                    "label": this.$t('wish_to_develop'),
                    "value": "wishes"
                }
            ],
            number: '',
            devProjects: [], // проєкти
            dontSave: false,
            actualNumber: '',
            showEnterComp: false,
            numbers: []
        }
    },
    created(){
        this.limit = (Math.random() * 120).toFixed()

        // перевірка на наявність частих номерів
        if(localStorage.getItem('frequentPhone')) {
            let obj = JSON.parse(localStorage.getItem('frequentPhone'))
            Object.keys(obj).forEach(el => {
                if(obj[el] > 1) {
                    this.frequentNumbers.push(String(el))
                }
            })
        }
        // перевірка на наявність частих номерів

        // Створенння тікету з картки клієнта
        if(this.phone){
            this.form.userPhones.push(this.phone)
        }

        this.changeSelectDevProjects();

    },
    methods: {  
        // якщо вікно просто було закрите, то не зараховуємо номер
        closeWithCheck(a){
            if(a == false) {
                this.showEnterComp = false
            } else {
                this.showEnterComp = false
                this.numbers.pop()
            }
        },
        changeSelectDevProjects(){
            // Робимо вигляд проєктів під мультіселект

            this.devProjects = [];
            for(var item in this.developmentProjects){
                var project = this.developmentProjects[item]
                this.devProjects.push({
                    "label": project.projectName,
                    "value": project.projectKey
                })
            }
            
        },
        // додавання компанії
        addComp(e) {
            this.$toast.success(this.$t('company') + ` ${e.companyName}`)
            this.form.userPhones.push(e) // в масив номерів кидаємо компанію з номером, або просто номер
            this.showEnterComp = false
        },
        // подія після додавання номеру на відкриття "Вибору компанії"
        findComp(e) {

            if(this.form.userPhones.length > e.length) { // якщо масив номерів, що запам'товані, більше, то сталось видалення і ми це опрацьовуємо
                this.form.userPhones.forEach((el, index) => {
                    if(e.indexOf(String(el).substr(0,2)) == -1 && e.indexOf(el) == -1) {
                        this.form.userPhones.splice(index, 1)
                    }
                })
            } else { // форматуємо номер та відкриваємо модалку
                if(isNaN(Number(Number(e[e.length - 1])))) {            
                    var compid = (e[e.length - 1]).replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, "$1-$2-$3-$4-$5");

                    apiAdmin.getListCompanyBycompanyId(compid).then(result => {
                        if(result.status == 'done' && result.data.show > 0){
                            this.actualNumber = this.phoneReset(Number(result.data.items[0].ownerPhone))
                            this.showEnterComp = true
                        } else if(result.data.show == 0) {
                            this.$toast.error(this.$t('AccountnotFound'))
                        }
                    })
                } else {
                    this.actualNumber = this.phoneReset(Number(e[e.length - 1]))
                    this.showEnterComp = true
                }
            }
        },
        // подія модифікації номера для апі
        phoneReset(e) {
            return mutatePhone(e)
        },
        send() {
            // створення/редагування тікету, подія з компоненту модалки
            if(this.perm == (false || undefined || null)) {
                this.add()
            } else {
                this.edit()
            }
        },
        // відкриття тікету
        openTicket(e) {
            this.showDialog = false;
            let type = 'ticket'
            this.$emit('updateCard')
            this.eventBus.emit('openTickets', e, type)
        },
        add() {
            // додавання з перевірками на наявність даних
            if (!this.form.type) {
                this.$toast.error(this.$t('EnterTypeTicket'));
                return
            }
            if (!this.form.ticketName) {
                this.$toast.error(this.$t('EnterNameTicket'));
                return
            }
            if (!this.form.priority) {
                this.$toast.error(this.$t('EnterPriority'));
                return
            }
            if (this.form.userPhones.length < 1) {
                this.$toast.error(this.$t('EnterNumberClient'));
                return
            }
            if (!this.form.problemEnvironment) {
                this.$toast.error(this.$t('EnterProblemEnvironment'));
                return
            } 
            if (!this.form.comment) {
                this.$toast.error(this.$t('EnterDescTicket'));
                return
            }  
            if (this.form.type != '' && this.form.ticketName && this.form.priority && this.form.problemEnvironment && this.form.comment && this.form.userPhones.length > 0){
                // скрипт для частих номерів
                if(localStorage.getItem('frequentPhone')) {
                    let obj = JSON.parse(localStorage.getItem('frequentPhone'))
                    this.form.userPhones.forEach(el => {
                        if(Object.keys(obj).indexOf(el) != -1) {
                            obj[el]++
                        }
                    })
                    localStorage.setItem('frequentPhone', JSON.stringify(obj))
                } else {
                    let obj = {}
                    this.form.userPhones.forEach(el => {
                        obj[el] = 1
                    })
                    localStorage.setItem('frequentPhone', JSON.stringify(obj))
                }
                // скрипт для частих номерів

                apiServe.createTicket(this.form).then(result => {
                    if(result.status === 'error') {
                        switch(result.error[0]){
                            case 'phones not found':
                            result.error[0] = this.$t('mobile_not_found')
                            break;
                            case "priority should be numeric": 
                            result.error[0] = this.$t("didnt_find_priority")
                            break;
                        }
                        this.$toast.error(this.$t('err'));
                        this.$toast.error(result.error[0]);
                        // this.$emit('close')
                    }
                    
                    if(result.status === 'done'){
                        this.$toast.success(this.$t('сreatedT'));

                        // Надсилаємо оповіщення ще в одну тг, щоб не пропустити
                        if(this.form.priority == '3'){
                            var ticketdId = result.data.ticketId;
                            var type = this.mutateticket(result.data.type);
                            var problemEnvironment = this.mutateProblemEnv(result.data.problemEnvironment);
                            var createWorkerId = this.nameWorker(result.data.createWorkerId);
                            var tgAccAuthorTicket = this.tgWorker(result.data.createWorkerId).tgNick;
                            var ticketName = result.data.ticketName;
                            var createComment = result.data.createComment;
                            var reasonTicket = result.data.reason;
                            var priority = this.form.priority

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramTicket(ticketdId, type, problemEnvironment, createWorkerId, ticketName, createComment, reasonTicket, tgAccAuthorTicket, priority)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error)); 
                        } else if(this.form.priority == '2') {
                            var ticketdId = result.data.ticketId;
                            var type = this.mutateticket(result.data.type);
                            var problemEnvironment = this.mutateProblemEnv(result.data.problemEnvironment);
                            var createWorkerId = this.nameWorker(result.data.createWorkerId);
                            var tgAccAuthorTicket = this.tgWorker(result.data.createWorkerId).tgNick;
                            var ticketName = result.data.ticketName;
                            var createComment = result.data.createComment;
                            var reasonTicket = result.data.reason;
                            var priority = this.form.priority

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramTicket(ticketdId, type, problemEnvironment, createWorkerId, ticketName, createComment, reasonTicket, tgAccAuthorTicket, priority)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error)); 
                        }

                        apiServe.getUnclosedTicketsByPhones(this.form.userPhones[0].number).then(result_new => {
                            if(result_new.status == "done") {
                                if(result_new.data.total >= 3) {
                                    var ticketdId = result.data.ticketId;
                                    var userPhone = this.form.userPhones[0].number;
                                    var tickets = result_new.data.items.slice(-10);
                                    var total = result_new.data.total;

                                    // Викликаємо функцію для відправки повідомлення в телеграм
                                    sendTelegramTotalTicketPhone(ticketdId, userPhone, total)
                                    .then(response => console.log('Telegram API response:', response.data))
                                    .catch(error => console.error('Telegram API error:', error)); 
                                }
                            } else {
                                console.log("err getUnclosedTicketsByPhones");
                            }
                        })
                        
                        // this.eventBus.emit('saveTickets', true)
                        if(this.settingCheck['025'] == true) {
                            this.typeForModal = 'ticket';
                            this.dontSave = true
                            this.result = result.data
                            this.showDialog = true
                            
                        } else {
                            this.dontSave = true
                            this.$emit('close')
                        }
                        
                        
                    }
                })
            }
        },
        edit() {
            // апі на редагування з перевікрами наявності всіх даних
            let id = this.form.ticketId;
            this.number = this.form.userPhones[0]
            if (!this.form.type) {
                this.$toast.error(this.$t('EnterTypeTicket'));
                return
            }
            if (!this.form.ticketName) {
                this.$toast.error(this.$t('EnterNameTicket'));
                return
            }
            if (!this.form.priority) {
                this.$toast.error(this.$t('EnterPriority'));
                return
            }
            if (this.form.userPhones.length < 1) {
                this.$toast.error(this.$t('EnterNumberClient'));
                return
            }
            if (!this.form.problemEnvironment) {
                this.$toast.error(this.$t('EnterProblemEnvironment'));
                return
            } 
            if (!this.form.comment) {
                this.$toast.error(this.$t('EnterDescTicket'));
                return
            }  
            apiServe.editTicket(id, this.form).then(result=> {
                if(result.status == 'done') {
                    if(this.settingCheck['025'] == true) {
                        this.dontSave = true  
                        this.typeForModal = 'ticket';
                        this.result = result.data
                        this.showDialog = true 
                    } else {
                        this.dontSave = true
                        this.$emit('close')
                        this.$emit('updateCard')
                    }
                }
            })
           
        },
        // додати девай
        addDeivce(e){
            this.form.devices.push(e);
            this.itemdevice = ''
        },
        editDeivce(e){
            // змінити девайся
            for(var value in this.form.devices){
                if(this.form.devices[value].id == e.id){
                    this.form.devices[value] = e;
                }
            }
        },
        removeDeivce(e) {
            // видалити девайся
            for(var value in this.form.devices){
                if(this.form.devices[value].id == e){
                    this.form.devices.splice(value, 1)
                }
            }
        },
        closeItem(){
            // закрити інфу про девайс
            this.showdevice = false;
            this.itemdevice = ''
        },
        open(e){
            // відкрити девайс
            this.itemdevice = e
            this.showdevice = true
        },
        currentDate() {
            // дізнатися поточну дату
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + date.getMonth()).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2) + " " + 
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },
        confirmExit(event) {
            // Перевірка, чи є необхідні дані відкриті в вікні
            if (this.haveUnsavedChanges()) {
                const message = this.$t('confirmCloseModalAlert');
                // Стандартна повідомлення браузера для підтвердження виходу
                event.returnValue = message;
                return message;
            }
        },
        haveUnsavedChanges() {
            //Перевірка полів, якщо хоча б одне заповнене, буде відображатись вікно чи точно бажаєте закрити вікно

            return this.form.ticketName !== '' 
                    || this.form.type !== '' 
                    || this.form.problemEnvironment !== ''
                    || this.form.comment !== '';
        },
        mutateProblemEnv(e){
            // мутейт середовища проблеми
            return mutateProblemEnv(e)
        },
        nameWorker(item){
            // мутейт імені співробітника
            return nameWorker(item)
        },
        mutateticket(e){
            // мутейт типу тікета
            return mutateTypeTicket(e)
        },
        tgWorker(item) {
            // отримання нікнейму співробітника
            return tgAccWorker(item);
        },
    },
    mounted() {
        // слухаємо події закриття, ребуту сторінки 
        window.addEventListener('beforeunload', this.confirmExit);
    },
    beforeUnmount() {
        // видаляєте слухача події
        window.removeEventListener('beforeunload', this.confirmExit);
        
        
        if(this.dontSave == false){ // якщо заявка створена або редагована, то не будемо зберігати форму в стор
            if(this.perm) {
                sessionStorage.setItem('perm', this.perm)
            }
            this.form.userPhones = JSON.stringify(this.form.userPhones)
            if(this.form.ticketName != ''  || this.form.problemEnvironment != '' || this.form.type != '' || this.form.comment != '') {
                
                let detail = {
                    dateTime: new Date(),
                    text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                    category: 'ticket',
                    form: JSON.stringify(this.form)
                };

                dbPromise.add(detail)
            } //додавання в indexedDB
        }
    },
    computed: {
        user() {
            return storeS.userbase
        },
        settingCheck() {
            return storeS.checks
        },
        dealines(){
            return storeS.dealines
        },
        checks(){
            return storeS.checks;
        },
        developmentProjects() {
            // Отримання проектів
            return storeS.developmentProjects
        }
    }
})
</script>
