<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('problem_area') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <Multiselect 
                mode="tags"
                v-model="problems"
                :close-on-select="true"
                :searchable="true"
                :create-option="false"
                :options="problemEnvironment"
                @select="this.change"
            />
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';

export default {
    components: { Multiselect },
    data(){
        return {
            problems: [],
            problemEnvironment: [],
        }
    },
    created() {
        this.changeSelectDevProjects();
    },
    methods: {
        changeSelectDevProjects(){
            // Робимо вигляд проєктів під мультіселект
            for(var item in this.developmentProjects){
                var project = this.developmentProjects[item]
                this.problemEnvironment.push({
                    "label": project.projectName,
                    "value": project.projectKey
                })
            }
            
        },
        change(){  
            this.$emit('change', 'problemEnvironments', this.problems)
        },
    },
    computed: {
        developmentProjects() {
            // Отримання проектів
            return storeS.developmentProjects
        }
    }
}
</script>