<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('time') }}</label
        >
        <br />
        <div class="row g-2 list_filtr" >
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioTime" id="all_times" v-model="status" :value="''">
                    <label class="form-check-label" for="all_times">{{ $t('all') }}</label>
                </div>
            </div>
            <template v-for="(value, index) in opt" :key="index" >
                <div class="col-lg-6">
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioTime" :id="'time'+index" v-model="status" :value="value.value">
                        <label class="form-check-label" :for="'time'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { storeS } from '@/store';

export default {
    data(){
        return {
            status: "",
            opt: [
                {
                    name: this.$t('3MonthsMore'),
                    value: "3MonthsMore",
                },
                {
                    name: this.$t('6MonthsMore'),
                    value: "6MonthsMore",
                },
                {
                    name: this.$t('9MonthsMore'),
                    value: "9MonthsMore",
                },
                {
                    name: this.$t('12MonthsMore'),
                    value: "12MonthsMore",
                },
            ]
        }
    },
    methods: {
        change(){
            let date = "";
            let today = new Date()
            console.log(new Date(today.setMonth(new Date().getMonth() - 1)))
            switch (this.status) {
                case "3MonthsMore":
                    date = "" + new Date(today.setMonth(new Date().getMonth() - 2)).getFullYear() + "-" + new Date(today.setMonth(new Date().getMonth() - 2)).getMonth() + "-" + new Date(today.setMonth(new Date().getMonth() - 2)).getDate()
                break;
                case "6MonthsMore":
                    date = "" + new Date(today.setMonth(new Date().getMonth() - 5)).getFullYear() + "-" + new Date(today.setMonth(new Date().getMonth() - 5)).getMonth() + "-" + new Date(today.setMonth(new Date().getMonth() - 5)).getDate()
                break;
                case "9MonthsMore":
                    date = "" + new Date(today.setMonth(new Date().getMonth() - 8)).getFullYear() + "-" + new Date(today.setMonth(new Date().getMonth() - 8)).getMonth() + "-" + new Date(today.setMonth(new Date().getMonth() - 8)).getDate()
                break;
                case "12MonthsMore":
                    date = "" + new Date(today.setMonth(new Date().getMonth() - 11)).getFullYear() + "-" + new Date(today.setMonth(new Date().getMonth() - 11)).getMonth() + "-" + new Date(today.setMonth(new Date().getMonth() - 11)).getDate()
                break;
            }

            this.$emit('change', 'pr', date)
        },
    },
    computed: {
        // providers(){
        //     return storeS.providers
        // }
    }
}
</script>